// Cette carte est utilisée pour afficher le nom et la date d'un contact dans la liste des contacts

// Elle possède plusieurs propriétés :
// - img_url : l'url de l'image du contact
// - img_alt : l'alt de l'image du contact
// - name : le nom du contact
// - date : la date de naissance
// - onClick : la fonction à exécuter au clic

// style
import './index.css';

// react
import { useEffect, useState } from 'react';
import { ReactComponent as NewProfileFr } from '../../assets/new-profile-fr.svg';
import { ReactComponent as NewProfileEn } from '../../assets/new-profile-en.svg';

import { useTranslation } from "react-i18next";
import SetUpTranslation from "../../components/SetUpTranslation";

interface CardNameDateProps {
    img_url: string;
    name?: string;
    date?: string;
    onClick?: () => void;
    variant?: 'large';
    new_profile?: boolean;
}

/**
 * ## CardNameDate
 * 
 * ---
 * 
 * Ce composant est une carte qui affiche le nom et la date de naissance d'un utilisateur.
 * 
 * 
 * ---
 * 
 * @param {string} img_url - L'url de l'image du contact
 * @param {string} img_alt - L'alt de l'image du contact
 * @param {string} name - Le nom du contact
 * @param {string} date - La date de naissance
 * @param {() => void} onClick - La fonction à exécuter au clic
 * @param {string} variant - Le type de carte (large ou non) **Par défaut, la carte est de taille normale**
 * @param {boolean} new_profile - Si le profil est nouveau ou non
 * 
 * 
 * ---
 * 
 * @example
 * <CardNameDate
 *    img_url={contact.img_url}
 *    img_alt={contact.img_alt}
 *    name={contact.name}
 *    date={contact.date}
 *    onClick={() => openContact(contact.id)}
 *    variant='large'
 *    new_profile={true}
 * />
 * 
 */

const CardNameDate = ({
    img_url,
    name = '',
    date = '',
    onClick,
    variant,
    new_profile,
}: CardNameDateProps) => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    const currentLanguage = i18n.language;

    const [className, setClassName] = useState('');
    useEffect(() => {
        if (variant === 'large') {
            setClassName('-large');
        }
    }, [variant]);

    const divStyle = {
        background: 'url(' + img_url + ')',
    };

    return (
        <>
            <div className={"card-name-date" + className} onClick={onClick}>
                <img className="card-pp" src={img_url}></img>
                {new_profile && currentLanguage == 'fr' && <NewProfileFr />}
                {new_profile && currentLanguage == 'en' && <NewProfileEn />}
                <div className="card-name-date__name-date ">
                    <p className="h1-auth">{name}</p>
                    <p className="date">{date}</p>
                </div>
            </div>
        </>
    )
}

export default CardNameDate;