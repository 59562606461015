// cette page permet à l'utilisateur de choisir le profil qu'il souhaite créer
// si il a déjà un profil, il peut connecter son profil, sinon il peut en créer un nouveau

// react-dom
import {useNavigate} from "react-router-dom";

// compononents
import ActionButton from "../../../../../../components/ActionButton";
import GoBack from "../../../../../../components/GoBack";

// assets
import MakeeDiamondLogin from '../../../../../../assets/makee-diamond-login';

// style
import './index.css';

import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";

const AdminInvProfile = () => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    // fonction qui permet de naviguer vers une autre page
    const navigate = useNavigate();

    return (
        <>
            <div className="go-back">
                <GoBack
                    onClick={() => navigate(-1)}
                />
            </div>
            <section className='section-admin-invitation-profile'>
                <div className='div-admin-invitation-profile'>
                    {/* image svg en haut du formulaire (mobile) */}
                    <MakeeDiamondLogin className='makee-diamond-login'/>
                    {/* textes */}
                    <h1 className='h1-auth'>{t("page.signUpAdmin.createYourAccount")}</h1>
                    <p className='form-text-auth new-profile'>{t("page.signUpAdmin.newProfileMustBeAssociated")}</p>
                    <h2 className='h1'>{t("page.mergeAccount.alreadyHaveMakeeAccount")}</h2>
                    <p className='form-text-auth interface-access'>{t("page.signUpAdmin.newProfileMustBeAssociated")}</p>

                    {/* bouton qui redirige vers la page de connexion avec un compte préexistant */}
                    <ActionButton
                        auth
                        variant="primary"
                        type="button"
                        onClick={() => {
                            navigate('/signup/admin/merge/search')
                        }}
                    >
                        {t("page.mergeAccount.alreadyHaveMakeeAccount")}
                    </ActionButton>

                    <div className='horizontal-line-ou'>
                        <div className='horizontal-line'></div>
                        <p className='form-text-auth'>{t("page.signUpAdmin.or")}</p>
                        <div className='horizontal-line'></div>
                    </div>

                    {/* bouton qui redirige vers la page de création de compte */}
                    <ActionButton
                        auth
                        variant="primary"
                        type="button"
                        onClick={() => {
                            navigate('/signup/admin/create/infos')
                        }}
                    >
                        {t("page.signUpAdmin.createMyAccount")}
                    </ActionButton>
                </div>
            </section>
        </>
    )
}

export default AdminInvProfile;
