// components
import React, {ChangeEvent, useEffect, useRef, useState} from 'react'

// outlet context
import {useAdminEditChallengeContext} from '..'

// style
import './index.css'

// types
import {IChallenge, IModalStep, IStep, IStepDeliverable, IStepQuestion, ITimeSlot} from './types'
// components
import {Box} from '@mui/system'
import moment, {Moment} from 'moment'
import {v4 as uuidv4} from 'uuid';
import ActionButton from '../../../../../../components/ActionButton'

//Icon
import {ReactComponent as IconAdd} from '../../../../../../assets/icon-add.svg';
import {ReactComponent as IconTrash} from '../../../../../../assets/icon-trash.svg';
import {ReactComponent as IconInfo} from '../../../../../../assets/icon-info.svg';
import {ReactComponent as Iconhelp} from '../../../../../../assets/icon-help.svg';
import {ReactComponent as IconArrowDown} from '../../../../../../assets/arrow-down.svg';

import {Alert, Divider, Grid, Snackbar, Stack, TextField, useMediaQuery, useTheme} from '@mui/material'
import Fade from '@mui/material/Fade';
import {DateTimePicker, renderTimeViewClock} from '@mui/x-date-pickers'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../app/type'
import SwitchButton from '../../../../../../components/SwitchButton'
import DragAndDropQuestions from '../../../../../../modules/DragAndDropQuestions'
import DragAndDropDeliverables from '../../../../../../modules/DragAndDropDeliverables'
import Modal from '../../../../../../modules/Modal'
import {useParams} from 'react-router'
import api from "../../../../../../utils/api";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";

const AdminEditChallengeSteps = () => {

    // const challengeId = useSelector((state: RootState) => state.challenges.getOneChallenge?.id);
    const challengeId = useParams().id
    const {setPage, setChange} = useAdminEditChallengeContext();
    const [challenge, setChallenge] = useState<IChallenge | null>(null);
    const {listSteps, setListSteps} = useAdminEditChallengeContext();
    const {originalSteps, setOriginalSteps} = useAdminEditChallengeContext();
    const {updateListSteps, setUpdateListSteps} = useAdminEditChallengeContext();
    const {newStep, setNewStep} = useAdminEditChallengeContext();
    const {stepToDelete, setStepToDelete} = useAdminEditChallengeContext();
    const {registrationEndDate, setRegistrationEndDate} = useAdminEditChallengeContext();

    //Error
    const {timeSlotError, setTimeSlotError} = useAdminEditChallengeContext();
    const {errorStep, setErrorStep} = useAdminEditChallengeContext();

    //SnackBar
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
    const [textSnackBar, setTextSnackBar] = useState<string>("");
    const [variantSnackBar, setVariantSnackBar] = useState<"error" | "success">("success");

    //Animation
    const [collapseQuestionDefault, setCollapseQuestionDefault] = useState<boolean[]>([]);
    const [collapseQuestionStep, setCollapseQuestionStep] = useState<boolean[]>([]);
    const [collapseQuestionSaveStep, setCollapseQuestionSaveStep] = useState<boolean[]>([]);
    const [collapseDeliverableSaveStep, setCollapseDeliverableSaveStep] = useState<boolean[]>([]);
    // const [openQuestionModal, setOpenQuestionModal] = useState<IModalStep[]>([]);
    // const [openDeliverableModal, setOpenDeliverableModal] = useState<boolean>(false);

    const prevStepIdsRefQuestion = useRef<string[]>([]);
    const [openQuestionModal, setOpenQuestionModal] = useState<IModalStep[]>([]);

    const prevStepIdsRefDeliverable = useRef<string[]>([]);
    const [openDeliverableModal, setOpenDeliverableModal] = useState<IModalStep[]>([]);
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    moment.locale(i18n.language);

    const currentLanguage = i18n.language;
    let dateFormat = 'DD/MM/YYYY hh:mm'; // Format par défaut
    if (currentLanguage === 'fr') {
        dateFormat = 'DD/MM/YYYY HH:mm'; // Format français
    } else if (currentLanguage === 'en') {
        dateFormat = 'MM/DD/YYYY hh:mm A'; // Format anglais
    }
    const handleChangeQuestionDefault = (index: any) => {
        setCollapseQuestionDefault((prev) => {
            const newStates = [...prev];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };
    const handleChangeQuestionStep = (index: any) => {
        setCollapseQuestionStep((prev) => {
            const newStates = [...prev];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };

    const handleChangeQuestionSaveStep = (index: any) => {
        setCollapseQuestionSaveStep((prev) => {
            const newStates = [...prev];
            newStates[index] = !newStates[index];
            return newStates;
        });
    }

    const handleChangeDeliverableSaveStep = (index: any) => {
        setCollapseDeliverableSaveStep((prev) => {
            const newStates = [...prev];
            newStates[index] = !newStates[index];
            return newStates;
        });
    }

    useEffect(() => {
        if (!openSnackBar) {
            if (errorStep && errorStep !== undefined && (errorStep !== "Les étapes ont bien été modifiées.")) {
                setOpenSnackBar(true);
                setTextSnackBar("Une erreur est survenue.")
                setVariantSnackBar("error")
            } else if (errorStep && errorStep !== undefined && (errorStep === "Les étapes ont bien été modifiées.")) {
                setOpenSnackBar(true);
                setTextSnackBar("Les modifications ont été enregistrées.")
                setVariantSnackBar("success")
            } else {
                setOpenSnackBar(false);
                setTextSnackBar("")
                setVariantSnackBar("success")
            }
        }
    }, [errorStep])

    setPage("steps")
    //Error plage horaire

    const getSteps = async () => {
        await api.get(
            "/admin/challenge/steps",
            {
                params: {
                    challengeId: challengeId
                },
                withCredentials: true
            }
        ).then((res) => {
            const challenge = res.data.challenge;
            setChallenge(challenge);
            if (challenge.registrationEndDate) {
                setRegistrationEndDate(challenge.registrationEndDate);
            }
            const steps = res.data.steps;
            setListSteps(steps);
            setOriginalSteps(steps);
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getSteps()
    }, [])

    const hasDateRangeOverlap = (rangeA: [string, string], rangeB: [string, string]): boolean => {
        const [startA, endA] = rangeA;
        const [startB, endB] = rangeB;

        return (
            moment(startA).isSameOrBefore(endB) &&
            moment(endA).isSameOrAfter(startB)
        );
    };

    const getStepById = (stepId: string): IStep | undefined => {
        return listSteps.find((step) => String(step.id) === String(stepId));
    };

    const isStepRangeOverlap = (modifiedStep: IStep, allSteps: IStep[]): {
        id: string,
        errorType: string,
        endDateType: string
    } | null => {
        const modifiedStart = modifiedStep.dateStartDeposite;
        const modifiedEnd = modifiedStep.dateEndDeposite;

        // Vérifiez si la step est en dehors de l'intervalle du challenge
        //Si la date modifiedStart ou modifiedEnd est en dehors de l'intervalle du challenge, renvoyez une erreur
        if (moment(modifiedStart).isBefore(challenge?.startDate) || moment(modifiedEnd).isAfter(challenge?.endDate)) {
            return {
                id: String(modifiedStep.id),
                errorType: "OutsideChallengeInterval",
                endDateType: "dateEndDeposite"
            };
        }

        if (challenge?.registrationEndDate && moment(modifiedEnd).isBefore(registrationEndDate)) {
            return {
                id: String(modifiedStep.id),
                errorType: "EndBeforeRegistrations",
                endDateType: "dateEndDeposite"
            };
        }

        if (moment(modifiedStart).isAfter(modifiedEnd)) {
            return {
                id: String(modifiedStep.id),
                errorType: "InvalidRange",
                endDateType: "dateEndDeposite"
            };
        } else if (moment(modifiedStep.dateStartDeposite).isSameOrAfter(modifiedStep.dateEndDeposite)) {
            return {id: String(modifiedStep.id), errorType: "InvalidRange", endDateType: "dateEndDeposite"};
        }

        for (const step of allSteps) {
            if (String(step.id) !== String(modifiedStep.id)) {
                const stepStart = step.dateStartDeposite;
                const stepEnd = step.dateEndDeposite;

                if (hasDateRangeOverlap([modifiedStart, modifiedEnd], [stepStart, stepEnd])) {
                    return {
                        id: String(step.id),
                        errorType: "Overlap",
                        endDateType: "dateEndDeposite"
                    };
                }
            }
        }

        return null;
    };

    // useEffect(() => {
    //     console.log("errorStep",errorStep);
    // }, [errorStep])

    const updateErrors = (allSteps: IStep[]) => {
        const updatedErrors: ITimeSlot[] = [];
        for (const step of allSteps) {
            const errorInfo = isStepRangeOverlap(step, allSteps.filter(s => String(s.id) !== String(step.id)));
            if (errorInfo) {
                updatedErrors.push({error: true, ...errorInfo});
            }
        }
        setTimeSlotError(updatedErrors);
    };

    const addStep = async () => {
        const dateStartDeposite = moment().add(1, 'minutes').format(dateFormat);
        const dateEndDeposite = challenge?.registrationEndDate ? challenge.registrationEndDate : moment().add(2, 'minutes').format(dateFormat);
        const addnewStep: IStep = {
            id: "new-" + uuidv4(),
            challengeId: Number(challengeId),
            name: `Nouvelle étape`,
            dateStartDeposite: dateStartDeposite,
            dateEndDeposite: dateEndDeposite,
            hasSelection: false,
            questions: [],
            deliverables: [],
            status: {
                name: "todo"
            }
        };

        setNewStep([...newStep, addnewStep]); // Mettez à jour newStep avec la nouvelle étape
        setListSteps([...listSteps, addnewStep]); // Mettez à jour listSteps avec la nouvelle étape
    }

    const removeStep = (stepToRemove: IStep) => {
        // Vérifie si stepToRemove est présent dans newStep
        const isStepInNewStep = newStep.some((step) => String(step.id) === String(stepToRemove.id));

        if (isStepInNewStep) {
            // Si stepToRemove est présent dans newStep, le supprimer de newStep
            const newStepCopy = newStep.filter((step) => String(step.id) !== String(stepToRemove.id));
            setNewStep(newStepCopy);
        } else {
            // Ajouter stepToRemove à stepToDelete
            setStepToDelete([...stepToDelete, stepToRemove]);
        }

        // Supprimer stepToRemove de listSteps
        const listStepsCopy = listSteps.filter((step) => String(step.id) !== String(stepToRemove.id));
        setListSteps(listStepsCopy);

        // Vérifier si stepToRemove est présent dans updateListSteps
        const isStepInUpdateListStep = updateListSteps.some((step) => String(step.id) === String(stepToRemove.id));

        if (isStepInUpdateListStep) {
            // Supprimer stepToRemove de updateListSteps
            const updateListStepsCopy = updateListSteps.filter((step) => String(step.id) !== String(stepToRemove.id));
            setUpdateListSteps(updateListStepsCopy);
        }
    };

    const updateStep = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | Moment, stepId: string, field: keyof IStep) => {
        let updatedFieldValue: string | boolean = '';
        const hasSelectionValue = moment.isMoment(e) ? (e as Moment).valueOf() : (e as ChangeEvent<HTMLInputElement>).target.checked;
        if (moment.isMoment(e)) {
            // Si e est de type Moment, mettez à jour les champs dateStartDeposite et dateEndDeposite
            if (field === 'dateStartDeposite' || field === 'dateEndDeposite') {
                updatedFieldValue = moment(e, dateFormat).toISOString();
            }
        } else if ('target' in e) {
            // Si e a une propriété 'target', il s'agit d'un événement ChangeEvent
            const target = e.target as HTMLInputElement; // Type assertion pour accéder à 'checked'
            if (field === 'hasSelection') {
                updatedFieldValue = target.checked;
            } else {
                updatedFieldValue = target.value;
            }
        } else {
            // Gérer d'autres cas ici si nécessaire
        }

        const updatedSteps = listSteps.map((step) => {
            if (String(step.id) === String(stepId)) {
                return {
                    ...step,
                    [field]: updatedFieldValue,
                };
            }
            return step;
        });

        // Vérifiez si le step mis à jour existe déjà dans updateListSteps et newStep
        const existingUpdatedStepIndex = updateListSteps.findIndex((step) => String(step.id) === String(stepId));
        const existingNewStepIndex = newStep.findIndex((step) => String(step.id) === String(stepId));

        if (existingUpdatedStepIndex !== -1) {
            // Si le step existe déjà dans updateListSteps, mettez à jour ses propriétés dans updateListSteps
            const updatedUpdateListSteps = [...updateListSteps];
            const existingUpdatedStep = updatedUpdateListSteps[existingUpdatedStepIndex];
            updatedUpdateListSteps[existingUpdatedStepIndex] = {
                ...existingUpdatedStep,
                [field]: updatedFieldValue,
            };
            setUpdateListSteps(updatedUpdateListSteps);
        } else if (existingNewStepIndex !== -1) {
            // Si le step existe déjà dans newStep, mettez à jour ses propriétés dans newStep
            const updatedNewStep = [...newStep];
            const existingNewStep = updatedNewStep[existingNewStepIndex];
            updatedNewStep[existingNewStepIndex] = {
                ...existingNewStep,
                [field]: updatedFieldValue,
            };
            setNewStep(updatedNewStep);
        } else {
            // Si le step n'existe ni dans updateListSteps ni dans newStep, ajoutez-le à updateListSteps
            const stepToUpdate = updatedSteps.find((step) => String(step.id) === String(stepId));
            if (stepToUpdate) {
                setUpdateListSteps([...updateListSteps, stepToUpdate]);
            }
        }

        // Mise à jour des étapes modifiées dans listSteps
        setListSteps(updatedSteps);
    };

    /**************** Question Action ****************/

    const addQuestionToStep = (stepId: string, questionName: string) => {
        // Recherchez l'étape à laquelle vous souhaitez ajouter la question
        const step = listSteps.find((step) => (String(step.id)) === String(stepId));

        if (step) {
            const newQuestion: IStepQuestion = {
                id: "new-" + uuidv4(), // Générez un ID unique pour la question
                questionText: questionName,
                position: step.questions.length + 1,
            };
            console.log("newQuestion", newQuestion);

            // Mettez à jour les questions de l'étape en ajoutant la nouvelle question
            const updatedStep = {
                ...step,
                questions: [...step.questions, newQuestion],
            };

            // Mettez à jour la liste des étapes
            const updatedSteps = listSteps.map((s) => (String(s.id) === String(stepId) ? updatedStep : s));

            setListSteps(updatedSteps);
        }
    };

    const updateQuestionPositionInStep = (stepId: string, newQuestions: any) => {
        // Recherchez l'étape à laquelle vous souhaitez mettre à jour les questions
        const step = getStepById(stepId);

        if (step) {
            // Créez une copie de l'étape avec les questions mises à jour
            const updatedStep = {...step, questions: newQuestions};

            // Mise à jour de la liste des étapes
            const updatedSteps = listSteps.map((s) =>
                String(s.id) === String(stepId) ? updatedStep : s
            );

            // Mettez à jour listSteps avec les étapes mises à jour
            setListSteps(updatedSteps);
            //Mettre à jour dans newStep ou updateListSteps

            // Vérifiez si l'étape mise à jour existe déjà dans newStep ou updateListSteps
            if (updatedStep) {
                // Si l'étape existe déjà dans newStep, mettez à jour ses propriétés
                const existingNewStepIndex = newStep.findIndex((step) => String(step.id) === String(stepId));
                if (existingNewStepIndex !== -1) {
                    const updatedNewStep = [...newStep];
                    updatedNewStep[existingNewStepIndex] = updatedStep;
                    setNewStep(updatedNewStep);
                } else {
                    // Si l'étape n'est pas dans updateListSteps, ajoutez-la
                    const existingUpdatedStepIndex = updateListSteps.findIndex((step) => String(step.id) === String(stepId));
                    if (existingUpdatedStepIndex === -1) {
                        setUpdateListSteps([...updateListSteps, updatedStep]);
                    } else {
                        // Si l'étape est déjà dans updateListSteps, mettez à jour ses propriétés
                        const updatedUpdateListSteps = [...updateListSteps];
                        updatedUpdateListSteps[existingUpdatedStepIndex] = updatedStep;
                        setUpdateListSteps(updatedUpdateListSteps);
                    }
                }
            }
        }
    };

    const updateQuestion = (stepId: string, updatedQuestion: IStepQuestion) => {
        // Recherchez l'étape à laquelle vous souhaitez mettre à jour la question
        const updatedSteps = listSteps.map((step) => {
            if (String(step.id) === stepId) {
                // Mise à jour des questions de l'étape
                const updatedQuestions = step.questions.map((question) => {
                    if (question.id === updatedQuestion.id) {
                        // Mise à jour de la question spécifique
                        console.log("la question mise à jour", updatedQuestion)
                        return updatedQuestion;
                    }
                    return question;
                });

                // Mise à jour de l'étape avec les questions mises à jour
                return {...step, questions: updatedQuestions};
            }
            return step;
        });

        // Mettez à jour listSteps avec les étapes mises à jour
        setListSteps(updatedSteps);

        // Vérifiez si l'étape mise à jour existe déjà dans newStep ou updateListSteps
        const updatedStep = updatedSteps.find((step) => String(step.id) === String(stepId));
        if (updatedStep) {
            // Si l'étape existe déjà dans newStep, mettez à jour ses propriétés
            const existingNewStepIndex = newStep.findIndex((step) => String(step.id) === String(stepId));
            if (existingNewStepIndex !== -1) {
                const updatedNewStep = [...newStep];
                updatedNewStep[existingNewStepIndex] = updatedStep;
                setNewStep(updatedNewStep);
            } else {
                // Si l'étape n'est pas dans updateListSteps, ajoutez-la
                const existingUpdatedStepIndex = updateListSteps.findIndex((step) => String(step.id) === String(stepId));
                if (existingUpdatedStepIndex === -1) {
                    setUpdateListSteps([...updateListSteps, updatedStep]);
                } else {
                    // Si l'étape est déjà dans updateListSteps, mettez à jour ses propriétés
                    const updatedUpdateListSteps = [...updateListSteps];
                    updatedUpdateListSteps[existingUpdatedStepIndex] = updatedStep;
                    setUpdateListSteps(updatedUpdateListSteps);
                }
            }
        }

    };

    const removeQuestionFromStep = (stepId: string, questionId: string) => {
        // Recherchez l'étape à laquelle vous souhaitez supprimer la question
        const updatedSteps = listSteps.map((step) => {
            if (String(step.id) === String(stepId)) {
                // Supprimez la question spécifique de l'étape
                const updatedQuestions = step.questions.filter((question) => String(question.id) !== String(questionId));
                return {...step, questions: updatedQuestions};
            }
            return step;
        });

        setListSteps(updatedSteps);

        // Si la question supprimée est présente dans originalStep, il faut ajouter la step dans updateStep
        const updatedQuestionExistsInOriginal = originalSteps.some((step) =>
            step.questions.some((question) => String(question.id) === String(questionId))
        );

        if (updatedQuestionExistsInOriginal) {
            const stepIndex = originalSteps.findIndex((step) => step.questions.some((question) => String(question.id) === String(questionId)));
            if (stepIndex !== -1) {
                // Créez une copie de la step mise à jour
                const updatedStep = {...originalSteps[stepIndex]};
                // Retirez la question supprimée de la liste des questions de la step mise à jour
                updatedStep.questions = updatedStep.questions.filter((question) => String(question.id) !== String(questionId));
                // Ajoutez la step mise à jour à updateListSteps
                setUpdateListSteps([...updateListSteps, updatedStep]);
            }
        }

        // Supprimez la question de updateListSteps si elle existe
        const updatedQuestionExistsInUpdateList = updateListSteps.some((step) =>
            step.questions.some((question) => String(question.id) === String(questionId))
        );
        if (updatedQuestionExistsInUpdateList) {
            const updatedUpdateListSteps = updateListSteps.map((step) => {
                const updatedQuestions = step.questions.filter((question) => String(question.id) !== String(questionId));
                return {...step, questions: updatedQuestions};
            });

            setUpdateListSteps(updatedUpdateListSteps);
        }

        // Supprimez la question de newStep si elle existe
        const updatedQuestionExistsInNewStep = newStep.some((step) =>
            step.questions.some((question) => String(question.id) === String(questionId))
        );

        if (updatedQuestionExistsInNewStep) {
            const updatedNewStep = newStep.map((step) => {
                const updatedQuestions = step.questions.filter((question) => String(question.id) !== String(questionId));
                return {...step, questions: updatedQuestions};
            });

            setNewStep(updatedNewStep);
        }
    };

    /**************** Deliverable Action ****************/
    const addDeliverableToStep = (stepId: string, deliverableName: string) => {
        // Recherchez l'étape à laquelle vous souhaitez ajouter la question
        const step = listSteps.find((step) => (String(step.id)) === String(stepId));

        if (step) {
            const newDeliverbale: IStepDeliverable = {
                id: "new-" + uuidv4(),
                name: deliverableName,
                position: step.deliverables.length + 1,
                description: ''
            };

            // Mettez à jour les livrables de l'étape en ajoutant le nouveau livrable
            const updatedStep = {
                ...step,
                deliverables: [...step.deliverables, newDeliverbale],
            };

            // Mettez à jour la liste des étapes
            const updatedSteps = listSteps.map((s) => (String(s.id) === String(stepId) ? updatedStep : s));

            setListSteps(updatedSteps);
        }
    };

    const updateDeliverablePositionInStep = (stepId: string, newDeliverables: any) => {
        // Recherchez l'étape à laquelle vous souhaitez mettre à jour les questions
        const step = getStepById(stepId);

        if (step) {
            // Créez une copie de l'étape avec les questions mises à jour
            const updatedStep = {...step, deliverables: newDeliverables};

            // Mise à jour de la liste des étapes
            const updatedSteps = listSteps.map((s) =>
                String(s.id) === String(stepId) ? updatedStep : s
            );

            // Mettez à jour listSteps avec les étapes mises à jour
            setListSteps(updatedSteps);
            //Mettre à jour dans newStep ou updateListSteps
        }
    };

    const updateDeliverable = (stepId: string, updatedDeliverable: IStepDeliverable) => {
        // Recherchez l'étape à laquelle vous souhaitez mettre à jour la question
        const updatedSteps = listSteps.map((step) => {
            if (String(step.id) === stepId) {
                // Mise à jour des livrables de l'étape
                const updatedDeliverables = step.deliverables.map((deliverable) => {
                    if (String(deliverable.id) === updatedDeliverable.id) {
                        // Mise à jour d'un livrable spécifique
                        return updatedDeliverable;
                    }
                    return deliverable;
                });

                // Mise à jour de l'étape avec les questions mises à jour
                return {...step, deliverables: updatedDeliverables};
            }
            return step;
        });

        // Mettez à jour listSteps avec les étapes mises à jour
        setListSteps(updatedSteps);

        // Vérifiez si l'étape mise à jour existe déjà dans newStep ou updateListSteps
        const updatedStep = updatedSteps.find((step) => String(step.id) === String(stepId));
        if (updatedStep) {
            // Si l'étape existe déjà dans newStep, mettez à jour ses propriétés
            const existingNewStepIndex = newStep.findIndex((step) => String(step.id) === String(stepId));
            if (existingNewStepIndex !== -1) {
                const updatedNewStep = [...newStep];
                updatedNewStep[existingNewStepIndex] = updatedStep;
                setNewStep(updatedNewStep);
            } else {
                // Si l'étape n'est pas dans updateListSteps, ajoutez-la
                const existingUpdatedStepIndex = updateListSteps.findIndex((step) => String(step.id) === String(stepId));
                if (existingUpdatedStepIndex === -1) {
                    setUpdateListSteps([...updateListSteps, updatedStep]);
                } else {
                    // Si l'étape est déjà dans updateListSteps, mettez à jour ses propriétés
                    const updatedUpdateListSteps = [...updateListSteps];
                    updatedUpdateListSteps[existingUpdatedStepIndex] = updatedStep;
                    setUpdateListSteps(updatedUpdateListSteps);
                }
            }
        }

    };

    const removeDeliverableFromStep = (stepId: string, deliverableId: string) => {
        // Recherchez l'étape à laquelle vous souhaitez supprimer le livrable
        const updatedSteps = listSteps.map((step) => {
            if (String(step.id) === String(stepId)) {
                // Supprimez le livrable spécifique de l'étape
                const updatedDeliverables = step.deliverables.filter((deliverable) => String(deliverable.id) !== String(deliverableId));
                return {...step, deliverables: updatedDeliverables};
            }
            return step;
        });

        setListSteps(updatedSteps);

        // Vérifiez si le livrable existe dans originalSteps, et ajoutez-la à stepToDelete
        const deliverableExistsInOriginal = originalSteps.some((step) =>
            step.deliverables.some((deliverable) => String(deliverable.id) === String(deliverableId))
        );

        if (deliverableExistsInOriginal) {
            const stepIndex = originalSteps.findIndex((step) => step.deliverables.some((deliverable) => String(deliverable.id) === String(deliverableId)));
            if (stepIndex !== -1) {
                // Créez une copie de la step mise à jour
                const updatedStep = {...originalSteps[stepIndex]};
                // Retirez la question supprimée de la liste des questions de la step mise à jour
                updatedStep.deliverables = updatedStep.deliverables.filter((deliverable) => String(deliverable.id) !== String(deliverableId));
                // Ajoutez la step mise à jour à updateListSteps
                setUpdateListSteps([...updateListSteps, updatedStep]);
            }
        }

        // Supprimez le livrable de updateListSteps si il existe
        const updatedDeliverableExistsInUpdateList = updateListSteps.some((step) =>
            step.deliverables.some((deliverable) => String(deliverable.id) === String(deliverableId))
        );

        if (updatedDeliverableExistsInUpdateList) {
            const updatedUpdateListSteps = updateListSteps.map((step) => {
                const updatedDeliverables = step.deliverables.filter((deliverable) => String(deliverable.id) !== String(deliverableId));
                return {...step, deliverables: updatedDeliverables};
            });

            setUpdateListSteps(updatedUpdateListSteps);
        }

        // Supprimez le livrable de newStep si il existe
        const updatedDeliverableExistsInNewStep = newStep.some((step) =>
            step.deliverables.some((deliverable) => String(deliverable.id) === deliverableId)
        );

        if (updatedDeliverableExistsInNewStep) {
            const updatedNewStep = newStep.map((step) => {
                const updatedDeliverables = step.deliverables.filter((deliverable) => String(deliverable.id) !== String(deliverableId));
                return {...step, deliverables: updatedDeliverables};
            });

            setNewStep(updatedNewStep);
        }
    };

    const arraysAreEqual = (arr1: any[], arr2: any[]) => {
        return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
    };

    useEffect(() => {
        // console.log("listSteps ===>",listSteps);
        setCollapseQuestionDefault(listSteps.map(() => false));
        setCollapseQuestionStep(listSteps.map(() => false));
        setCollapseQuestionSaveStep(listSteps.map(() => false));
        setCollapseDeliverableSaveStep(listSteps.map(() => false));
        updateErrors(listSteps);
        const newStepIds = listSteps.map((step) => String(step.id));

        const prevStepIdsQuestion = prevStepIdsRefQuestion.current;
        const prevStepIdsDeliverable = prevStepIdsRefDeliverable.current;

        if (!arraysAreEqual(newStepIds, prevStepIdsQuestion)) {
            setOpenQuestionModal(newStepIds.map((stepId) => ({
                open: false,
                step: listSteps.find((step) => String(step.id) === String(stepId))
            })));
            prevStepIdsRefQuestion.current = newStepIds;
        }

        if (!arraysAreEqual(newStepIds, prevStepIdsDeliverable)) {
            setOpenDeliverableModal(newStepIds.map((stepId) => ({
                open: false,
                step: listSteps.find((step) => String(step.id) === String(stepId))
            })));
            prevStepIdsRefDeliverable.current = newStepIds;
        }

    }, [listSteps])

    useEffect(() => {
        if (stepToDelete.length > 0 || updateListSteps.length > 0 || newStep.length > 0) {
            setChange(true);
        } else {
            setChange(false);
        }
    }, [stepToDelete, updateListSteps, newStep])

    useEffect(() => {
        console.log("collapseQuestionSaveStep", collapseQuestionSaveStep);
    }, [collapseQuestionSaveStep])

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div className={`admin-edit-challenge  m-auto ${!isMdDown ? "w-75" : "w-100"}`}>
            <section className="admin-edit-challenge-steps">
                <Box className="header">
                    <h2>Gérez les étapes</h2>
                    <p className="text">À chaque étape de votre Challenge, vos participants déposent un dossier de
                        projet auprès de votre Jury. Elle peut donner lieu ou non à une sélection.</p>
                    <p className="small-lead-text required-field">1 étape minimum requise</p>
                    {challenge && (
                        <div id="info-recap-challenge-step" className='mt-4'>
                            <div className='text-center'>
                                <IconInfo/>
                            </div>
                            <div className='text-start mt-3'>
                                <div className='w-auto m-auto text-center pb-3'>
                                    <label className='challenge-name'>Récapitulatif de mon challenge</label>
                                </div>
                                <ul>
                                    <li><p><span className='fw-bold'>Titre de mon challenge :</span> {challenge.name}
                                    </p></li>
                                    <li><p><span
                                        className='fw-bold'>Dates du challenge : </span>Du {moment(challenge.startDate).format("LLL")} au {moment(challenge.endDate).format("LLL")}
                                    </p></li>
                                    {(moment(challenge.registrationStartDate).isValid() || moment(registrationEndDate).isValid()) && (
                                        <li>
                                            <p><span className='fw-bold'>Dates d'inscriptions : </span>
                                                {moment(challenge.registrationStartDate).isValid() && moment(registrationEndDate).isValid() ?
                                                    `Du ${moment(challenge.registrationStartDate).format("LLL")} au ${moment(registrationEndDate).format("LLL")}`
                                                    : moment(challenge.registrationStartDate).isValid() ?
                                                        `À partir du ${moment(challenge.registrationStartDate).format("LLL")}`
                                                        : moment(registrationEndDate).isValid() ?
                                                            `Jusqu'au ${moment(registrationEndDate).format("LLL")}`
                                                            : null}
                                            </p>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}
                </Box>

                <div className="w-50 m-auto mb-5 pb-2 mt-2">
                    <ActionButton
                        variant={challenge && moment(challenge.endDate).isBefore(moment()) ? 'disabled' : 'primary'}
                        className='steps-add-button'
                        onClick={challenge && moment(challenge.endDate).isBefore(moment()) ? () => {} : addStep}
                        icon={<IconAdd/>}
                    >
                        Ajouter une étape
                    </ActionButton>
                </div>

                {listSteps.length > 0 && (
                    <Grid container gap={2} justifyContent={"center"}
                          sx={{paddingX: `${isMdDown ? "10%" : "0%"}`, margin: 'auto', marginBottom: '3em'}}>
                        {listSteps.map((step, index) => (
                            <Grid item key={step.id} xs={12} md={6} lg={6} xl={4}>
                                <div className={`p-4 stepCard ${step.status.name === 'done' ? 'opacity-50' : ''}`}>
                                    <Grid container>
                                        <Grid item xs={9}>
                                            <h3>Étape {index + 1}</h3>
                                        </Grid>
                                        <Grid item xs={3} textAlign={"right"}>
                                            <IconTrash
                                                onClick={step.status.name !== 'todo' ? () => {} : () => removeStep(step)}
                                                className='cursor-pointer'/>
                                        </Grid>
                                    </Grid>
                                    <label htmlFor={`name-${index}`}>Nom de l'étape *</label>

                                    <TextField
                                        id={`name-${index}`}
                                        sx={{
                                            borderRadius: '10px',
                                            '& .MuiOutlinedInput-root': {
                                                background: "#fff",
                                                borderRadius: '10px',
                                                // '& fieldset' :{
                                                //     borderStyle: 'none'
                                                //   }
                                            }
                                        }}
                                        type="text"
                                        value={step.name}
                                        onChange={(e) => updateStep(e, String(step.id), 'name')}
                                        fullWidth
                                        disabled={step.status.name === 'done'}
                                    />
                                    <label htmlFor={`dateStartDeposite-${index}`}>Début des dépôts de projet *</label>
                                    <DateTimePicker
                                        slotProps={{
                                            textField: {
                                                error: timeSlotError.some((error) => String(error.id) === String(step.id) && error.endDateType === "dateEndDeposite" && error.errorType !== "EndBeforeRegistrations"),
                                                helperText: timeSlotError.map((error) => {
                                                    if (String(error.id) === String(step.id) && error.endDateType === "dateEndDeposite" && error.errorType === "Overlap") {
                                                        return "Les dates chevauchent celles d'une autre étape.";
                                                    } else if (String(error.id) === String(step.id) && error.endDateType === "dateEndDeposite" && error.errorType === "InvalidRange") {
                                                        return `La date de début des dépôts ne peut pas être après la date de fin des dépôts.`;
                                                    } else if (String(error.id) === String(step.id) && error.endDateType === "dateEndDeposite" && error.errorType === "OutsideChallengeInterval") {
                                                        return `La date de début des dépôts doit être comprise dans la période du challenge.`;
                                                    }
                                                    return null;
                                                }),
                                                id: `dateStartDeposite-${index}`,
                                                // size:"small",
                                                fullWidth: true,

                                                sx: {
                                                    borderRadius: '10px',
                                                    '& .MuiOutlinedInput-root': {
                                                        background: "#fff",
                                                        borderRadius: '10px',
                                                        // '& fieldset' :{
                                                        //     borderStyle: 'none'
                                                        // }
                                                    }
                                                }
                                            },
                                        }}
                                        disabled={step.status.name === 'done'}
                                        value={moment(step.dateStartDeposite)}
                                        onChange={(e: Moment | null) => {
                                            if (e !== null && moment.isMoment(e)) {
                                                updateStep(e, String(step.id), 'dateStartDeposite')
                                            }
                                        }}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                    />
                                    <label htmlFor={`dateEndDeposite-${index}`}>Fin des dépôts de projet *</label>
                                    {timeSlotError.some((error) => String(error.id) === String(step.id) && error.endDateType === "dateEndDeposite" && error.errorType === "EndBeforeRegistrations") &&
                                        <>
                                            <br/>
                                            <small>
                                                Les inscriptions se terminent au plus tard à la fin de la première étape
                                                : le {moment(step.dateEndDeposite).format(dateFormat)}
                                            </small>
                                        </>
                                    }
                                    <DateTimePicker
                                        minDateTime={moment(step.dateStartDeposite)}
                                        slotProps={{
                                            textField: {
                                                error: timeSlotError.some((error) => String(error.id) === String(step.id) && error.endDateType === "dateEndDeposite" && error.errorType !== "EndBeforeRegistrations"),
                                                helperText: timeSlotError.map((error) => {
                                                    if (String(error.id) === String(step.id) && error.endDateType === "dateEndDeposite" && error.errorType === "Overlap") {
                                                        return "Les dates chevauchent celles d'une autre étape.";
                                                    } else if (String(error.id) === String(step.id) && error.endDateType === "dateEndDeposite" && error.errorType === "InvalidRange") {
                                                        return `La date de début des dépôts ne peut pas être après la date de fin des dépôts.`;
                                                    } else if (String(error.id) === String(step.id) && error.endDateType === "dateEndDeposite" && error.errorType === "OutsideChallengeInterval") {
                                                        return `La date de fin des dépôts doit être comprise dans la période du challenge.`;
                                                    } else {
                                                        return null;
                                                    }
                                                }),
                                                id: `dateEndDeposite-${index}`,
                                                fullWidth: true,
                                                sx: {
                                                    borderRadius: '10px',
                                                    '& .MuiOutlinedInput-root': {
                                                        background: "#fff",
                                                        borderRadius: '10px',
                                                    }
                                                }
                                            },
                                        }}
                                        disabled={step.status.name === 'done'}
                                        value={moment(step.dateEndDeposite)}
                                        onChange={(e: Moment | null) => {
                                            if (e !== null && moment.isMoment(e)) {
                                                updateStep(e, String(step.id), 'dateEndDeposite')
                                            }
                                        }}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                    />
                                    <label htmlFor={`step-selection-${index}`}>Sélection</label>
                                    <p>Activez la sélection si cette étape donne lieu à une sélection des équipes
                                        retenues pour l'étape suivante ou pour la fin de votre Challenge.</p>
                                    <Grid container alignItems={"center"} marginTop={2}>
                                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                            <SwitchButton
                                                disabled={step.status.name === 'done'}
                                                checked={step.hasSelection}
                                                onChange={(e) => updateStep(e, String(step.id), 'hasSelection')}
                                                inputProps={{'aria-label': 'selection'}}
                                                size={isMdDown ? 'small' : 'medium'}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <p className="form-title">L'étape donne lieu à une sélection.</p>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                            <p className="small-lead-text required-field">{step.hasSelection ? "Activé" : ""}</p>
                                        </Grid>
                                    </Grid>
                                    {step.hasSelection && (
                                        <div>
                                            <p className='mt-3'>Pensez à prendre en compte le temps dont vous avez besoin pour faire les sélections pour définir la date de début de la prochaine étape</p>
                                        </div>
                                    )}
                                    <div>
                                        <h3 className='text-center mt-4 mb-2'>Dossier de projet</h3>
                                        {(step.status.name !== "todo" && (step.questions.length + step.deliverables.length === 0)) && (
                                            <p className='text-center'>Vous n'avez pas renseigné de questions ni de
                                                livrables</p>
                                        )}
                                        {step.status.name !== "done" ? (
                                            <>
                                                <div className='info-collapse mt-4'>
                                                    <Stack onClick={() => handleChangeQuestionDefault(index)} sx={{
                                                        marginBottom: "1em",
                                                        cursor: "pointer",
                                                        padding: "1em",
                                                        background: "var(--primary00)",
                                                        borderRadius: "10px"
                                                    }}>
                                                        <Grid container>
                                                            <Grid item xs={10}>
                                                                <label>Questions fixes</label> <Iconhelp
                                                                className='icon-help'/>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <div className='text-end'>
                                                                    <IconArrowDown
                                                                        className={`${collapseQuestionDefault[index] ? 'rotate-iconopen' : 'rotate-iconclose'}`}/>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Fade in={collapseQuestionDefault[index]} unmountOnExit>
                                                            <div>
                                                                <Divider orientation="horizontal" flexItem sx={{
                                                                    border: "solid 1px var(--primary50)",
                                                                    marginTop: '1.3em',
                                                                    marginBottom: '1.3em'
                                                                }}/>
                                                                <div>
                                                                    <p>Les questions ci-dessous sont posées pour la
                                                                        première étape de tous les projets déposés sur
                                                                        la plateforme. Ces réponses sont ensuite
                                                                        modifiables lors des étapes suivantes pour être
                                                                        toujours à jour de l'avancée du projet.</p>
                                                                    <div className="fixed-questions-list">
                                                                        <ul className='mb-0'>
                                                                            <li className="small-lead-text">Titre du
                                                                                projet
                                                                            </li>
                                                                            <li className="small-lead-text">Description
                                                                                rapide
                                                                            </li>
                                                                            <li className="small-lead-text">Donnez une
                                                                                description approfondie de votre projet.
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    </Stack>
                                                </div>
                                                <div className='info-collapse'>
                                                    <Stack onClick={() => handleChangeQuestionStep(index)} sx={{
                                                        marginBottom: "1em",
                                                        cursor: "pointer",
                                                        padding: "1em",
                                                        background: "var(--primary00)",
                                                        borderRadius: "10px"
                                                    }}>
                                                        <Grid container>
                                                            <Grid item xs={10}>
                                                                <label>Questions de l'étape</label><Iconhelp
                                                                className='icon-help'/>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <div className='text-end'>
                                                                    <IconArrowDown
                                                                        className={`${collapseQuestionStep[index] ? 'rotate-iconopen' : 'rotate-iconclose'}`}/>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Fade in={collapseQuestionStep[index]} unmountOnExit>
                                                            <div>
                                                                <Divider orientation="horizontal" flexItem sx={{
                                                                    border: "solid 1px var(--primary50)",
                                                                    marginTop: '1.3em',
                                                                    marginBottom: '1.3em'
                                                                }}/>
                                                                <div>
                                                                    <p className="text">En plus de ces premières
                                                                        questions, vous pouvez ajouter autant de
                                                                        questions que vous souhaitez pour chaque étape.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    </Stack>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {step.questions.length > 0 && (
                                                    <div className='info-collapse mt-4'>
                                                        <Stack onClick={() => handleChangeQuestionSaveStep(index)} sx={{
                                                            marginBottom: "1em",
                                                            cursor: "pointer",
                                                            padding: "1em",
                                                            background: "var(--primary00)",
                                                            borderRadius: "10px"
                                                        }}>
                                                            <Grid container>
                                                                <Grid item xs={10}>
                                                                    <label>Questions</label>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <div className='text-end'>
                                                                        <IconArrowDown
                                                                            className={`${collapseQuestionSaveStep[index] ? 'rotate-iconopen' : 'rotate-iconclose'}`}/>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            <Fade in={collapseQuestionSaveStep[index]} unmountOnExit>
                                                                <div>
                                                                    <Divider orientation="horizontal" flexItem sx={{
                                                                        border: "solid 1px var(--primary50)",
                                                                        marginTop: '1.3em',
                                                                        marginBottom: '1.3em'
                                                                    }}/>
                                                                    <div>
                                                                        <div className="fixed-todo-list">
                                                                            <ul className='mb-0'>
                                                                                {step.questions.map((question, index) => (
                                                                                    <li key={index}
                                                                                        className="small-lead-text">{question.questionText}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Fade>
                                                        </Stack>
                                                    </div>
                                                )}
                                                {step.deliverables.length > 0 && (
                                                    <div className='info-collapse mt-4'>
                                                        <Stack onClick={() => handleChangeDeliverableSaveStep(index)}
                                                               sx={{
                                                                   marginBottom: "1em",
                                                                   cursor: "pointer",
                                                                   padding: "1em",
                                                                   background: "var(--primary00)",
                                                                   borderRadius: "10px"
                                                               }}>
                                                            <Grid container>
                                                                <Grid item xs={10}>
                                                                    <label>Livrables</label>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <div className='text-end'>
                                                                        <IconArrowDown
                                                                            className={`${collapseDeliverableSaveStep[index] ? 'rotate-iconopen' : 'rotate-iconclose'}`}/>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            <Fade in={collapseDeliverableSaveStep[index]} unmountOnExit>
                                                                <div>
                                                                    <Divider orientation="horizontal" flexItem sx={{
                                                                        border: "solid 1px var(--primary50)",
                                                                        marginTop: '1.3em',
                                                                        marginBottom: '1.3em'
                                                                    }}/>
                                                                    <div>
                                                                        <div className="fixed-todo-list">
                                                                            <ul className='mb-0'>
                                                                                {step.deliverables.map((deliverable, index) => (
                                                                                    <li key={index}
                                                                                        className="small-lead-text">{deliverable.name} {deliverable.description !== "" ? "- " + deliverable.description : ""}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Fade>
                                                        </Stack>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {step.status.name !== "done" && (
                                            <>
                                                <ActionButton
                                                    variant={step.status.name === 'done' ? 'disabled' : 'secondary'}
                                                    onClick={step.status.name === 'done' ? () => {} : () => {
                                                        setOpenQuestionModal(openQuestionModal.map((modal) => {
                                                            if (modal.step && String(modal.step.id) === String(step.id)) {
                                                                return {
                                                                    ...modal,
                                                                    open: true,
                                                                };
                                                            }
                                                            return modal;
                                                        }));
                                                    }}
                                                >
                                                    {(step.questions.length > 0 || step.status.name === 'done') ? `Voir les questions (${step.questions.length})` : 'Ajouter les questions'}
                                                </ActionButton>
                                                <Modal
                                                    title="Ajouter les questions"
                                                    open={openQuestionModal.findIndex((modal) => modal.step && String(modal.step.id) === String(step.id)) !== -1 && openQuestionModal[openQuestionModal.findIndex((modal) => modal.step && String(modal.step.id) === String(step.id))].open}
                                                    onClose={() => {
                                                        setOpenQuestionModal(openQuestionModal.map((modal) => {
                                                            if (modal.step && String(modal.step.id) === String(step.id)) {
                                                                return {
                                                                    ...modal,
                                                                    open: false,
                                                                };
                                                            }
                                                            return modal;
                                                        }));
                                                    }}
                                                    underlineButtonText="Fermer"
                                                    underlineButtonAction={() => {
                                                        setOpenQuestionModal(openQuestionModal.map((modal) => {
                                                            if (modal.step && String(modal.step.id) === String(step.id)) {
                                                                return {
                                                                    ...modal,
                                                                    open: false,
                                                                };
                                                            }
                                                            return modal;
                                                        }));
                                                    }}
                                                >
                                                    <DragAndDropQuestions key={index} questions={step.questions}
                                                                          stepId={step.id}
                                                                          addQuestionToStep={addQuestionToStep}
                                                                          updateQuestionPosition={updateQuestionPositionInStep}
                                                                          updateQuestion={updateQuestion}
                                                                          removeQuestionFromStep={removeQuestionFromStep}
                                                                          disabled={step.status.name === 'done'}/>
                                                </Modal>
                                            </>
                                        )}
                                    </div>
                                    {step.status.name !== "done" && (
                                        <div>
                                            <h3 className='text-center mt-4 mb-2'>Ajouter les livrables attendus</h3>
                                            <Box sx={{paddingY: "1em"}}>
                                                <p className="text text-center">Indiquez aux participants les documents
                                                    que vous attendez pour cette étape.
                                                    <p className="text text-center italic">Attention : les livrables ne
                                                        seront plus modifiables après le commencement de l'étape.</p>
                                                </p>
                                            </Box>
                                            <ActionButton
                                                variant={step.status.name === 'done' ? 'disabled' : 'secondary'}
                                                onClick={step.status.name === 'done' ? () => {} : () => {
                                                    setOpenDeliverableModal(openDeliverableModal.map((modal) => {
                                                        if (modal.step && String(modal.step.id) === String(step.id)) {
                                                            return {
                                                                ...modal,
                                                                open: true,
                                                            };
                                                        }
                                                        return modal;
                                                    }));
                                                }}
                                            >
                                                {(step.deliverables.length > 0 || step.status.name === 'done') ? `Voir les livrables (${step.deliverables.length})` : 'Ajouter les livrables'}
                                            </ActionButton>
                                            <Modal
                                                title="Ajouter les livrables"
                                                open={openDeliverableModal.findIndex((modal) => modal.step && String(modal.step.id) === String(step.id)) !== -1 && openDeliverableModal[openDeliverableModal.findIndex((modal) => modal.step && String(modal.step.id) === String(step.id))].open}
                                                onClose={() => {
                                                    setOpenDeliverableModal(openDeliverableModal.map((modal) => {
                                                        if (modal.step && String(modal.step.id) === String(step.id)) {
                                                            return {
                                                                ...modal,
                                                                open: false,
                                                            };
                                                        }
                                                        return modal;
                                                    }));
                                                }}
                                                underlineButtonText="Fermer"
                                                underlineButtonAction={() => {
                                                    setOpenDeliverableModal(openDeliverableModal.map((modal) => {
                                                        if (modal.step && String(modal.step.id) === String(step.id)) {
                                                            return {
                                                                ...modal,
                                                                open: false,
                                                            };
                                                        }
                                                        return modal;
                                                    }));
                                                }}
                                            >
                                                <DragAndDropDeliverables key={index} deliverables={step.deliverables}
                                                                         stepId={step.id}
                                                                         addDeliverableToStep={addDeliverableToStep}
                                                                         updateDeliverablePosition={updateDeliverablePositionInStep}
                                                                         updateDeliverable={updateDeliverable}
                                                                         removeDeliverableFromStep={removeDeliverableFromStep}
                                                                         disabled={step.status.name === 'done'}/>
                                            </Modal>
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                )}
                {listSteps.length > 0 && (
                    <div className="w-50 m-auto pt-4">
                        <ActionButton
                            variant={challenge && moment(challenge.endDate).isBefore(moment()) ? 'disabled' : 'primary'}
                            className='steps-add-button'
                            onClick={challenge && moment(challenge.endDate).isBefore(moment()) ? () => {} : addStep}
                            icon={<IconAdd/>}
                        >
                            Ajouter une étape
                        </ActionButton>
                    </div>
                )}
                <Snackbar
                    open={openSnackBar}
                    autoHideDuration={3000}
                    onClose={() => {
                        setOpenSnackBar(false)
                        setVariantSnackBar('success')
                        setTextSnackBar('')
                    }}
                >
                    <Alert onClose={() => {
                        setOpenSnackBar(false)
                        setVariantSnackBar('success')
                        setTextSnackBar('')
                    }} severity={variantSnackBar}>{textSnackBar}</Alert>
                </Snackbar>
                {/* {QuestionStepModal()} */}
            </section>
        </div>
    )
}

export default AdminEditChallengeSteps;
