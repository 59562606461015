import React, {useEffect, useState} from 'react';
import {Route, Navigate, useLocation, useMatch } from 'react-router-dom';
import {useAuth} from '../context/AuthContext';
import {IProtectedRoute} from './types';

const ProtectedRoute: React.FC<IProtectedRoute> = ({
                                                       Component,
                                                       unAuthenticatedOnly,
                                                       ...rest
                                                   }) => {
    const {isAuthenticated, logout, login, finishedCheckingAuth} = useAuth();
    const location = useLocation();
    useEffect(() => {
        const checkAuth = async () => {
            await login();
        };

        if (!finishedCheckingAuth) {
            checkAuth();
        }
    }, [finishedCheckingAuth, login]);

    if (!finishedCheckingAuth) {
        // Attendre que la vérification d'authentification soit terminée
        return null; // ou un composant de chargement si vous le souhaitez
    }

    console.log("finishedCheckingAuth", finishedCheckingAuth);
    console.log("unAuthenticatedOnly", unAuthenticatedOnly);
    console.log("isAuthenticated", isAuthenticated);

    if (unAuthenticatedOnly) {
        return (!isAuthenticated || location.pathname === "/signup/participant/merge/summary") ? (
            <Component/>
        ) : (
            <Navigate to={"/"} replace={true}/>
        )
    } else {
        //Si je ne suis pas loggé, je stocke la page sur laquelle je suis pour y revenir après la sélection du profil
        if(!isAuthenticated && location.pathname !== "/login/1" && location.pathname !== "/login/choice/profile") {
            localStorage.setItem("redirect", location.pathname);
        }
        return isAuthenticated ? (
            <Component/>
        ) : (
            <>
                <Navigate to="/login/1" replace={true}/>
                {logout()}
            </>
        );
    }
};
export default ProtectedRoute;
