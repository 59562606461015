// react-routeur
import {Link, useNavigate} from 'react-router-dom';

// style
import './index.css'

// assets
import {ReactComponent as IconDates} from '../../assets/icon-calendar.svg'
import {ReactComponent as IconPrice} from '../../assets/icon-winner.svg'
import {ReactComponent as IconPrivate} from '../../assets/icon-private.svg'
import {DEFAULT_CHALLENGE_PICTURE} from '../../utils/consts';


// components
// components
import ButtonBase from '../../components/BaseButton'
import {Grid} from '@mui/material';
import moment from "moment";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../components/SetUpTranslation";
import React from "react";

interface ChallengeCardProps {
    organizer?: string;

    title?: string;

    theme?: string;

    state?: string;

    description?: string;

    date?: string;

    price?: string;

    img?: string;

    done?: boolean;

    participants?: number;

    status?: 'create' | 'inProgress' | 'finished';

    link?: string;

    onClick?: () => void;

    registration_date?: string | null;

    link_to_challenge?: string;

    desktop?: boolean;

    participation_type?: string;

    long_description?: string;

    min_members?: number;

    max_members?: number;

    isPublic?: boolean | null;

    disabled?: boolean;
}

const ChallengeCard = ({
                           organizer,
                           title,
                           theme,
                           state,
                           description,
                           date,
                           price,
                           img,
                           done,
                           participants,
                           status,
                           link,
                           onClick,
                           registration_date,
                           link_to_challenge,
                           desktop,
                           participation_type,
                           long_description,
                           min_members,
                           max_members,
                           isPublic,
                           disabled
                       }: ChallengeCardProps) => {

    const navigate = useNavigate();
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    moment.locale(i18n.language);

    return (
        <>
            {
                desktop ? (
                        <div className='challenge-card-desktop'>
                            <div className="div-left">
                                <div className="div-img-text">
                                    {participants &&
                                        <p className={(status === 'finished' ? 'grey-all-text' : '') + ' participants small-lead-text'}>{participants} étudiants
                                            inscrits</p>}
                                    <div
                                        className={(done ? 'grey-img ' : ' ') + (participants && ' participant-div ') + ' img'}
                                        style={{
                                            background: `url(${img ? img : DEFAULT_CHALLENGE_PICTURE}) center center/cover`,
                                            filter: `${disabled && "grayscale(100%)"}`
                                        }}>
                                    </div>
                                </div>
                                <div
                                    className={'wordbreak ' + (status === 'finished' ? 'grey-all-text' : '') + (link ? ' radius-text ' : '') + ' all-text'}>
                                    <p className='organisateur'>{organizer}</p>
                                    <div className='title-private'>
                                        <p className="big-lead-text mxw-90">{title}</p>
                                        {!isPublic && isPublic !== undefined && <IconPrivate className="mt5p5"/>}
                                    </div>
                                    <p className="text"><span
                                        className="bold">{!done && (date + ' - ')}</span>{description && description}</p>
                                </div>
                            </div>
                            <div className="div-right">
                                <div className="top">
                                    <div className='top-infos'>
                                        <p className="small-lead-text">
                                            {participation_type}
                                        </p>
                                        <ButtonBase bold className='state-button'>{state}</ButtonBase>
                                    </div>
                                    <div className="participations-registrations">

                                        {(!done && registration_date) && <div className="registrations">
                                            <p className="bold">{t("challengeCard.registrationPhase")}</p>
                                            <div className="registrations-dates">
                                                <p className="text registrations-dates-text">{registration_date}</p>
                                            </div>
                                        </div>}
                                        {participation_type === 'En équipe' && <div className="registrations">
                                            <p className="bold">{t("challengeCard.teamSize")}</p>
                                            <div className="registrations-dates">
                                                <p className="text registrations-dates-text">{t("challengeCard.fromMembers")} {min_members} {t("challengeCard.toMembers")} {max_members} {t("projectCard.members")}</p>
                                            </div>
                                        </div>}
                                        {participation_type && participation_type === t("participationType.both") &&
                                            <div className="registrations">
                                                <p className="bold">{t("challengeCard.teamSize")}</p>
                                                <div className="registrations-dates">
                                                    <p className="text registrations-dates-text">{t("challengeCard.untilMembers")} {max_members} {t("projectCard.members")}</p>
                                                </div>
                                            </div>}
                                    </div>
                                    {long_description && <div className="description-longue">
                                        <p className="bold">Description</p>
                                        <div className="text" dangerouslySetInnerHTML={{ __html: long_description && long_description.length > 370 ? `${long_description.substring(0, 370)}...` : long_description }} />
                                    </div>}

                                </div>

                                <div className="bottom">

                                    {link_to_challenge &&
                                        <div className="button-text see-challenge"
                                             onClick={() => {
                                                 onClick && onClick()
                                                 navigate(link_to_challenge)
                                             }}>
                                            {t("challengeCard.seeChallenge")}
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    ) :
                    (
                        <div className='challenge-card'>
                            <div className="div-img-text">
                                {participants &&
                                    <p className={(status === 'finished' ? 'grey-all-text' : '') + ' participants small-lead-text'}>{participants} étudiants
                                        inscrits</p>}
                                <img src={img ? img : DEFAULT_CHALLENGE_PICTURE}  className={(status === 'finished' ? 'grey-img' : '') + (participants && ' participant-div ') + ' img'} alt={"image background project"}/>
                            </div>
                            <div
                                className={'wordbreak ' + (status === 'finished' ? 'grey-all-text' : '') + (link && ' radius-text ') + ' all-text'}>
                                <p className='organisateur'>{organizer}</p>
                                <div className='title-private'>
                                    <p className="small-lead-text mxw-90">{title}</p>
                                    {!isPublic && isPublic !== undefined && <IconPrivate className="mt5p5"/>}
                                </div>
                                {/* <p className="small-lead-text mxw-90">{title}&ensp;{!isPublic && isPublic!==undefined && <IconPrivate/>}</p> */}
                                <p className="text"><span
                                    className="bold">{date}</span>{description && (' - ' + description)}</p>
                                <div className='bottom-infos'>
                                    <ButtonBase bold className='state-button'>{state}</ButtonBase>
                                    {link &&
                                        <Link onClick={onClick} to={link || '/'}>
                                            {t("challengeCard.seeChallenge")}
                                        </Link>
                                    }
                                </div>

                                {registration_date && <div className="registrations">
                                    <p className="bold">Phase d'inscriptions</p>
                                    <div className="registrations-dates">
                                        <p className="text registrations-dates-text">{registration_date}</p>
                                    </div>
                                </div>}

                            </div>
                            {link_to_challenge &&
                                <div className="button-text see-challenge"
                                     onClick={() => {
                                         onClick && onClick()
                                         navigate(link_to_challenge)
                                     }}>
                                    {t("challengeCard.seeChallenge")}
                                </div>
                            }

                        </div>
                    )
            }
        </>
    )
}

export default ChallengeCard;
