// page de recherche d'un profil déjà existant afin de pouvoir le connecter au profil en création

// material-ui
import { Box, FormControl, TextField } from "@mui/material";

// react-dom
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";

// components
import ActionButton from "../../../../../../components/ActionButton";

// context
import { useAuth } from "../../../../../../context/AuthContext";

// styles
import './index.css';

// assets
import MakeeDiamondLogin from '../../../../../../assets/makee-diamond-login';

// redux
import { useSelector } from "react-redux";
import { RootState } from '../../../../../../app/type';

import { useTranslation } from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";

const ProfileMerge = () => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    // redux state
    const error = useSelector((action: RootState) => action.error.error);
    const errorMessage = useSelector((action: RootState) => action.error.message);


    const location = useLocation();

    // navigation
    const navigate = useNavigate();

    // context
    const { searchUserAdmin, searchUserParticipant } = useAuth();

    return (
        <>
            <section className='profile-merge'>
                <div className='profile-merge-div'>
                    {/* image svg en haut du formulaire (mobile) */}
                    <MakeeDiamondLogin className='makee-diamond-login' />
                    <h1 className='h1-auth mb-3'>{t("page.mergeAccount.alreadyHaveMakeeAccount")}</h1>

                    {/* formulaire de recherche d'un compte existant à partir de matérial ui
            la recherche se fait à partir du nom et du prénom renseignés */}

                    <FormControl onSubmit={(e) =>
                        location.pathname.includes('admin') ? searchUserAdmin(e) :
                        location.pathname.includes('participant') ? searchUserParticipant(e) :
                        console.log('erreur')
                    }
                    >
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >

                            {/* mail */}
                            <TextField
                                {...(error && { error: true })}
                                id="email"
                                label={t("page.mergeAccount.accountEmail")}
                                variant="outlined"
                                placeholder='contact@mail.com'
                                type='email'
                                helperText={error && errorMessage}
                            />

                            <div className="buttons">
                                <ActionButton
                                    auth
                                    variant="tertiary"
                                    type="button"
                                    onClick={() => {
                                        navigate(-1) // naviguer vers la page précédente
                                    }}
                                >
                                    {t("form.back")}
                                </ActionButton>
                                <ActionButton
                                    auth
                                    variant="primary"
                                    type="submit"
                                >
                                    {t("page.mergeAccount.search")}
                                </ActionButton>
                            </div>
                        </Box>
                    </FormControl>
                </div>

                <Outlet />
            </section>
        </>
    )
}

export default ProfileMerge

// searchAccount
