import "./index.css";
import { Chip, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CardMyParticipation from "../../components/CardMyParticipation";
import { ReactComponent as IconOk } from "../../assets/icon-ok.svg";
import { ReactComponent as IconCrossFilled } from "../../assets/icon-cross-filled.svg";
import moment from "moment";
import { IProject, IProjectChallenge } from "./types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SetUpTranslation from "../../components/SetUpTranslation";
import ActionButton from "../../components/ActionButton";
import { ReactComponent as IconArrowRight } from "../../assets/arrow-right.svg";

interface ProjectCardProps {
    project?: IProject,
    challengeRegistered?: IProjectChallenge
}

const ProjectCard = ({ project, challengeRegistered }: ProjectCardProps) => {
    const challenge = project ? project.challenge : challengeRegistered;
    const team = project?.team;
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    //Récupération du status d'envoi du projet
    const sentProject = project?.stepProjectStatus == "sent";

    //Récupération de l'étape actuel du projet et l'étape du challenge correspondante
    // const curProjectStep = project?.step_projects[project.step_projects.length - 1];
    // const curChallStep = challenge?.steps ? challenge?.steps[challenge.steps.length - 1] : undefined;

    const [error, setError] = useState<boolean>(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (team && challenge && challenge.minMembersTeam) {
            setError(team.eliminated || team.membersCount < challenge.minMembersTeam);
        }
    }, []);

    //Transformation des dates reçues en dates lisibles
    let startDate, endDate = null;
    if (challenge) {
        startDate = new Date(challenge.startDate).toLocaleDateString(i18n.language, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        endDate = new Date(challenge.endDate).toLocaleDateString(i18n.language, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }

    //Traduction du status du challenge
    let challStatus = "Publié";
    switch (challenge?.status.name) {
        case 'published':
            challStatus = t("status.published");
            break;
        case 'registrations':
            challStatus = t("status.registrations");
            break;
        case 'submission':
            challStatus = t("status.submission");
            break;
        case 'deliberations':
            challStatus = t("status.deliberations");
            break;
        case 'finished':
            challStatus = t("status.finished");
            break;
    }

    //Traduction du status de l'étape actuelle
    let stepStatus = "";
    let stepStatusClass = "good"; //Pour l'affichage en vert rouge ou gris
    switch (project?.stepProjectStatus) {
        case 'draft':
            stepStatus = t("status.draft");
            stepStatusClass = "draft";
            break;
        case 'sent':
            stepStatus = t("status.sent");
            stepStatusClass = "good";
            break;
        case 'eliminated':
            stepStatus = t("status.eliminated");
            stepStatusClass = "bad";
            break;
    }

    if (team?.eliminated === true) {
        stepStatus = t("status.eliminated");
        stepStatusClass = "bad";
    }
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div className={`project-card ${error ? 'error' : ''}`}>
            <div className={"project-card-content"}>
                <div className={"project-card-header"}>
                    <h5>{challenge?.establishments[0].name}</h5>
                    <h3>{challenge?.name}</h3>
                    <p>{t("challengeCard.from")} {startDate} {t("challengeCard.to")} {endDate}</p>
                </div>
                <div className={"project-card-challenge"}>
                    <div className="chip-see-challenge">
                        <Chip
                            className={"chip-status-challenge"}
                            label={challStatus}
                            variant={"filled"}
                        />
                        <ActionButton
                            variant="little-underline"
                            onClick={() => navigate(`/challenge/${challenge?.id}/general`)}
                            iconEnd={true}
                            icon={<IconArrowRight />}
                        >
                            {t("challengeCard.seeChallenge")}
                        </ActionButton>
                    </div>
                    <img src={challenge?.challenge_picture} alt="challenge banner" />
                </div>
                {project && <>
                    <hr />
                    <div className={"project-card-participation"}>
                        <h2>{t("projectCard.myParticipation")}</h2>
                        <div className={"type-participation"}>
                            <p>{team?.single ? t("participationType.single") : t("participationType.team")} </p>
                            {challenge && challenge.maxMembersTeam && challenge.maxMembersTeam > 0 && (
                                <p className={"nbMembers"}>
                                    ({
                                        challenge?.minMembersTeam === 0 ? (
                                            <>
                                                {t("projectCard.jusqua")} {challenge.maxMembersTeam} {t("projectCard.members")}
                                            </>
                                        )
                                            : (
                                                <>
                                                    {challenge.minMembersTeam} {t("projectCard.to")} {challenge.maxMembersTeam} {t("projectCard.members")}
                                                </>
                                            )
                                    })
                                </p>
                            )}

                        </div>
                        <div className={"my-participations"}>
                            {
                                challenge && team && !team.single && (
                                    <CardMyParticipation
                                        buttonVariant={"tertiary"}
                                        buttonText={t("projectCard.teamSpace")}
                                        iconTeam={team?.membersCount > challenge?.minMembersTeam ? (
                                            <IconOk />) : (<div className={"to-complete"}>{!isMobile && (<>À compléter</>)}
                                                <IconCrossFilled /></div>)}
                                        onClick={() => navigate(`/participant/challenge/${challenge.id}/team/${team.id}/management`)}
                                    >
                                        <p className={"position-team"}>{t("projectCard.team")} n°{team.numero}</p>
                                        <h4>{team.name}</h4>
                                        <p>{t("projectCard.numberMembers")} {team.membersCount}</p>
                                    </CardMyParticipation>
                                )
                            }
                            <CardMyParticipation
                                buttonVariant={"primary"}
                                buttonText={t("projectCard.seeMyProject")}
                                onClick={() => navigate(`/participant/challenge/${challenge?.id}/project/${project?.id}`)}
                            >
                                {project && project.name !== "" &&
                                    <h4
                                        className={"align-left-flex"}
                                        dangerouslySetInnerHTML={{
                                            __html: `${project.name}`
                                        }}
                                    />}
                                <p>{t("projectCard.step")} {challenge?.doneSteps}/{challenge?.totalSteps}</p>
                                <div className={"status-project"}>
                                    {stepStatus !== "" &&
                                        <p className={"status-project-" + stepStatusClass}>{stepStatus}</p>}
                                </div>
                            </CardMyParticipation>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    );
}

export default ProjectCard;
