import { UPDATE_PROJECT } from '../actions/projectActions';

const initialState = {
    project: {},
};

const projectReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_PROJECT:
            return {
                ...state,
                project: action.payload,
            };
        default:
            return state;
    }
};

export default projectReducer;
