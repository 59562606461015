// mui
import {Box, FormControl, TextField} from "@mui/material";

// components
import ActionButton from "../../../../../components/ActionButton";

// context
import {useAuth} from "../../../../../context/AuthContext";

// react-router-dom
import {useLocation, useNavigate} from "react-router-dom";

// modules
import ImageUploader from "../../../../../modules/ImageUploader/ImageUploader";

// style
import "../index.css";

// assets
import {ReactComponent as IconArrow} from "../../../../../assets/arrow-right.svg";
import {DEFAULT_ESTABLISHMENT_IMAGE} from "../../../../../utils/consts";

// react
import {useEffect, useRef, useState} from "react";

// axios
import {use} from "i18next";

// redux
import {useDispatch, useSelector} from "react-redux";
import {RootState} from '../../../../../app/type';
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import moment, {Moment} from "moment";
import MuiPhoneInput from 'mui-phone-number';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";
import api from "../../../../../utils/api";

const phoneUtil = PhoneNumberUtil.getInstance();
const ProfileUpdate = () => {

    const navigate = useNavigate();

    const [imagePreview, setImagePreview] = useState<string>("");
    const [fileToUpload, setFileToUpload] = useState<File>();

    const [open, setOpen] = useState(false);
    const [classNamesMdp, setClassNamesMdp] = useState('mdp-close cursor-pointer');
    const [fileToDelete, setFileToDelete] = useState<string | null>(null);
    const roleName = useSelector((action: RootState) => action.rootProfile.name);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

    // Translation
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    moment.locale(i18n.language);
    const checkNumber = (e: any): { number: string, isValid: boolean } => {
        try {
            const number = e.target.tel.value;
            const numberProto = phoneUtil.parse(number, "ZZ");
            const formattedPhone = phoneUtil.format(numberProto, PhoneNumberFormat.INTERNATIONAL);

            const isValid = phoneUtil.isValidNumber(numberProto);
            setIsValidPhoneNumber(isValid);

            return {number: isValid ? number : "", isValid};

        } catch (error) {
            // Gestion des erreurs liées à la syntaxe du numéro de téléphone
            setIsValidPhoneNumber(false);
            return {number: "", isValid: false};
        }
    };

    useEffect(() => {
        if (open) {
            setClassNamesMdp('mdp-open cursor-pointer')
        } else {
            setClassNamesMdp('mdp-close cursor-pointer')
        }
    }, [open])

    const location = useLocation();
    const establishmentName = useSelector((state: RootState) => state.user.establishment);

    // const [userInfos, setUserInfos] = useState<any>();
    const dispatch = useDispatch();

    function GetDefaultAdmin() {
        api.get('/admin/myinfo_details',
            {
                withCredentials: true
            })
            .then((res) => {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        firstname: res.data.profile.user.firstname,
                        lastname: res.data.profile.user.lastname,
                        email_pro: res.data.profile.email,
                        tel: res.data.profile.user.phone_number,
                        birthdate: res.data.profile.user.birthdate,
                        job: res.data.profile.user.job,
                        company: res.data.profile.user.company,
                        degree: res.data.profile.user.degree,
                        school: res.data.profile.school,
                        profile_picture: res.data.profile.user.profile_picture,
                    }
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const GetDefaultParticipant = async () => {
        await api.get('/participant_libre/myinfo_details',
            {
                withCredentials: true
            })
            .then((res) => {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        firstname: res.data.profile.user.firstname,
                        lastname: res.data.profile.user.lastname,
                        email_pro: res.data.profile.email,
                        tel: res.data.profile.user.phone_number,
                        birthdate: res.data.profile.user.birthdate,
                        job: res.data.profile.user.job,
                        company: res.data.profile.user.company,
                        degree: res.data.profile.user.degree,
                        school: res.data.profile.user.school,
                        profile_picture: res.data.profile.user.profile_picture,
                    }
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }
    let dateFormatBirthDate = 'DD/MM/YYYY'; // Format par défaut
    useEffect(() => {
        const currentLanguage = i18n.language;
        if (currentLanguage === 'fr') {
            dateFormatBirthDate = 'DD/MM/YYYY'; // Format français
        } else if (currentLanguage === 'en') {
            dateFormatBirthDate = 'MM/DD/YYYY'; // Format anglais
        }
    }, [i18n.language]);

    const updateInfosAdmin = (e: any | Moment, file?: File | undefined) => {
        e.preventDefault();

        setPhoneNumber(e.target.tel.value);
        let tel = '';

        if (e.target.tel) {
            const {number, isValid} = checkNumber(e);
            // Vérifier si le numéro est valide
            if (!isValid && e.target.tel !== "") {
                // Bloquer la requête car le numéro n'est pas valide
                console.log("Numéro invalide");
                return;
            }
            tel = number;
        }


        const formData = new FormData();
        formData.append('lastname', e.target.lastname.value);
        formData.append('firstname', e.target.firstname.value);
        if (file) {
            formData.append('file', file);
        }
        formData.append('phone_number', tel);

        const dateMoment = moment.utc(e.target.birthdate.value, dateFormatBirthDate);
        if (dateMoment.isValid()) {
            // Formater la date en chaîne ISO
            const isoString = dateMoment.toISOString();
            formData.append('birthdate', isoString);

        } else {
            console.log("Date invalide");
        }

        // Pour les autres champs, envoyez une chaîne vide si la valeur est falsy
        formData.append('job', e.target.job.value || '');
        formData.append('company', e.target.company.value || '');
        formData.append('degree', e.target.degree.value || '');
        formData.append('school', e.target.school.value || '');

        formData.append("language", localStorage.getItem("translation") || "fr");

        if (fileToDelete) {
            formData.append('fileToDelete', fileToDelete);
        }

        api.put('/admin/update_profile', formData,
            {
                withCredentials: true
            }
        )
            .then((res) => {
                dispatch({
                    type: "CLEAR_ERROR",
                });

                dispatch({
                    type: "CLEAR_USER",
                });

                console.log("e.target.school.value", e.target.school.value);
                api.get('/myinfo',
                    {
                        withCredentials: true,
                        params: {
                            establishment_name: establishmentName
                        }
                    })
                    .then((res) => {
                        dispatch({
                            type: "SET_USER",
                            payload: {
                                firstname: res.data.profile.user.firstname,
                                lastname: res.data.profile.user.lastname,
                                email_recup: res.data.profile.user.email,
                                birthdate: res.data.profile.user.birthdate,
                                email_pro: res.data.profile.email,
                                profile_picture: res.data.profile.user.profile_picture,
                                establishment: res.data.profile.establishments[0].name,
                            }
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                dispatch({
                    type: "SET_USER",
                    payload: {
                        firstname: e.target.firstname.value,
                        lastname: e.target.lastname.value,
                        job: e.target.job.value,
                        company: e.target.company.value,
                        degree: e.target.degree.value,
                        school: e.target.school.value,
                    }
                });
                navigate('/my-profile')
            })
            .catch((error) => {
                dispatch({
                    type: "SET_ERROR",
                    payload: {
                        message: error.response.data.message,
                        error: true,
                        type: error.response.data.error
                    }
                });
            })
    }

    const updateInfosParticipant = async (e: any, file: File | undefined) => {
        e.preventDefault();

        setPhoneNumber(e.target.tel.value);
        let tel = '';

        if (e.target.tel) {
            const {number, isValid} = checkNumber(e);
            // Vérifier si le numéro est valide
            if (!isValid && e.target.tel !== "") {
                // Bloquer la requête car le numéro n'est pas valide
                console.log("Numéro invalide");
                return;
            }
            tel = number;
        }

        const formData = new FormData();
        formData.append('lastname', e.target.lastname.value);
        formData.append('firstname', e.target.firstname.value);
        if (file) {
            formData.append('file', file);
        }
        formData.append('phone_number', tel);
        const dateMoment = moment.utc(e.target.birthdate.value, dateFormatBirthDate);
        if (dateMoment.isValid()) {
            // Formater la date en chaîne ISO
            const isoString = dateMoment.toISOString();
            formData.append('birthdate', isoString);

        } else {
            console.log("Date invalide");
        }
        formData.append('job', e.target.job.value);
        formData.append('company', e.target.company.value);
        formData.append('degree', e.target.degree.value);
        formData.append('school', e.target.school.value);
        formData.append("language", localStorage.getItem("translation") ? String(localStorage.getItem("translation")) : "fr");
        if (fileToDelete) {
            formData.append('fileToDelete', fileToDelete);
        }

        api.put('/participant/update_profile', formData,
            {
                withCredentials: true
            }
        )
            .then((res) => {
                dispatch({
                    type: "CLEAR_ERROR",
                });
                dispatch({
                    type: "CLEAR_USER",
                });
                dispatch({
                    type: "SET_USER",
                    payload: {
                        firstname: e.target.firstname.value,
                        lastname: e.target.lastname.value,
                        job: e.target.job.value,
                        company: e.target.company.value,
                        degree: e.target.degree.value,
                        school: e.target.school.value
                    }
                });
                navigate('/my-profile')
            })
            .catch((error) => {
                console.log(error)
            })
    }


    function updatePassword(e: any) {
        e.preventDefault();
        const oldpassword = e.target.current_password.value;
        const password = e.target.new_password.value;
        const password2 = e.target.new_password_confirmation.value;
        api.post('/auth/resetpasswordold',
            {
                language: localStorage.getItem("translation"),
                oldpassword: oldpassword,
                password: password,
                password2: password2,
                emailpro: emailProDefaultValue
            },
            {
                withCredentials: true
            }
        )
            .then((res) => {
                setOpen(false);
                dispatch({
                    type: "CLEAR_ERROR",
                });
            })
            .catch((error) => {
                dispatch({
                    type: "SET_ERROR",
                    payload: {
                        message: error.response.data.message,
                        error: true,
                        type: error.response.data.error
                    }
                });
            })
    }

    // paramétrer les erreurs
    const errorMessage = useSelector((action: RootState) => action.error.message);
    const error = useSelector((action: RootState) => action.error.error);
    const type = useSelector((action: RootState) => action.error.type);

    const [typeError, setTypeError] = useState('error');

    const firstNameDefaultValue = useSelector((action: RootState) => action.user.firstname);
    const lastNameDefaultValue = useSelector((action: RootState) => action.user.lastname);
    const emailProDefaultValue = useSelector((action: RootState) => action.user.email_pro);
    const telDefaultValue = useSelector((action: RootState) => action.user.tel);
    const birthDateDefaultValue = useSelector((action: RootState) => action.user.birthdate);
    const profilePictureDefaultValue = useSelector((action: RootState) => action.user.profile_picture);
    const jobDefaultValue = useSelector((action: RootState) => action.user.job);
    const companyDefaultValue = useSelector((action: RootState) => action.user.company);
    const degreeDefaultValue = useSelector((action: RootState) => action.user.degree);
    const schoolDefaultValue = useSelector((action: RootState) => action.user.school);

    useEffect(() => {
        //roleName === "admin" && GetDefaultAdmin();
        roleName === "admin" && GetDefaultAdmin();
        roleName === "participant" && GetDefaultParticipant();
    }, [])


    useEffect(() => {
        if (errorMessage === t("page.myProfileUpdate.error.incorrectPswd")) {
            setTypeError('incorrect-password')
        }
        if (errorMessage === t("page.myProfileUpdate.error.passwordsDontMatch")) {
            setTypeError('uncorresponded-password')
        }
        if (errorMessage === t("page.myProfileUpdate.error.passwordTooWeak")) {
            setTypeError('weak-password')
        }
        if (!error) {
            setTypeError('success')
        }
    }, [errorMessage]);

    const handleDeleteProfilePicture = () => {
        if (profilePictureDefaultValue.includes("makee_bucket")) {
            setFileToDelete(profilePictureDefaultValue);
        }
    }
    const [formErrors, setFormErrors] = useState<any>({});
    const [lastName, setLastName] = useState<any>(lastNameDefaultValue);
    const [firstname, setFirstname] = useState<any>(firstNameDefaultValue);

    const refLastName = useRef<HTMLDivElement>(null);
    const refFirstname = useRef<HTMLDivElement>(null);

    const handleFormProfileUpdate = async (e: any, fileToUpload: any) => {
        e.preventDefault();
        let errors = {};
        if (lastName.trim() === "") {
            errors = {...errors, lastName: t("page.myProfileUpdate.error.nameRequired")};
        }
        if (firstname.trim() === "") {
            errors = {...errors, firstname: t("page.myProfileUpdate.error.firstnameRequired")};
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (roleName === "participant") {
                await updateInfosParticipant(e, fileToUpload);
            } else if (roleName === "admin") {
                await updateInfosAdmin(e, fileToUpload);
                // await updateInfosParticipant(e, fileToUpload);
            }
        } else {
            if (formErrors.lastName) {
                refLastName?.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
            if (formErrors.firstname) {
                refFirstname?.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
        }
    }
    return (
        <div className='profile-update'>
            <FormControl
                onSubmit={(e) => {
                    if (open) {
                        updatePassword(e);
                        dispatch({
                            type: "CLEAR_ERROR",
                        });
                    } else {
                        handleFormProfileUpdate(e, fileToUpload)
                    }
                }}
            >
                <Box
                    component="form"
                    sx={{
                        width: 'calc(100vw-4rem)',
                        padding: '0 2rem',
                        '& > :not(style)': {width: '100%'},
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div className="form-inputs">
                        {/* Nom */}
                        <div className='inputs' ref={refLastName}>
                            <p className='text bold'>{t("page.myProfileUpdate.name")}</p>
                            <TextField
                                {...(type === "emptyLastname" && {error: true})}
                                size="small"
                                id="lastname"
                                variant="outlined"
                                placeholder={`${t("page.myProfileUpdate.name")}`}
                                type="text"
                                className='form-text'
                                //defaultValue={lastNameDefaultValue}
                                value={lastName!}
                                onChange={(e) => setLastName(e.target.value)}
                                helperText={formErrors.lastName ? formErrors.lastName : ""}
                                error={!!formErrors.lastName}
                            />
                        </div>

                        {/* Prénom */}
                        <div className="inputs" ref={refFirstname}>
                            <p className='text bold'>{t("page.myProfileUpdate.firstname")}</p>
                            <TextField
                                {...(type === "emptyFirstname" && {error: true})}
                                size="small"
                                id="firstname"
                                variant="outlined"
                                placeholder={`${t("page.myProfileUpdate.firstname")}`}
                                type="text"
                                className='form-text'
                                //defaultValue={firstNameDefaultValue}
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                helperText={formErrors.firstname ? formErrors.firstname : ""}
                                error={!!formErrors.firstname}
                            />
                        </div>

                        <div className="inputs">
                            <p className="text bold">{t("page.myProfileUpdate.profilePicture")}</p>
                            <ImageUploader
                                image={profilePictureDefaultValue!}
                                defaultImage={"image-profile-picture"}
                                imagePreview={imagePreview}
                                setImagePreview={setImagePreview}
                                setFileToUpload={setFileToUpload}
                                handleDelete={handleDeleteProfilePicture}
                                ratio="1/1"
                                width={"auto"}
                                height={"15rem"}
                            />
                        </div>

                        {/* Date de Naissance */}
                        <div className="inputs">
                            <p className='text bold'>{t("page.myProfileUpdate.birthDate")}</p>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        id: "birthdate",
                                        variant: "outlined",
                                        fullWidth: true,
                                        error: type === 'NoDateProvided' || type === 'EarlierEndDate',
                                        helperText: type === 'NoDateProvided' || type === 'EarlierEndDate' ? errorMessage : '',
                                    },
                                }}
                                defaultValue={moment(birthDateDefaultValue) || null}
                            />
                            {/* <TextField
                                id="birthdate"
                                variant="outlined"
                                placeholder="20/02/1999"
                                type="date"
                                className='form-text'
                                defaultValue={birthDateDefaultValue || "Non renseignée"}
                            /> */}
                        </div>

                        {/* job / formation */}
                        <div className={"block-inputs-job-comp-degree-school"}>
                            <div className={"block-inputs-job-comp"}>
                                {/* Job */}
                                <div className="inputs">
                                    <p className='text bold'>{t("page.myProfileUpdate.jobTraining")}</p>
                                    <TextField
                                        size="small"
                                        id="job"
                                        variant="outlined"
                                        placeholder={`${t("page.myProfileUpdate.jobTraining")}`}
                                        type="text"
                                        className='form-text'
                                        defaultValue={jobDefaultValue || ""}
                                        helperText={errorMessage && error}
                                    />
                                </div>
                                {/* Company */}
                                <div className="inputs">
                                    <p className='text bold'>{t("page.myProfileUpdate.company")}</p>
                                    <TextField
                                        size="small"
                                        id="company"
                                        variant="outlined"
                                        placeholder={`${t("page.myProfileUpdate.company")}`}
                                        type="text"
                                        className='form-text'
                                        defaultValue={companyDefaultValue || ""}
                                        helperText={errorMessage && error}
                                    />
                                </div>

                            </div>
                            <div className={"block-inputs-degree-school"}>
                                {/* Degree */}
                                <div className="inputs">
                                    <p className='text bold'>{t("page.myProfileUpdate.degree")}</p>
                                    <TextField
                                        size="small"
                                        id="degree"
                                        variant="outlined"
                                        placeholder={`${t("page.myProfileUpdate.degree")}`}
                                        type="text"
                                        className='form-text'
                                        defaultValue={degreeDefaultValue || ""}
                                        helperText={errorMessage && error}
                                    />
                                </div>
                                {/* School */}
                                <div className="inputs">
                                    <p className='text bold'>{t("page.myProfileUpdate.school")}</p>
                                    <TextField
                                        size="small"
                                        id="school"
                                        variant="outlined"
                                        placeholder={`${t("page.myProfileUpdate.school")}`}
                                        type="text"
                                        className='form-text'
                                        defaultValue={schoolDefaultValue || ""}
                                        helperText={errorMessage && error}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* numéro de telephone */}
                        <div className="inputs">
                            <p className='text bold'>{t("page.myProfileUpdate.telNum")}</p>
                            <MuiPhoneInput
                                size="small"
                                id="tel"
                                variant="outlined"
                                defaultCountry='fr'
                                // onlyCountries={['fr']}
                                value={telDefaultValue}
                                onChange={(value) => console.log(value)}
                                preferredCountries={['fr']}
                                countryCodeEditable={false}
                                disableAreaCodes={true}
                                // helperText={type === "emptyTel" && errorMessage}
                                error={!isValidPhoneNumber}
                                helperText={isValidPhoneNumber ? '' : `${t("page.myProfileUpdate.telNum")} ${t("page.myProfileUpdate.invalid")}`}
                            />
                        </div>

                        <div className="reset-mdp">
                            <div
                                className="texts"
                                onClick={() => open ? setOpen(false) : setOpen(true)}>
                                <p className='small-lead-text'>{t("page.myProfileUpdate.changePswd")}</p>
                                <div>
                                    <IconArrow className={classNamesMdp}/>
                                </div>
                            </div>
                            {open && <div className="container">
                                <div className="form-inputs">
                                    {/* Nom */}
                                    <div className='inputs'>
                                        <p className='text bold'>{t("page.myProfileUpdate.currentPswd")}</p>
                                        <TextField
                                            {...(typeError === 'incorrect-password' && {error: true})}
                                            size="small"
                                            id="current_password"
                                            variant="outlined"
                                            placeholder="************"
                                            type="password"
                                            className='form-text'
                                            helperText={typeError === 'incorrect-password' && errorMessage}
                                        />
                                    </div>
                                    <div className='inputs'>
                                        <p className='text bold'>{t("page.myProfileUpdate.newPswd")}</p>
                                        <TextField
                                            {...((typeError === 'uncorresponded-password' || typeError === 'weak-password') && {error: true})}
                                            size="small"
                                            id="new_password"
                                            variant="outlined"
                                            placeholder="************"
                                            type="password"
                                            className='form-text'
                                            helperText={`${t("page.myProfileUpdate.pswdMust")}`}
                                        />
                                    </div>
                                    <div className='inputs'>
                                        <p className='text bold'>{t("page.myProfileUpdate.confirmNewPswd")}</p>
                                        <TextField
                                            {...((typeError === 'uncorresponded-password' || typeError === 'weak-password') && {error: true})}
                                            size="small"
                                            id="new_password_confirmation"
                                            variant="outlined"
                                            placeholder="************"
                                            type="password"
                                            className='form-text'
                                            helperText={(typeError === 'uncorresponded-password' || typeError === 'weak-password') && errorMessage}
                                        />
                                    </div>
                                    <div className='change-mdp-buttons'>
                                        <ActionButton
                                            type='button'
                                            variant='tertiary'
                                            onClick={() => {
                                                dispatch({
                                                    type: "CLEAR_ERROR",
                                                });
                                                setOpen(false)
                                            }}
                                        >
                                            {t("form.cancel")}
                                        </ActionButton>
                                        <ActionButton
                                            type='submit'
                                            variant='primary'
                                        >
                                            {t("form.edit")}
                                        </ActionButton>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className='global-modification-buttons'>
                            <ActionButton
                                className='button'
                                type='button'
                                variant='tertiary'
                                onClick={() => {
                                    navigate(-1)
                                    dispatch({
                                        type: "CLEAR_ERROR",
                                    });
                                }}
                                {
                                    ...(
                                        open && {
                                            disabled: true,
                                        })
                                }
                            >
                                {t("form.cancel")}
                            </ActionButton>

                            <ActionButton
                                className='button'
                                type='submit'
                                variant='primary'
                                {
                                    ...(
                                        open && {
                                            disabled: true,
                                        })
                                }
                            >
                                {t("form.edit")}
                            </ActionButton>
                        </div>
                    </div>
                </Box>
            </FormControl>
        </div>
    )
}

export default ProfileUpdate;
