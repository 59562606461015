// dnd-kit
import {
    DndContext,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    arrayMove
} from '@dnd-kit/sortable';

import { v4 as uuidv4 } from 'uuid';
// sensor
import GlobalPointerSensor from '../../utils/GlobalPointerSensor';

// types

// react
import React, { useEffect, useState } from 'react';

// components
import ActionButton from '../ActionButton';

// icons
import { ReactComponent as IconAdd } from '../../assets/icon-add.svg';

// style
import './index.css'

import {IChallenge} from '../../pages/MINIMAL/Admin/AdminChallenges/types'
import IPartner from '../Partner/types';
import { useAdminEditChallengeContext } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/index';
import Partner  from '../Partner';


interface PartnerListProps {
    setChallenge: (challenge: IChallenge) => void
    challenge: IChallenge
}

const PartnerList = ({challenge, setChallenge}: PartnerListProps) => {
    const sensors = [useSensor(GlobalPointerSensor)];
    const { imagePrices, setImagePrices} = useAdminEditChallengeContext();
    const {errorFieldGeneral, setErrorFieldGeneral} = useAdminEditChallengeContext()
    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = challenge.partners.findIndex((partner) => partner.id === active.id);
            const newIndex = challenge.partners.findIndex((partner) => partner.id === over.id);

            const newPartners = challenge.partners.map((partner) => {
                if (partner.id === active.id) {
                    return {
                        ...partner,
                        position: newIndex + 1
                    };
                } else if (partner.id === over.id) {
                    return {
                        ...partner,
                        position: oldIndex + 1
                    };
                }
                return partner;
            });

            // Mettre à jour le challenge en changeant la position
            setChallenge({
                ...challenge,
                partners: arrayMove(newPartners, oldIndex, newIndex)
            });
        }
    };

    const handleDeletePartner = (partner: IPartner) => {
        // Supprimer dare et remettre les positions à jour
        const newPartners = challenge.partners
            .filter((p) => String(p.id) !== String(partner.id))
            .map((p, index) => ({
                ...p,
                position: index + 1
            }));
        // Mettre à jour challenge
        setChallenge({
            ...challenge,
            partners: newPartners
        });

        //Supprimer les errorFieldGeneral avec cette id 
        const newErrorFieldGeneral = errorFieldGeneral.filter((errorField: { id: string | number; })=> String(errorField.id) !== String(partner.id));
        setErrorFieldGeneral(newErrorFieldGeneral);
    };

    const handleUpdatePartner = (partner: IPartner) => {
        // Mettre à jour dare
        const newPartners:any = challenge.partners.map((p) =>
            String(p.id) === String(partner.id) ? partner : p
        );
        // Mettre à jour challenge
        setChallenge({
            ...challenge,
            partners: newPartners
        });
        //Supprimer les errorFieldGeneral avec cette id 
        const newErrorFieldGeneral = errorFieldGeneral.filter((errorFieldGeneral: { id: any; })=> String(errorFieldGeneral.id) !== String(partner.id));
        setErrorFieldGeneral(newErrorFieldGeneral);
    };

    const handleAddPartner = () => {
        console.log('add partner');
        console.log("partner", challenge.partners);
        // Ajouter Partner
        const newPartner: any = {
            id: "new-"+uuidv4(),
            name: "",
            partnerLogo: "/images/photo-profil.jpg",
            partnerLink: null,
            position: challenge.partners.length + 1
        };

        // Ajouter newDare à challenge
        setChallenge({
            ...challenge,
            partners: [...challenge.partners, newPartner]
        });

    };

    useEffect(() => {
        console.log("partners", challenge.partners);
    }, [challenge.partners]);

    return (
        <div className="partners">
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={challenge.partners.sort((a, b) => a.position - b.position).map((partner) => partner.id)}
                    strategy={verticalListSortingStrategy}
                >
                    {challenge.partners
                        .map((partner) => (
                        <Partner
                            disabled={challenge.status.name==="finished"}
                            key={String(partner.id)}
                            partner={partner}
                            handleDeletePartner={handleDeletePartner}
                            handleUpdatePartner={handleUpdatePartner}
                        />
                    ))}
                </SortableContext>
            </DndContext>
            <ActionButton
                variant={challenge.status.name==="finished"?'disabled':'secondary'}
                onClick={challenge.status.name==="finished"?() => {} : handleAddPartner}
                className='button-add-dare'
                icon={<IconAdd className='icon-add'/>}
            >
                Ajouter un partenaire
            </ActionButton>
            {/* <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={partners.map((partner) => partner.id)}
                    strategy={verticalListSortingStrategy}
                >
                    {partners.map((partner: IPartner) => (
                        <Partner
                            key={partner.id}
                            partner={partner}
                            mobile={mobile}
                            handleDeletePartner={handleDeletePartner}
                            handleUpdatePartner={handleUpdatePartner}
                        />
                    ))}
                </SortableContext>
            </DndContext>
            <ActionButton
                variant='secondary'
                onClick={handleAddPartner}
                className='button-add-partner'
                icon={<IconAdd className='icon-add'/>}
            >
                Ajouter un partenaire
            </ActionButton> */}
        </div>
    )
}

export default PartnerList;