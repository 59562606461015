import "./index.css";
import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {RootState} from "../../../../../../app/type";
import {useSelector} from "react-redux";
import {
    ReactComponent as ImgNoUserInChallengeLeft
} from '../../../../../../assets/imgs/no_users_in_challenge_look_left.svg';
import {IChallenge} from "../../types";
import {IStep} from "src/utils/types";
import api from "../../../../../../utils/api";

const AdminInfosChallengeSelect = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {id} = useParams();
    const [links, setLinks] = useState([
        {
            label: 'Participants',
            key: 'participants',
            isSelected: true,
        },
        {
            label: 'Equipes',
            key: 'equipes',
            isSelected: false,
        }
    ]);
    const [challenge, setChallenge] = useState<IChallenge>();

    const getOneChallenge = async (id: string) => {
        await api.get('/challenge/preview', {
            params: {
                challengeId: id
            },
            withCredentials: true,
        })
            .then((res) => {
                setChallenge(res.data.challenge)
                console.log(res.data.challenge)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getOneChallenge(String(id));
    }, [id])
    // const challenge = useSelector((state: RootState) => state.challenges.challenge);

    return (
        <div className="container-admin-infos-challenge-select">
            {
                challenge && challenge?.countMembers > 0 ? (
                    <>
                        <div className="container-admin-infos-challenge-select-menu-tabs">
                            {
                                challenge.participationType !== "single" && (
                                    <div className="admin-infos-challenge-select-menu-tabs">
                                        {links.map((link, index) => (
                                            <p key={index}
                                               className={`${location.pathname.includes(link.key) ? 'selected' : ''}`}
                                               onClick={() => navigate(`/admin/challenge/${id}/select/${link.key}`)}>{link.label}</p>
                                        ))}
                                    </div>
                                )
                            }
                        </div>
                        <Outlet/>
                    </>
                ) : (
                    <div className={"no-users"}>
                        <p>En attente de participants !</p>
                        <ImgNoUserInChallengeLeft/>
                    </div>
                )
            }
        </div>
    )
}

export default AdminInfosChallengeSelect;
