// assets
import {ReactComponent as IconPen} from "../../../../../../assets/icon-pen.svg";
import {ReactComponent as IconMessage} from "../../../../../../assets/icon-message.svg";
import {ReactComponent as IconTel} from "../../../../../../assets/icon-tel.svg";
import {ReactComponent as IconChange} from "../../../../../../assets/icon-change-profile.svg";

//react-router-dom
import {Link, useNavigate, useLocation} from "react-router-dom";

// components
import CardName from "../../../../../../components/CardName"
import ActionButton from "../../../../../../components/ActionButton";
import ChangeProfileEmailModal from "../../../../../../components/ChangeProfileEmailModal";
import Modal from "../../../../../../modules/Modal";

import {ReactComponent as IconTrash} from "../../../../../../assets/icon-trash.svg";

// context
import {useAuth} from "../../../../../../context/AuthContext";

// pages
import ProfileUpdate from "../../ProfileUpdate";

// style
import './index.css'

// react
import {useEffect, useState} from "react";

// redux
import {useDispatch, useSelector} from "react-redux";
import {RootState} from '../../../../../../app/type';

// moment
import moment from 'moment';

// utils
import {openModal, isEmail} from "../../../../../../utils/functions";
import {Alert, Snackbar, TextField} from "@mui/material";
import {set} from "immutable";
import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";
import api from "../../../../../../utils/api";

export const GetMyProfileAdmin = (establishmentName: string, dispatch: any) => {
    api.get('/myinfo',
        {
            withCredentials: true,
            params: {
                establishment_name: establishmentName
            }
        })
        .then((res) => {
            dispatch({
                type: "SET_USER",
                payload: {
                    firstname: res.data.profile.user.firstname,
                    lastname: res.data.profile.user.lastname,
                    email_recup: res.data.profile.user.email,
                    email_pro: res.data.profile.email,
                    establishment: res.data.profile.establishments[0].name,
                    tel: res.data.profile.user.phone_number,
                    birthdate: res.data.profile.user.birthdate,
                    job: res.data.profile.user.job,
                    company: res.data.profile.user.company,
                    degree: res.data.profile.user.degree,
                    school: res.data.profile.user.school,
                    profile_picture: res.data.profile.user.profile_picture,
                }
            });
        })
        .catch((error) => {
            console.log(error);
        })
}

export function GetMyProfileParticipant(dispatch: any) {
    api.get('/participant_libre/myinfo',
        {
            withCredentials: true
        })
        .then((res) => {
            dispatch({
                type: "SET_USER",
                payload: {
                    firstname: res.data.profile.user.firstname,
                    lastname: res.data.profile.user.lastname,
                    email_recup: res.data.profile.user.email,
                    email_pro: res.data.profile.email,
                    establishment: res.data.profile.establishments?.name,
                    tel: res.data.profile.user.phone_number,
                    birthdate: res.data.profile.user.birthdate,
                    job: res.data.profile.user.job,
                    company: res.data.profile.user.company,
                    degree: res.data.profile.user.degree,
                    school: res.data.profile.user.school,
                    profile_picture: res.data.profile.user.profile_picture,
                }
            });
        })
        .catch((error) => {
            console.log(error);
        })
}

const ProfilePreviewInfos = () => {

    // navigation
    const navigate = useNavigate();

    // récupération de la page dans laquelle on se trouve
    const location = useLocation();

    // const [myProfile, setMyProfile] = useState(null as any);
    const dispatch = useDispatch();
    const establishmentName = useSelector((state: RootState) => state.user.establishment);
    console.log("establishmentName", establishmentName);
    const profileId = useSelector((action: RootState) => action.rootProfile.id);
    useEffect(() => {
        location.pathname.includes('admin') ? GetMyProfileAdmin(establishmentName, dispatch) :
            console.log('erreur')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        GetMyProfileParticipant(dispatch)
    }, [dispatch])

    const firstName = useSelector((action: RootState) => action.user.firstname);
    const lastName = useSelector((action: RootState) => action.user.lastname);
    const formattedFirstname = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    const formattedLastname = lastName.charAt(0).toUpperCase() + lastName.slice(1);

    const emailRecupDefaultValue = useSelector((action: RootState) => action.user.email_recup);
    const emailProDefaultValue = useSelector((action: RootState) => action.user.email_pro);
    const establishmentDefaultValue = useSelector((action: RootState) => action.user.establishment);
    let phoneDefaultValue = useSelector((action: RootState) => action.user.tel);
    const birthDateDefaultValue = useSelector((action: RootState) => action.user.birthdate);
    const profilePictureDefaultValue = useSelector((action: RootState) => action.user.profile_picture);
    const jobDefaultValue = useSelector((action: RootState) => action.user.job);
    const companyDefaultValue = useSelector((action: RootState) => action.user.company);
    const degreeDefaultValue = useSelector((action: RootState) => action.user.degree);
    const schoolDefaultValue = useSelector((action: RootState) => action.user.school);
    const nbProfiles = useSelector((action: RootState) => action.profile.nbProfiles);
    const roleName = useSelector((state: RootState) => state.rootProfile.name)

    const [errorDeleteProfile, setErrorDeleteProfile] = useState<string>("");
    const [succesMessageDeleteProfile, setSuccesMessageDeleteProfile] = useState<string>("");
    //update email
    // Email Recup
    const [isModalOpenModifiedemailRecup, setIsModalOpenModifiedemailRecup] = useState(false);
    const [updatedEmailRecup, setUpdatedEmailRecup] = useState("");
    const [errorEmailRecup, setErrorEmailRecup] = useState(false);
    const [errorEmailRecupMessage, setErrorEmailRecupMessage] = useState("");
    // Email Pro
    const [isModalOpenModifiedemailPro, setIsModalOpenModifiedemailPro] = useState(false);
    const [openModalDeleteProfile, setOpenModalDeleteProfile] = useState(false);
    const [updatedEmailPro, setUpdatedEmailPro] = useState("");
    const [errorEmailPro, setErrorEmailPro] = useState(false);
    const [errorEmailProMessage, setErrorEmailProMessage] = useState("");
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const {logout} = useAuth();
    const [isDeleteDisabled, setIsDeleteDisabled] = useState<boolean>(true);
    const [valueTextDisabled, setValueTextDisabled] = useState<string>("");
    // Translation
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const changeEmailRecup = async () => {
        const emailValid = isEmail(updatedEmailRecup)
        if (!emailValid) {
            setErrorEmailRecup(true);
            setErrorEmailRecupMessage(`${t("page.myProfile.error.validEmail")}`);
            return;
        }
        await api.put('/auth/change_emailreset',
            {
                language: localStorage.getItem("translation"),
                newemailreset: updatedEmailRecup,
                profileId: profileId
            },
            {
                withCredentials: true
            })
            .then((res) => {
                console.log(res.data)
                setUpdatedEmailRecup("");
                setIsModalOpenModifiedemailRecup(false);
                dispatch({
                    type: "SET_USER",
                    payload: {
                        email_recup: updatedEmailRecup,
                    }
                });
            })
            .catch((error) => {
                console.log(error);
                setErrorEmailRecup(true);
                setErrorEmailRecupMessage(error.response.data.message);
            })
    }

    const changeEmailPro = () => {
        console.log('change email pro');
        console.log(emailProDefaultValue);

        console.log(updatedEmailPro);

        const emailValid = isEmail(updatedEmailPro)
        if (!emailValid) {
            setErrorEmailPro(true);
            setErrorEmailProMessage(`${t("page.myProfile.error.validEmail")}`);
            return;
        }
        api.put('/auth/change_email',
            {
                language: localStorage.getItem("translation"),
                newemail: updatedEmailPro,
                profileId: profileId
            },
            {
                withCredentials: true
            })
            .then((res) => {
                console.log(res.data)
                setUpdatedEmailPro("");
                setIsModalOpenModifiedemailPro(false);
                dispatch({
                    type: "SET_USER",
                    payload: {
                        email_pro: updatedEmailPro,
                    }
                });
                localStorage.setItem('newEmailPro', updatedEmailPro);
                navigate("/my-profile/change/email");
            })
            .catch((error) => {
                console.log(error);
                setErrorEmailPro(true);
                setErrorEmailProMessage(error.response.data.message);
            });
    }
    const deleteAdmin = async (email: string) => {
        await api.delete(`/admin/delete_admin?email=${email}&establishment_name=${establishmentName}`,
            {
                withCredentials: true,
                params: {
                    language: localStorage.getItem("translation")
                }
            },
        )
            .then((data) => {
                setOpenModalDeleteProfile(false);
                setSuccesMessageDeleteProfile(data.data.message);
                setOpenModalDeleteProfile(false);
                setOpenSnackbar(true)
            })
            .catch((err) => {
                console.log(err);
                setErrorDeleteProfile(err.response.data.message);
            });
    }
    const deleteParticipant = async () => {
        await api.delete(`/participant/delete_profile`,
            {
                withCredentials: true,
                params: {
                    language: localStorage.getItem("translation")
                }
            }
        ).then((data) => {
            logout();
        }).catch((err) => {
            console.log(err);
            setErrorDeleteProfile(err.response.data.message);
        })
    }
    useEffect(() => {
        setIsDeleteDisabled(valueTextDisabled !== "DESACTIVER");
    }, [valueTextDisabled]);
    return (
        <>
            <div className='profile-admin-part'>
                <div className='block-profile-admin'>
                    <div className='block-pp-name'>
                        <CardName
                            img_url={profilePictureDefaultValue || 'https://ionicframework.com/docs/img/demos/avatar.svg'}
                            firstname={formattedFirstname}
                            lastname={formattedLastname}
                        />
                    </div>

                    <div className="block-buttons-profile">
                        <ActionButton
                            icon={<IconPen/>}
                            variant='primary'
                            onClick={() => {
                                navigate('/my-profile/update')
                            }}
                        >
                            {t("page.myProfile.modifyProfile")}
                        </ActionButton>
                        {(nbProfiles > 1) &&
                            <ActionButton
                                variant='tertiary'
                                icon={<IconChange/>}
                                onClick={() => {
                                    navigate('/login/choice/profile')
                                }}
                            >
                                {t("page.myProfile.changeProfile")}
                            </ActionButton>
                        }
                        <button className='button-link' onClick={() => setOpenModalDeleteProfile(true)}>
                            {t("page.myProfile.deactivateProfile")}
                        </button>
                    </div>
                    <div className='block-infos'>
                        {
                            roleName === "admin" ? (
                                <div className='profile-school-infos'>
                                    <div>
                                        <p className="text">{t("page.myProfile.admin")} :</p>
                                        <p className="h2">{establishmentDefaultValue || '...'}</p>
                                    </div>
                                    <Link className='text'
                                          to='/admin/school/config/general'>{t("page.myProfile.seePage")}</Link>
                                </div>
                            ) : null
                        }

                        <div className='private-infos'>
                            <div>
                                <p className="text"><span
                                    className="bold">{t("page.myProfile.job")} : </span>{jobDefaultValue ? jobDefaultValue : t("page.myProfile.noInfo")}
                                </p>
                                <>
                                    <p className="text"><span
                                        className="bold">{t("page.myProfile.company")} : </span>{companyDefaultValue ? companyDefaultValue : t("page.myProfile.noInfo")}
                                    </p>
                                    <p className="text"><span
                                        className="bold">{t("page.myProfile.degree")} : </span>{degreeDefaultValue ? degreeDefaultValue : t("page.myProfile.noInfo")}
                                    </p>
                                    <p className="text"><span
                                        className="bold">{t("page.myProfile.school")} : </span>{schoolDefaultValue ? schoolDefaultValue : t("page.myProfile.noInfo")}
                                    </p>
                                </>
                                <p className="text"><span
                                    className="bold">{t("page.myProfile.birthDate")} : </span>{birthDateDefaultValue ? moment(birthDateDefaultValue).format("DD/MM/YYYY") : t("page.myProfile.noInfo")}
                                </p>
                            </div>
                            <h2 className="h2">{t("page.myProfile.titleContact")}</h2>
                            <div className='coordonnees'>
                                <div className={"coordonnee"}>
                                    <div className={"coordonnee-left"}>
                                        <IconMessage/>
                                        <p className="text">{t("page.myProfile.profileEmail")} : {emailProDefaultValue || '...'}</p>
                                    </div>
                                    <IconPen className='icon-edit'
                                             onClick={() => setIsModalOpenModifiedemailPro(true)}
                                        //  onClick={() => openModal('modal-change-admin-profile-email')}
                                    />
                                    <ChangeProfileEmailModal id='modal-change-admin-profile-email'
                                                             email={emailProDefaultValue} type="pro"/>
                                </div>
                                <div className={"coordonnee"}>
                                    <div className={"coordonnee-left"}>
                                        <IconMessage/>
                                        <p className="text">{t("page.myProfile.recoveryEmail")}
                                            : {emailRecupDefaultValue || '...'}</p>
                                    </div>
                                    <IconPen className='icon-edit'
                                             onClick={() => setIsModalOpenModifiedemailRecup(true)}
                                        //  onClick={() => openModal('modal-change-admin-recup-email')}
                                    />
                                    <ChangeProfileEmailModal id="modal-change-admin-recup-email"
                                                             email={emailRecupDefaultValue} type="recup"/>
                                </div>
                                <div>
                                    <div className={"coordonnee-left"}>
                                        <IconTel/>
                                        <p className="text">{phoneDefaultValue || t("page.myProfile.noInfo")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal
                            title={`${t("page.myProfile.modalRecoveryEmail.title")}`}
                            open={isModalOpenModifiedemailRecup}
                            onClose={() => setIsModalOpenModifiedemailRecup(false)}
                            tertiaryButtonText={`${t("form.cancel")}`}
                            tertiaryButtonAction={() => {
                                setIsModalOpenModifiedemailRecup(false);
                                setUpdatedEmailRecup("");
                                setErrorEmailRecup(false);
                                setErrorEmailRecupMessage("")
                            }}
                            primaryButtonAction={() => {
                                changeEmailRecup()
                            }}
                            primaryButtonText={`${t("form.edit")}`}
                            primaryButtonDisabled={(updatedEmailRecup === emailRecupDefaultValue) || (updatedEmailRecup === "")}
                        >
                            <p className="text-center">{t("page.myProfile.modalRecoveryEmail.text1")}</p>
                            <TextField
                                label={`${t("page.myProfile.modalRecoveryEmail.labelCurrentEmail")}`}
                                variant="outlined"
                                fullWidth
                                required
                                type="email"
                                value={emailRecupDefaultValue}
                                className="input-text-field"
                                disabled
                            />
                            <TextField
                                label={`${t("page.myProfile.modalRecoveryEmail.labelNewEmail")}`}
                                variant="outlined"
                                fullWidth
                                required
                                type="email"
                                value={updatedEmailRecup}
                                onChange={(e) => {
                                    setUpdatedEmailRecup(e.target.value);
                                    setErrorEmailRecup(false);
                                    setErrorEmailRecupMessage("")
                                }}
                                className="input-text-field"
                                error={errorEmailRecup}
                                helperText={errorEmailRecupMessage}
                            />
                        </Modal>
                        <Modal
                            title={`${t("page.myProfile.modalEmail.title")}`}
                            open={isModalOpenModifiedemailPro}
                            onClose={() => setIsModalOpenModifiedemailPro(false)}
                            tertiaryButtonText={`${t("form.cancel")}`}
                            tertiaryButtonAction={() => {
                                setIsModalOpenModifiedemailPro(false);
                                setUpdatedEmailPro("");
                                setErrorEmailPro(false);
                                setErrorEmailProMessage("")
                            }}
                            primaryButtonAction={() => {
                                changeEmailPro()
                            }}
                            primaryButtonText={`${t("form.edit")}`}
                            primaryButtonDisabled={(updatedEmailPro === emailProDefaultValue) || (updatedEmailPro === "")}
                        >
                            <p className="text-center">{t("page.myProfile.modalEmail.text1")}</p>
                            <TextField
                                label={`${t("page.myProfile.modalRecoveryEmail.labelCurrentEmail")}`}
                                variant="outlined"
                                fullWidth
                                required
                                type="email"
                                value={emailProDefaultValue}
                                className="input-text-field"
                                disabled
                            />
                            <TextField
                                label={`${t("page.myProfile.modalRecoveryEmail.labelNewEmail")}`}
                                variant="outlined"
                                fullWidth
                                required
                                type="email"
                                value={updatedEmailPro}
                                onChange={(e) => {
                                    setUpdatedEmailPro(e.target.value);
                                    setErrorEmailPro(false);
                                    setErrorEmailProMessage("")
                                }}
                                className="input-text-field"
                                error={errorEmailPro}
                                helperText={errorEmailProMessage}
                            />
                        </Modal>
                        <Modal
                            title={`${t("page.myProfile.deactivateProfile")}`}
                            open={openModalDeleteProfile}
                            onClose={() => {
                                setOpenModalDeleteProfile(false);
                                setErrorDeleteProfile("");
                                setValueTextDisabled("");
                            }}
                            tertiaryButtonText={`${t("form.cancel")}`}
                            primaryButtonText={`${t("form.deactivate")}`}
                            primaryButtonDisabled={isDeleteDisabled}
                            tertiaryButtonAction={() => {
                                setOpenModalDeleteProfile(false);
                                setErrorDeleteProfile("");
                                setValueTextDisabled("");
                            }}
                            primaryButtonAction={() => {
                                roleName === 'admin' && deleteAdmin(emailProDefaultValue);
                                roleName === 'participant' && deleteParticipant();
                            }
                            }
                            statusError={true}
                            icon={<IconTrash/>}
                            iconColor={"errorIconFill"}
                        >
                            <p>
                                {t("page.myProfile.modalDeactivate.text1")} <a
                                href={"mailto:contact@challenkers.com"}>contact@challenkers.com</a>.
                            </p>
                            <p>{t("page.myProfile.modalDeactivate.text2")}</p>
                            <TextField
                                label={`${t("page.myProfile.modalDeactivate.labelDeactivate")}`}
                                variant="outlined"
                                fullWidth
                                required
                                type="text"
                                value={valueTextDisabled}
                                onChange={(e) => {
                                    setValueTextDisabled(e.target.value);
                                }}
                                className="input-text-field"
                            />
                            {
                                errorDeleteProfile && (
                                    <p className={"error-message"}>{errorDeleteProfile}</p>
                                )
                            }

                        </Modal>
                    </div>
                </div>
                <Snackbar
                    open={openSnackBar}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackbar(false)}>
                    <Alert severity="success">
                        {succesMessageDeleteProfile}
                    </Alert>
                </Snackbar>
            </div>
        </>
    )
}

export default ProfilePreviewInfos;
