import React, { ReactComponentElement, useEffect } from "react";
import ActionButton from "../../components/ActionButton";
import "./index.css";
import { Link } from "react-router-dom";
import { ReactComponent as IconClose } from "../../assets/icon-close.svg";

interface ModalProps {
    title?: React.ReactNode;
    children?: React.ReactNode;
    primaryButtonText?: string | React.ReactNode;
    primaryButtonAction?: () => void;
    primaryButtonDisabled?: boolean | undefined | null;
    secondaryButtonText?: string | React.ReactNode;
    secondaryButtonAction?: () => void;
    secondaryButtonDisabled?: boolean;
    tertiaryButtonText?: string | React.ReactNode;
    tertiaryButtonAction?: () => void;
    tertiaryButtonDisabled?: boolean;
    underlineButtonText?: string;
    underlineButtonAction?: () => void;
    underlineButtonDisabled?: boolean;
    icon?: ReactComponentElement<any> | string;
    iconColor?: string;
    open: boolean;
    onClose: () => void;
    onClick?: () => void;
    buttonsColumn?: boolean;
    link?: string;
    linkText?: string;
    linkAction?: () => void;
    error?: string | null;
    statusError?: boolean;
    info?: boolean | null;
    hideIconClose?: boolean | null;
}

const Modal: React.FC<ModalProps> = ({
    icon,
    iconColor,
    title,
    primaryButtonText,
    primaryButtonAction,
    primaryButtonDisabled,
    secondaryButtonText,
    secondaryButtonAction,
    secondaryButtonDisabled,
    tertiaryButtonText,
    tertiaryButtonAction,
    tertiaryButtonDisabled,
    underlineButtonText,
    underlineButtonAction,
    underlineButtonDisabled,
    open,
    onClose,
    children,
    buttonsColumn,
    link,
    linkText,
    linkAction,
    error,
    statusError,
    info,
    hideIconClose
}) => {
    useEffect(() => {
        const handleBodyScroll = (event: Event) => {
            event.preventDefault();
        };

        if (open) {
            document.body.style.overflow = "hidden";
            document.body.addEventListener("scroll", handleBodyScroll, {
                passive: false,
            });
        } else {
            document.body.style.overflow = "auto";
            document.body.removeEventListener("scroll", handleBodyScroll);
        }
    }, [open]);
    return (
        <div
            className={`component-modal ${open ? "component-modal-visible" : "component-modal-hidden"}`}
        >
            <div
                className={`component-modal-overlay ${open ? "component-modal-overlay-visible" : "component-modal-overlay-hidden"}`}
                onClick={onClose}
            ></div>
            <div
                className={`component-modal-content ${info ? "infoModal" : ""}  ${(error && error !== null) || (statusError && statusError) ? "errorModal" : ""} ${open ? 'component-modal-content-visible' : 'component-modal-content-hidden'}`}>
                <div className="component-modal-content-header">
                    <div className={iconColor && iconColor}>
                        {icon && icon}
                    </div>
                    <h2>{title}</h2>
                </div>
                <div className="component-modal-content-body">
                    {
                        !hideIconClose && (
                            <div style={{ position: 'absolute', top: "1rem", right: "1rem", cursor: "pointer" }} onClick={onClose}>
                                <IconClose />
                            </div>
                        )
                    }
                    {children}
                    {error && <p className="errorModal-message">{error}</p>}
                </div>
                <div className="component-modal-content-footer">
                    <div className={`buttons ${buttonsColumn ? 'buttons-column' : ''}`}>
                        {tertiaryButtonText && (
                            <ActionButton variant={`${tertiaryButtonDisabled ? 'disabled' : 'tertiary'}`}
                                onClick={() => {
                                    if (tertiaryButtonDisabled === undefined) {
                                        return tertiaryButtonAction && tertiaryButtonAction();
                                    } else {
                                        if (!tertiaryButtonDisabled) {
                                            return tertiaryButtonAction && tertiaryButtonAction();
                                        }
                                    }
                                }}>
                                {tertiaryButtonText}
                            </ActionButton>
                        )}
                        {secondaryButtonText && (
                            <ActionButton variant={`${secondaryButtonDisabled ? 'disabled' : 'secondary'}`}
                                onClick={() => {
                                    if (secondaryButtonDisabled === undefined) {
                                        return secondaryButtonAction && secondaryButtonAction();
                                    } else {
                                        if (!secondaryButtonDisabled) {
                                            return secondaryButtonAction && secondaryButtonAction();
                                        }
                                    }
                                }}>
                                {secondaryButtonText}
                            </ActionButton>
                        )
                        }
                        {primaryButtonText && primaryButtonAction && (
                            <ActionButton variant={`${primaryButtonDisabled ? 'disabled' : 'primary'}`}
                                onClick={() => {
                                    if (primaryButtonDisabled === undefined) {
                                        return primaryButtonAction();
                                    } else {
                                        if (!primaryButtonDisabled) {
                                            return primaryButtonAction();
                                        }
                                    }
                                }}>
                                {primaryButtonText}
                            </ActionButton>
                        )}
                        {underlineButtonText && (
                            <ActionButton variant={`${underlineButtonDisabled ? 'disabled' : 'little-underline'}`}
                                onClick={underlineButtonAction}>
                                {underlineButtonText}
                            </ActionButton>
                        )}
                        {linkText && (
                            <Link to={`${link}`} onClick={linkAction}><span
                                className='linkModal'>{linkText}</span></Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;