import React, {useEffect, useRef, useState} from 'react';
import './index.css';
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
import {useMediaQuery, useTheme} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

/**
 *  * ## MenuTabs
 *  *
 *  * ---
 *  *
 *  * Module permettant d'afficher des onglets pour desktop et mobile.
 *
 * ---
 *
 * @param {React.ReactNode} children - Le texte à afficher dans le bouton
 * @param {string} type - Le type du bouton (button, submit, reset)
 * @param {() => void} onClick - La fonction à exécuter au clic
 * @param {string} variant - Le type de bouton (primary, grey, secondary, grey-profiles)
 * @param {string} className - Les classes CSS à appliquer au bouton
 * @param {number | string} key - La clé du bouton
 * @param {ReactComponentElement<any>} icon - L'icône à afficher à gauche du texte
 * @param {boolean} focus - Si le bouton est focus ou non
 * @param {boolean} auth - Si le bouton est un bouton d'authentification ou non
 *
 * ---
 *
 * @example
 * <ActionButton
 * type='submit'
 * onClick={() => submitForm()}
 * variant='primary'
 * className='button-auth'
 * key={0}
 * icon={<DoneIcon/>}>
 *    Valider
 * </ActionButton>
 *
 */
interface ILink {
    label: string;

    key: string;

    link?: string;

    onClick?: () => void;

    isSelected?: boolean;
}

interface IMenuTabs {
    links: ILink[];

    variant: 'menu-tabs-primary' | 'menu-tabs-secondary';

    className?: string;
}

const MenuTabs = ({links, variant, className}: IMenuTabs) => {
    const location = useLocation();
    const navigate = useNavigate();

    const blackListEdit = [
        "/admin/challenges/edit/"
    ]
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    useEffect(() => {
        setIsEdit(blackListEdit.some(item => location.pathname.includes(item)));
        console.log(isDesktop);
    }, [location, isDesktop]);
    useEffect(() => {
        // Cherchez l'index de l'onglet dont le lien correspond à l'URL actuelle
        const activeTabIndex = links.findIndex(link => location.pathname.includes(link.key));

        // Mettez à jour la valeur de l'onglet actif seulement si un onglet correspond à l'URL
        if (activeTabIndex !== -1) {
            setValue(activeTabIndex);
        }
    }, [location.pathname, links]);

    const selectedLink = (link: ILink) => {
        if(location.pathname.includes('/admin/challenges/edit') && link.onClick) {
            link.onClick();
        } else if (!location.pathname.includes('/admin/challenges/edit/') && link.link) {
            navigate(link.link)
        }
    }
    return (
        <>
            <div
                id="menu-tabs"
                className={`menu-tabs ${variant ? variant : ''} ${className ? className : ''} ${!isDesktop ? 'menu-tabs-mobile' : ''}`}>
                {isDesktop || isEdit ?
                    (
                        links.map((link, index: number) => (
                            <div
                                className={`menu-tabs-button ${isEdit ? 'menu-tabs-button-edit' : ''} ${location.pathname === link.link
                                    ? 'menu-tabs-button-active'
                                    : ''} ${link.isSelected && 'menu-tabs-button-active'}`
                                }
                                onClick={() => {
                                    link.link && selectedLink(link);
                                }}
                                    key={index}
                                    >
                                {link.label}
                                    </div>
                                    ))
                                    )
                                    :
                                    (
                                    <Box sx={{width: "100%", border: "1px solid black", borderRadius: "10px"}}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    aria-label="scrollable force tabs example"
                                    TabIndicatorProps={{
                                        style: {
                                            width: "0",
                                        }
                                    }}
                                    sx={{
                                        '.MuiTabs-scrollButtons.Mui-disabled': {
                                            opacity: 0.3,
                                            backgroundColor: "rgba(0,0,0,0.15)"
                                        },
                                        '.MuiTabs-scrollButtons': {
                                            backgroundColor: "rgba(0,0,0,0.05)",
                                        },
                                        '.MuiTabs-scrollButtons:nth-child(1)': {
                                            borderRight: "1px solid black",
                                        },
                                        '.MuiTabs-scrollButtons:last-child(1)': {
                                            borderLeft: "1px solid black",
                                        },

                                    }}
                                >
                                    {
                                        links.map((link, index) => (
                                            <Tab
                                                key={index}
                                                label={link.label}
                                                sx={{
                                                    '&.Mui-selected': {
                                                        backgroundColor: 'black',
                                                        color: 'white',
                                                    },
                                                    'button:last-child(1)': {
                                                        border: "none",
                                                    },
                                                    borderRight: "1px solid black",
                                                    fontFamily: "var(--font-tertiary)",
                                                    textTransform: "none",
                                                }}
                                                onClick={() => {
                                                    link.link && selectedLink(link);
                                                }}
                                            />
                                        ))
                                    }
                                </Tabs>
                            </Box>
                        )
                }
            </div>
        </>
    );
};

// @ts-ignore
export default MenuTabs;