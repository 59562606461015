// react-dom
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';

// react
import React, {useEffect} from 'react';

// components
// import DiamondLoader from '../components/DiamondLoader';

// protected routes
// import ProtectedRoute from './ProtectedRoutes';

// context
import {useAuth} from '../context/AuthContext';

// ---------------------------------------------------------------------------------------------------------------------------------------------------------
// ---- POST -----------------------------------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------------------------------

// pages

// admin
import AdminChallenges from '../pages/MINIMAL/Admin/AdminChallenges';
import AdminCreateChallenge from '../pages/MINIMAL/Admin/AdminChallenges/AdminCreateChallenge';
import AdminEditChallenge from '../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge';
import AdminEditChallengeCriteria
    from '../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/AdminEditChallengeCriteria';
import AdminEditChallengeGeneral
    from '../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/AdminEditChallengeGeneral';
import AdminEditChallengeSignUp
    from '../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/AdminEditChallengeSignUp';
import AdminEditChallengeRessources
    from '../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/AdminEditChallengeRessources';
import AdminEditChallengeSteps from '../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/AdminEditChallengeSteps';
import AdminInfosChallenge from '../pages/MINIMAL/Admin/AdminChallenges/AdminInfosChallenge';
import AdminInfosChallengeGeneral
    from '../pages/MINIMAL/Admin/AdminChallenges/AdminInfosChallenge/AdminInfosChallengeGeneral';
import AdminInfosChallengePlanning
    from '../pages/MINIMAL/Admin/AdminChallenges/AdminInfosChallenge/AdminInfosChallengePlanning';
import AdminInfosChallengeProjects
    from '../pages/MINIMAL/Admin/AdminChallenges/AdminInfosChallenge/AdminInfosChallengeProjects';
import AdminInfosChallengeSelect
    from '../pages/MINIMAL/Admin/AdminChallenges/AdminInfosChallenge/AdminInfosChallengeSelect';
import Challenge from '../pages/MINIMAL/Global/Challenge';
import ChallengeGeneral
    from '../pages/MINIMAL/Global/Challenge/ChallengeGeneral';
import ChallengePlanning
    from '../pages/MINIMAL/Global/Challenge/ChallengePlanning';
import ChallengeFaq
    from '../pages/MINIMAL/Global/Challenge/ChallengeFaq';
import ChallengeRessources
    from '../pages/MINIMAL/Global/Challenge/ChallengeRessources';
import AdminConfig from '../pages/MINIMAL/Admin/AdminConfig';
import AdminConfigChallenges from '../pages/MINIMAL/Admin/AdminConfig/AdminConfigChallenges';
import AdminConfigGeneral from '../pages/MINIMAL/Admin/AdminConfig/AdminConfigGeneral';
import AdminConfigGeneralUpdate from '../pages/MINIMAL/Admin/AdminConfig/AdminConfigGeneral/AdminConfigGeneralUpdate';
import AdminConfigUsers from '../pages/MINIMAL/Admin/AdminConfig/AdminConfigUsers';
import AdminConfigUsersAdd from '../pages/MINIMAL/Admin/AdminConfig/AdminConfigUsers/AdminConfigUsersAdd';

// participant
import ParticipantHome from '../pages/MINIMAL/Participant/ParticipantHome';
// import ParticipantChallenge from "../modules/SubscribeChallenge";
import ParticipantTeamsJoin from "../pages/MINIMAL/Participant/ParticipantTeams/ParticipantJoinTeam";
import ParticipantTeamsJoinInvite from "../pages/MINIMAL/Participant/ParticipantTeams/ParticipantJoinTeamInvite";
import TeamManagement from "../pages/MINIMAL/Participant/ParticipantTeams/TeamManagement";
import ParticipantChallenges from "../pages/MINIMAL/Participant/ParticipantChallenges";
// import ParticipantChallengesFindChallenge from '../pages/MINIMAL/Participant/ParticipantChallenges/ParticipantChallengesFindChallenge' ;
import ParticipantProjects from '../pages/MINIMAL/Participant/ParticipantProjects';
import ParticipantProjectChallengePage
    from '../pages/MINIMAL/Participant/ParticipantProjects/ParticipantProjectChallengePage';
import ParticipantProjectFocus from '../pages/MINIMAL/Participant/ParticipantProjects/ParticipantProjectFocus';
import ParticipantProjectsAll from '../pages/MINIMAL/Participant/ParticipantProjects/ParticipantProjectsAll';

import ProjectRunning from "../pages/MINIMAL/Participant/ParticipantProjects/ProjectRunning";
import Project from "../pages/MINIMAL/Participant/ParticipantProjects/ParticipantProjectFocus";
import ListChallengeSubscribes from "../modules/Lists/Challenge/Subscribes";
import ListChallengeTeams from "../modules/Lists/Challenge/Teams";
// global
import Home from "../pages/MINIMAL/Global/Home";
import Profile from '../pages/MINIMAL/Global/Profile';
import ProfilePreview from '../pages/MINIMAL/Global/Profile/ProfilePreview';
import ProfileUpdate from '../pages/MINIMAL/Global/Profile/ProfileUpdate';
import ProfileUpdateEmail from '../pages/MINIMAL/Global/Profile/ProfileUpdate/ProfileUpdateEmail';
import ProfilePreviewInfos from '../pages/MINIMAL/Global/Profile/ProfilePreview/ProfilePreviewInfos';
import ProfilePreviewChallenges from '../pages/MINIMAL/Global/Profile/ProfilePreview/ProfilePreviewChallenges';
import Test from '../pages/MINIMAL/Test';
import AlphaMakee from '../pages/MINIMAL/Global/AlphaMakee';
import ChallengeResultats from '../pages/MINIMAL/Global/Challenge/ChallengeResultats';

// login
import Login from '../pages/MINIMAL/Auth/Login';
import LoginEmailPsw from '../pages/MINIMAL/Auth/Login/LoginEmailPsw';
import LoginChoice from '../pages/MINIMAL/Auth/Login/LoginChoice';
import LoginProfile from '../pages/MINIMAL/Auth/Login/LoginChoice/LoginProfile';
import LoginSpeaker from '../pages/MINIMAL/Auth/Login/LoginChoice/LoginProfile/LoginSpeaker';
import LoginAdmin from '../pages/MINIMAL/Auth/Login/LoginChoice/LoginProfile/LoginAdmin';
// signup
import Signup from '../pages/MINIMAL/Auth/Signup';
import Invite from '../pages/MINIMAL/Auth/Signup/Invite';
import VerifyCode from '../pages/MINIMAL/Auth/Signup/Invite/VerifyCode';
import InvNameEmailSchool from '../pages/MINIMAL/Auth/Signup/Invite/InvNameEmailSchool';
import InvProfile from '../pages/MINIMAL/Auth/Signup/Invite/InvProfile';
import CreateAccount from '../pages/MINIMAL/Auth/Signup/CreateAccount';
import CreateAccountPswTelEmail from '../pages/MINIMAL/Auth/Signup/CreateAccount/CreateAccountPswTelEmail';
import CreateAccountDone from '../pages/MINIMAL/Auth/Signup/CreateAccount/CreateAccountDone';
import MergeAccount from '../pages/MINIMAL/Auth/Signup/MergeAccount';
import ProfileMerge from '../pages/MINIMAL/Auth/Signup/MergeAccount/ProfileMerge';
import ProfileMergeSearch from '../pages/MINIMAL/Auth/Signup/MergeAccount/ProfileMerge/ProfileMergeSearch';
import ProfileSummary from '../pages/MINIMAL/Auth/Signup/MergeAccount/ProfileSummary';
import MergeExistingAccount from '../pages/MINIMAL/Auth/Signup/MergeAccount/MergeExistingAccount';
import SignupParticipantNameEmail from '../pages/MINIMAL/Auth/SignupParticipant/SignupParticipantNameEmail';
import SignupParticipantEmail from '../pages/MINIMAL/Auth/SignupParticipant/SignupParticipantEmail';
// 404 error
import Error404 from '../pages/MINIMAL/Error404';
// 401 error
import Error401 from '../pages/MINIMAL/Error401';
// 498 error
import Error498 from '../pages/MINIMAL/Error498';

// password reset (forgot password)
import ResetPassword from '../pages/MINIMAL/Auth/PasswordReset/ResetPassword';
import ForgotPassword from '../pages/MINIMAL/Auth/PasswordReset/ForgotPassword';
import ResetDone from '../pages/MINIMAL/Auth/PasswordReset/ForgotPassword/ResetDone';
import ResetPasswordDone from '../pages/MINIMAL/Auth/PasswordReset/ResetPassword/Done';

// tutorial
// const Introduction = React.lazy(() => import('../pages/POST/MakeeIntroduction'));
// const MakeeIntroduction = React.lazy(() => import('../pages/POST/MakeeIntroduction/MakeeIntroduction'));
// const MakeeIntroduction1 = React.lazy(() => import('../pages/POST/MakeeIntroduction/MakeeIntroduction/MakeeIntroduction1'));
// const MakeeIntroduction2 = React.lazy(() => import('../pages/POST/MakeeIntroduction/MakeeIntroduction/MakeeIntroduction2'));
// const MakeeIntroduction3 = React.lazy(() => import('../pages/POST/MakeeIntroduction/MakeeIntroduction/MakeeIntroduction3'));
// const SignupTutorial = React.lazy(() => import('../pages/POST/MakeeIntroduction/SignupTutorial'));
// const SignupTutorial1 = React.lazy(() => import('../pages/POST/MakeeIntroduction/SignupTutorial/SignupTutorial1'));
// const SignupTutorial2 = React.lazy(() => import('../pages/POST/MakeeIntroduction/SignupTutorial/SignupTutorial2'));

// ---------------------------------------------------------------------------------------------------------------------------------------------------------
// ---- MINIMAL --------------------------------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------------------------------
import ProtectedRoute from "./ProtectedRoutes";
import AdminInfosChallengeProjectsSelections
    from "../pages/MINIMAL/Admin/AdminChallenges/AdminInfosChallenge/AdminInfosChallengeProjectsSelections";
import ChallengeMyProject from "../pages/MINIMAL/Global/Challenge/ChallengeMyProject";
import ProfileChangeEmail from "../pages/MINIMAL/Global/Profile/ProfileUpdate/ProfileChangeEmail";
import ProjectEdition from "../pages/MINIMAL/Participant/ParticipantProjects/ProjectEdition";

export default function RouterComponent() {

    const {isAuthenticated, login, finishedCheckingAuth} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const checkAuth = async () => {
            await login();
        };

        if (!finishedCheckingAuth) {
            checkAuth();
        }
    }, [finishedCheckingAuth, login]);

    if (!finishedCheckingAuth) {
        // Attendre que la vérification d'authentification soit terminée
        return null; // ou un composant de chargement si vous le souhaitez
    }
    return (
        // <Routes>
        <Routes>
            {
                isAuthenticated ? (
                    <Route path="/" element={<Home/>}/>
                ) : (
                    <Route path="/" element={<AlphaMakee/>}/>
                )
            }

            <Route path={"/challengetest/:id"} element={<ProtectedRoute Component={Challenge}/>}/>

            {/* LOGIN */}
            <Route path="/login" element={<Login/>}>
                <Route path="/login/1"
                       element={<ProtectedRoute Component={LoginEmailPsw} unAuthenticatedOnly={true}/>}/>
                {/* quand tu choisis ton profil pour la connexion (admin/participant/intervenant) */}
                <Route path="/login/choice" element={<ProtectedRoute Component={LoginChoice}/>}>
                    <Route path="/login/choice/profile" element={<ProtectedRoute Component={LoginProfile}/>}>
                        {/* choix de l'école */}
                        <Route path="/login/choice/profile/intervenant"
                               element={<ProtectedRoute Component={LoginSpeaker}/>}/>
                        <Route path="/login/choice/profile/admin" element={<ProtectedRoute Component={LoginAdmin}/>}/>
                    </Route>
                </Route>
            </Route>

            {/* SIGNUP */}
            <Route path="/signup" element={<Signup/>}>
                {/* SIGNUP ADMIN */}
                {/* on reçoit une invitation de l'école par mail */}
                <Route path="/signup/admin/invite" element={<Invite/>}>
                    <Route path="/signup/admin/invite/code/:token" element={<VerifyCode/>}/>
                    <Route path="/signup/admin/invite/profile/:token" element={<InvNameEmailSchool/>}/>
                    <Route path="/signup/admin/invite/account" element={<InvProfile/>}/>
                </Route>

                {/* création d'un nouveau compte */}
                <Route path="/signup/admin/create" element={<CreateAccount/>}>
                    <Route path="/signup/admin/create/infos" element={<CreateAccountPswTelEmail/>}/>
                    <Route path="/signup/admin/create/done" element={<CreateAccountDone/>}/>
                </Route>

                {/* fusionner à un compte préeexistant */}
                <Route path="/signup/admin/merge" element={<MergeAccount/>}>
                    <Route path="/signup/admin/merge/search" element={<ProfileMerge/>}>
                        <Route path="/signup/admin/merge/search/search" element={<ProfileMergeSearch/>}/>
                    </Route>
                    <Route path="/signup/admin/merge/connect" element={<MergeExistingAccount/>}/>
                    <Route path="/signup/admin/merge/summary" element={<ProfileSummary/>}/>
                </Route>

                {/* SIGNUP PARTICIPANT LIBRE */}
                <Route path="/signup/participant"
                       element={<ProtectedRoute Component={SignupParticipantNameEmail} unAuthenticatedOnly={true}/>}/>
                <Route path="/signup/participant/code/:token"
                       element={<ProtectedRoute Component={VerifyCode} unAuthenticatedOnly={true}/>}/>
                <Route path="/signup/participant/email"
                       element={<ProtectedRoute Component={SignupParticipantEmail} unAuthenticatedOnly={true}/>}/>
                <Route path="/signup/participant/merge"
                       element={<ProtectedRoute Component={MergeAccount} unAuthenticatedOnly={true}/>}>
                    <Route path="/signup/participant/merge/search"
                           element={<ProtectedRoute Component={ProfileMerge} unAuthenticatedOnly={true}/>}>
                        <Route path="/signup/participant/merge/search/search"
                               element={<ProtectedRoute Component={ProfileMergeSearch} unAuthenticatedOnly={true}/>}/>
                    </Route>
                    <Route path="/signup/participant/merge/connect"
                           element={<MergeExistingAccount/>}/>
                    <Route path="/signup/participant/merge/summary"
                           element={<ProfileSummary/>}/>
                </Route>
                <Route path="/signup/participant/create"
                       element={<ProtectedRoute Component={CreateAccount} unAuthenticatedOnly={true}/>}>
                    <Route path="/signup/participant/create/infos"
                           element={<ProtectedRoute Component={CreateAccountPswTelEmail} unAuthenticatedOnly={true}/>}/>
                    <Route path="/signup/participant/create/done"
                           element={<ProtectedRoute Component={CreateAccountDone} unAuthenticatedOnly={true}/>}/>
                </Route>
            </Route>

            {/* PASSWORD RESET */}
            <Route path="/reset-password" element={<ResetPassword/>}/>
            <Route path="/forgot/password/:token" element={<ForgotPassword/>}/>
            <Route path="/forgot/password/done" element={<ResetDone/>}/>
            <Route path="/reset-password/done" element={<ResetPasswordDone/>}/>

            {/* ADMIN CHALLENGES */}
            <Route path="/admin/challenges" element={<ProtectedRoute Component={AdminChallenges}/>}/>
            <Route path="/admin/challenges/create" element={<ProtectedRoute Component={AdminCreateChallenge}/>}/>
            <Route path="/admin/challenge/:id" element={<ProtectedRoute Component={AdminInfosChallenge}/>}>
                <Route path="/admin/challenge/:id/general"
                       element={<ProtectedRoute Component={AdminInfosChallengeGeneral}/>}/>
                <Route path="/admin/challenge/:id/planning"
                       element={<ProtectedRoute Component={AdminInfosChallengePlanning}/>}/>
                <Route path="/admin/challenge/:id/projects"
                       element={<ProtectedRoute Component={AdminInfosChallengeProjects}/>}/>
                <Route path="/admin/challenge/:id/select/"
                       element={<ProtectedRoute Component={AdminInfosChallengeSelect}/>}>
                    <Route path="/admin/challenge/:id/select/participants"
                           element={<ProtectedRoute Component={ListChallengeSubscribes}/>}/>
                    <Route path="/admin/challenge/:id/select/equipes"
                           element={<ProtectedRoute Component={ListChallengeTeams}/>}/>
                </Route>
            </Route>
            <Route path={"/admin/challenge/:id/projects/selections"}
                   element={<ProtectedRoute Component={AdminInfosChallengeProjectsSelections}/>}/>
            <Route path="/admin/challenges/edit" element={<ProtectedRoute Component={AdminEditChallenge}/>}>
                <Route path="/admin/challenges/edit/general/:id"
                       element={<ProtectedRoute Component={AdminEditChallengeGeneral}/>}/>
                <Route path="/admin/challenges/edit/sign-up/:id"
                       element={<ProtectedRoute Component={AdminEditChallengeSignUp}/>}/>
                <Route path="/admin/challenges/edit/steps/:id"
                       element={<ProtectedRoute Component={AdminEditChallengeSteps}/>}/>
                <Route path="/admin/challenges/edit/ressources/:id"
                       element={<ProtectedRoute Component={AdminEditChallengeRessources}/>}/>
                <Route path="/admin/challenges/edit/criteria/:id"
                       element={<ProtectedRoute Component={AdminEditChallengeCriteria}/>}/>
            </Route>
            <Route path="/preview/challenge/:id" element={<ProtectedRoute Component={Challenge}/>}>
                <Route path="/preview/challenge/:id/general" element={<ProtectedRoute Component={ChallengeGeneral}/>}/>
                <Route path="/preview/challenge/:id/planning"
                       element={<ProtectedRoute Component={ChallengePlanning}/>}/>
                <Route path="/preview/challenge/:id/faq" element={<ProtectedRoute Component={ChallengeFaq}/>}/>
                <Route path="/preview/challenge/:id/ressources"
                       element={<ProtectedRoute Component={ChallengeRessources}/>}/>
                <Route path="/preview/challenge/:id/mon-projet"
                       element={<ProtectedRoute Component={ChallengeRessources}/>}/>
            </Route>
            {/* ADMIN SCHOOL CONFIG */}
            <Route path="/admin/school/config" element={<ProtectedRoute Component={AdminConfig}/>}>
                <Route path="/admin/school/config/general" element={<ProtectedRoute Component={AdminConfigGeneral}/>}/>
                <Route path="/admin/school/config/general/update"
                       element={<ProtectedRoute Component={AdminConfigGeneralUpdate}/>}/>
                <Route path="/admin/school/config/user" element={<ProtectedRoute Component={AdminConfigUsers}/>}>
                    <Route path="/admin/school/config/user/add"
                           element={<ProtectedRoute Component={AdminConfigUsersAdd}/>}/>
                </Route>
                <Route path="/admin/school/config/challenges"
                       element={<ProtectedRoute Component={AdminConfigChallenges}/>}/>
            </Route>
            {/* PROFILE */}
            <Route path="/my-profile" element={<ProtectedRoute Component={ProfilePreviewInfos}/>}/>
            <Route path="/my-profile/update" element={<ProtectedRoute Component={ProfileUpdate}/>}/>
            <Route path="/my-profile/update/email" element={<ProtectedRoute Component={ProfileUpdateEmail}/>}/>
            <Route path="/my-profile/change/email" element={<ProtectedRoute Component={ProfileChangeEmail}/>}/>
            {/* ADMIN PROFILE */}
            <Route path="/admin/profile" element={<ProtectedRoute Component={Profile}/>}>
                <Route path="/admin/profile/preview" element={<ProtectedRoute Component={ProfilePreview}/>}>
                    <Route path="/admin/profile/preview/profile-chall"
                           element={<ProtectedRoute Component={ProfilePreviewChallenges}/>}/>
                </Route>
            </Route>

            {/* PARTICIPANT CHALLENGES */}
            <Route path="/my-projects" element={<ProtectedRoute Component={ParticipantChallenges}/>}/>

            {/* PARTICIPANT PROJECTS */}

            <Route path="/participant/portfolio/:profileId"
                   element={<ParticipantProjects/>}/>

            {/* PARTICIPANT PROFILE */}
            {/* <Route path="/participant/profile" element={<ProtectedRoute Component={Profile}/>}>
                <Route path="/participant/profile/preview" element={<ProtectedRoute Component={ProfilePreview}/>}>
                    <Route path="/participant/profile/preview/infos"
                           element={<ProtectedRoute Component={ProfilePreviewInfos}/>}/>
                </Route>
                <Route path="/participant/profile/update" element={<ProtectedRoute Component={ProfileUpdate}/>}/>
            </Route> */}


            {/* GLOBAL */}
            <Route path="/challenge/:id" element={<Challenge/>}>
                <Route path="/challenge/:id/general" element={<ChallengeGeneral/>}/>
                <Route path="/challenge/:id/planning" element={<ChallengePlanning/>}/>
                <Route path="/challenge/:id/faq" element={<ChallengeFaq/>}/>
                <Route path="/challenge/:id/ressources" element={<ChallengeRessources/>}/>
                <Route path="/challenge/:id/my-project" element={<ChallengeMyProject/>}/>
                <Route path="/challenge/:id/resultats" element={<ChallengeResultats/>}/>
            </Route>

            {/* Erreurs (401, 404 ...) */}
            <Route path='/*' element={<Error404/>}/>
            <Route path='/error/401' element={<Error401/>}/>
            <Route path='/error/498' element={<Error498/>}/>


            {/* PARTICIPANT - Challenge */}
            <Route path="/participant/challenge/:id/teams"
                   element={<ProtectedRoute Component={ParticipantTeamsJoin}/>}/>
            <Route path="/participant/challenge/:id/team/:teamId"
                   element={<ProtectedRoute Component={ParticipantTeamsJoinInvite}/>}/>
            <Route path="/test" element={<Test totalSteps={5} activeStep={3}/>}/>

            {/* <Route path="/:profile/challenge/:id/project/:projectId" element={<Home/>}> */}
            <Route path="/:profile/challenge/:id/team/:teamId/management" element={<TeamManagement/>}/>
            <Route path="/:profile/challenge/:id/project/:projectId/edit" element={<ProjectRunning/>}/>

            <Route path="/:profile/challenge/project/edition/:projectId" element={<ProjectEdition/>}/>

            <Route path="/:profile/challenge/:id/project/:projectId" element={<Project/>}/>
            {/* </Route> */}
        </Routes>
    );
}
