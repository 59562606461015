//importer le style index.css
import './index.css';

// components
import OutletNavBar from '../../../../../modules/MenuTabs'
import ActionButton from "../../../../../components/ActionButton";
import NavTabs from "../../../../../modules/NavTabs";


// react-router-dom
import {Outlet, useNavigate, useParams, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../../app/type';

// icons
import {ReactComponent as IconPreview} from '../../../../../assets/icon-eye.svg';
import {ReactComponent as IconPen} from '../../../../../assets/icon-pen.svg';
import {ReactComponent as IconPrivate} from '../../../../../assets/icon-private.svg'
import {ReactComponent as IconTeam} from '../../../../../assets/icon-network.svg'

import React, {useEffect, useState} from 'react';
import GoBack from '../../../../../components/GoBack';
import MenuTabs from "../../../../../modules/MenuTabs";
import {Avatar, Chip, Grid, useMediaQuery, useTheme} from "@mui/material";
import {DEFAULT_CHALLENGE_PICTURE} from '../../../../../utils/consts';
import {useAdminEditChallengeContext} from '../AdminEditChallenge/index';
import IChallenge from '../AdminEditChallenge/types';
import api from "../../../../../utils/api";

const AdminInfosChallenge = () => {

    const navigate = useNavigate();
    const id = useParams().id;

    console.log("id", id);

    const outletNavbarLinks = [
        {
            label: 'Général',
            key: 'general',
            link: `/admin/challenge/${id}/general`
        },
        {
            label: 'Planning',
            key: 'planning',
            link: `/admin/challenge/${id}/planning`
        },
        {
            label: 'Suivi des inscriptions',
            key: 'select',
            link: `/admin/challenge/${id}/select/participants`
        },
        {
            label: 'Suivi des projets',
            key: 'projects',
            link: `/admin/challenge/${id}/projects`
        },
    ];

    const [state, setState] = useState('')

    const [pictureChallenge, setPictureChallenge] = useState<any>('')
    const [logoChallenge, setLogoChallenge] = useState<any>('')

    const [isLoaded, setIsLoaded] = useState(false);

    const [challenge, setChallenge] = useState<IChallenge>();

    const getOneChallenge = async (id: string) => {
        await api.get('/challenge/preview', {
            params: {
                challengeId: id
            },
            withCredentials: true,
        })
            .then((res) => {
                setChallenge(res.data.challenge)
                console.log(res.data.challenge)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getOneChallenge(String(id));
    }, [])
    useEffect(() => {
        challenge && setPictureChallenge(challenge.challengePicture)
        challenge && setLogoChallenge(challenge.challengeLogo)
    }, [challenge])
    useEffect(() => {
        console.log("id challenge", challenge);
    }, [challenge])

    useEffect(() => {
        if (challenge) {
            if (challenge.status.name === 'in creation') {
                setState('En création');
            }
            if (challenge.status.name === 'published') {
                setState('Publié');
            }
            if (challenge.status.name === 'registrations') {
                setState('Inscriptions');
            }
            if (challenge.status.name === 'submission') {
                setState('Soumissions');
            }
            if (challenge.status.name === 'deliberations') {
                setState('Délibérations');
            }
            if (challenge.status.name === 'finished') {
                setState('Terminé');
            }
        }

    }, [challenge])

    const location = useLocation();
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <>
            {challenge && (
                <div className="admin-infos-challenge">
                    {/*{*/}
                    {/*    !isDesktop && (*/}
                    {/*        <section className="admin-challenge-menu-tabs-container-sticky">*/}
                    {/*            <div className="admin-challenge-menu-tabs-content">*/}
                    {/*                <MenuTabs links={outletNavbarLinks} variant={"menu-tabs-primary"}/>*/}
                    {/*            </div>*/}
                    {/*        </section>*/}
                    {/*    )*/}
                    {/*}*/}

                    <section className='w-100 relative'>
                        <div className='w-100' style={{position: "relative"}}>
                            <img className='w-100' src={pictureChallenge} alt="challengepicture"/>
                            {
                                !isMobile && (
                                    <Chip
                                        sx={{
                                            backgroundColor: "var(--primary10)",
                                        }}
                                        className={"chip-countMembers"}
                                        label={challenge.countMembers !== 0 ? `${challenge.countMembers} ${challenge.countMembers === 1 ? 'inscrit' : 'inscrits'}` : "Pas encore d'inscrit"}
                                        variant={"filled"}
                                        icon={<IconTeam/>}
                                    />
                                )
                            }
                        </div>
                    </section>


                    <div className='header'>
                        <div className="challenge-infos-container">
                            <div className="challenge-infos">
                                <div className='edit-buttons'>
                                    <ActionButton
                                        variant="primary"
                                        icon={<IconPen/>}
                                        onClick={() => navigate('/admin/challenges/edit/general/' + id)}
                                    >
                                        Éditer le challenge
                                    </ActionButton>
                                    <ActionButton
                                        icon={<IconPreview/>}
                                        variant="tertiary"
                                        onClick={() => navigate(`/preview/challenge/${id}/general`)}
                                    >
                                        Prévisualiser le challenge
                                    </ActionButton>
                                </div>


                                <div className="title-button">
                                    {
                                        logoChallenge && (
                                            <div className="logo-challenge"
                                                 style={{background: `url(${logoChallenge}) center center/cover`}}></div>
                                        )
                                    }
                                    <div className='title-admin-challenge'>
                                        <p className="state">{state}</p>
                                        <h1 className="h1">{challenge.name} {challenge && challenge.public !== undefined && !challenge.public && (
                                            <IconPrivate/>)}</h1>
                                        <Chip
                                            avatar={<Avatar alt="organizer-icon"
                                                            src={challenge.establishments[0].establishmentPicture || ""}/>}
                                            label={challenge.establishments[0].name}
                                            variant="outlined"
                                            className="chip-organizers"
                                        />
                                        {
                                            isMobile && (
                                                <Chip
                                                    sx={{
                                                        backgroundColor: "var(--primary10)",
                                                        position: "relative",
                                                        bottom: "0",
                                                        right: "0",
                                                        top: "0",
                                                        marginTop: "1rem",
                                                    }}
                                                    className={"chip-countMembers"}
                                                    label={challenge.countMembers !== 0 ? `${challenge?.countMembers} ${challenge?.countMembers === 1 ? 'inscrit' : 'inscrits'}` : `Pas encore d'inscrit`}
                                                    variant={"filled"}
                                                    icon={<IconTeam/>}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="img-background"*/}

                        {/*    style={{*/}
                        {/*        background: `url(${pictureChallenge !== '' ? pictureChallenge : DEFAULT_CHALLENGE_PICTURE}) center center/cover`,*/}
                        {/*        position: "relative"*/}
                        {/*    }}>*/}
                        {/*    {*/}
                        {/*        !isMobile && (*/}
                        {/*            <Chip*/}
                        {/*                sx={{*/}
                        {/*                    backgroundColor: "var(--primary10)",*/}
                        {/*                }}*/}
                        {/*                className={"chip-countMembers"}*/}
                        {/*                label={challenge.countMembers !== 0 ? `${challenge.countMembers} ${challenge.countMembers === 1 ? 'inscrit' : 'inscrits'}` : `Pas encore d'inscrit`}*/}
                        {/*                variant={"filled"}*/}
                        {/*                icon={<IconTeam/>}*/}
                        {/*            />*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*</div>*/}
                    </div>

                    <section className="admin-challenge-menu-tabs-container">
                        <div className="admin-challenge-menu-tabs-content">
                            <MenuTabs links={outletNavbarLinks} variant={"menu-tabs-primary"}/>
                        </div>
                    </section>

                    <section className="admin-infos-challenge-container">
                        <div className="admin-infos-challenge-content">
                            <Outlet/>
                        </div>
                    </section>
                </div>
            )}
        </>
    )
}

export default AdminInfosChallenge;
