import React, { useEffect, useState } from 'react';
import ChallengePlanning from "../../../../../../modules/Planning";
import { useTranslation } from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";
import Switch from "@mui/material/Switch";
import { TextField, Snackbar, Alert } from '@mui/material';
import "./index.css";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import api from '../../../../../../utils/api';
import SwitchButton from '../../../../../../components/SwitchButton';
import ActionButton from '../../../../../../components/ActionButton';
import { ReactComponent as IconAdd } from '../../../../../../assets/icon-add.svg';

const AdminChallengePlanning = () => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [convertedImage, setConvertedImage] = useState<File | null>(null);
    const [challenge, setChallenge] = useState<any>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const challengeId: any = useParams().id;
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
    const [variantSnackBar, setVariantSnackBar] = useState<string>('');
    const [textSnackBar, setTextSnackBar] = useState<string>('');

    const handleSwitchChange = (event: any) => {
        setIsSwitchOn(event.target.checked);
        changeManualPlanning();
    };

    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setSuccessUpload(false);

        if (selectedFile && selectedFile.type === 'application/pdf') {
            // Handle PDF file if needed
        } else {
            const reader: any = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
            setConvertedImage(null);
        }
    };
    const [successUpload, setSuccessUpload] = useState<boolean>(false);
    const handleUpload = async () => {
        setSuccessUpload(false);
        if (file || convertedImage) {
            const formData = new FormData();
            formData.append('file', convertedImage || file as File);
            formData.append('challengeId', challengeId);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/challenge/manualPlanning`, formData, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setOpenSnackBar(true);
                setVariantSnackBar("success");
                setTextSnackBar("Planning enregistré avec succès");
                setImagePreview(URL.createObjectURL(file || convertedImage as File));
                setSuccessUpload(true);
            } catch (error: any) {
                console.error('Error uploading file', error);
                setOpenSnackBar(true);
                setVariantSnackBar("error");
                setTextSnackBar(`Erreur lors de l'ajout du planning : ${error?.response?.data?.message}`);
            }
        } else {
            setOpenSnackBar(true);
            setVariantSnackBar("error");
            setTextSnackBar("Veuillez ajouter un planning");
        }
    };

    const getOneChallenge = async () => {
        await api.get('/challenge/preview', {
            params: {
                challengeId: challengeId
            },
            withCredentials: true,
        })
            .then((res) => {
                setChallenge(res.data.challenge);
                setImageUrl(res.data.challenge.planning);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const changeManualPlanning = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/admin/challenge/isManualPlanning`, {
                challengeId: challengeId
            }, {
                withCredentials: true,
            }).then((res) => {
                console.log("ok");

            });

        } catch (error: any) {
            console.error('Error', error);
        }
    }
    useEffect(() => {
        getOneChallenge();
    }, []);

    useEffect(() => {
        if (challenge) {
            setIsSwitchOn(challenge.isManual);
        }
    }, [challenge]);

    const handleCancel = () => {
        setFile(null);
        setConvertedImage(null);
        setImagePreview(null);
        setSuccessUpload(false);
    };

    return (
        <section className="admin-challenge-planning">
            <div className="planning-header">
                <h2>Planning</h2>
            </div>
            <div className={"switch-planning-mode mb-5"}>
                <p>Automatique</p>
                <SwitchButton
                    checked={isSwitchOn}
                    onChange={handleSwitchChange}
                    inputProps={{ 'aria-label': 'selection' }}
                    size={'small'}
                    onClick={() => changeManualPlanning()}
                />
                <p>Manuel</p>
            </div>
            {isSwitchOn ? (
                <div className='manual-planning'>
                    <p className='text-center mb-5'>Les formats d'images (png, jpg, jpeg) sont acceptés (5Mo max).</p>
                    {imageUrl || imagePreview ? (
                        <div>
                            <img src={imagePreview ? imagePreview : imageUrl ? imageUrl : ""} alt="Planning" style={{ maxWidth: '100%' }} className='img-planning' />
                            <div className='block-modif-send'>
                                <div className={"file-button-modif"}>
                                    <ActionButton
                                        variant='secondary'
                                        onClick={() => handleFileChange}
                                        className='btn-modifier'
                                    >
                                        <TextField
                                            type="file"
                                            label="Ajouter un fichier"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            onChange={handleFileChange}
                                            sx={{
                                                '&.MuiFormControl-root': {
                                                    position: "absolute",
                                                    top: "0",
                                                    left: "0",
                                                    right: "0",
                                                    bottom: "0",
                                                    margin: '0',
                                                    opacity: '0',
                                                },
                                                '& .MuiInputBase-input': {
                                                    zIndex: 50,
                                                    width: '100%',
                                                    cursor: 'pointer',
                                                    opacity: 1,
                                                },
                                                '& .MuiFormLabel-root': {
                                                    position: 'relative',
                                                    opacity: 0,
                                                    display: 'none',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    opacity: 0,
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        />
                                        Modifier
                                    </ActionButton>
                                    <div className='container-buttons-planning'>
                                        {
                                            (file || convertedImage) && !successUpload ? (
                                                <>
                                                    <ActionButton variant="tertiary" onClick={handleCancel}>
                                                        Annuler
                                                    </ActionButton>
                                                    <ActionButton variant="primary" onClick={handleUpload}>
                                                        Valider
                                                    </ActionButton>
                                                </>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {
                        (!imagePreview && !imageUrl) ? (
                            <div className={"file-button"}>
                                <TextField
                                    type="file"
                                    label="Ajouter un fichier"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    onChange={handleFileChange}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            position: 'absolute',
                                            zIndex: 50,
                                            top: '0.4rem',
                                            height: '3rem',
                                            width: '100%',
                                            cursor: 'pointer',
                                            opacity: 0,
                                        },
                                        '& .MuiFormLabel-root': {
                                            position: 'relative',
                                            opacity: 0,
                                            display: 'none',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            opacity: 0,
                                            cursor: 'pointer',
                                        }
                                    }}
                                />
                                <ActionButton
                                    variant='secondary'
                                    onClick={() => handleFileChange}
                                    icon={<IconAdd className='icon-add' />}
                                >
                                    Ajouter un planning
                                </ActionButton>
                            </div>
                        ) : null
                    }
                </div>
            ) : (
                <>
                    <p className="text w-50 mb-5">
                        {t("page.challenge.planning.planningAdminInfo")}
                    </p>
                    <ChallengePlanning />
                </>
            )}
            <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={() => {
                    setOpenSnackBar(false)
                    setVariantSnackBar('success')
                    setTextSnackBar('')
                }}
            >
                <Alert onClose={() => {
                    setOpenSnackBar(false)
                    setVariantSnackBar('success')
                    setTextSnackBar('')
                }} severity={variantSnackBar === "success" ? "success" : "error"}>{textSnackBar}</Alert>
            </Snackbar>
        </section>
    );
};

export default AdminChallengePlanning;
