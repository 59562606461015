// Le header est le composant qui se trouve en haut de l'application.
// Il contient le nom de la page dans laquelle on se trouve et un icon de menu qui permet d'accéder à un menu déroulant.
// Dans ce menu déroulant, on retrouve les liens vers les différentes pages de l'application :
// - Changer la langue
// - Paramètres
// - Voir son profil
// - Changer de profil
// - Se déconnecter

// Il possède plusieurs propriétés :
// - currentPage : la page dans laquelle on se trouve (path)
// - blackList : si on est sur une des pages de la blacklist, on affiche le logo de Makee, sinon on affiche le header

// react-dom
import {useNavigate, useLocation, Link, useParams, redirect} from 'react-router-dom';
// react
import {ReactComponent as DiamondColorHorizontalSvg} from '../../assets/diamond-colors-horizontal.svg';
import {ReactComponent as IconLogout} from '../../assets/icon-logout.svg';
import {ReactComponent as IconClose} from '../../assets/icon-close.svg'
import {ReactComponent as MakeeScript} from '../../assets/makee-script.svg'
import {ReactComponent as IconDots} from '../../assets/dots-vertical.svg'
import {ReactComponent as IconEye} from '../../assets/icon-eye-masked.svg'
import {ReactComponent as Transparent} from '../../assets/transparent.svg'

import {useEffect, useState} from 'react';
import React from 'react';

// react-i18next
import 'react-i18next'
import {useTranslation} from 'react-i18next';

// styles
import './index.css';

// context
import {useAuth} from "../../context/AuthContext";
import ActionButton from '../../components/ActionButton';
import GoBack from '../../components/GoBack';

// title
import {titleHeader} from './titleHeader';

// redux
import {useDispatch, useSelector} from "react-redux";
import {RootState} from '../../app/type';
import {useMediaQuery, useTheme} from "@mui/material";
import {Skeleton} from "@mui/lab";
import SwitchTranslate from "../../components/SwitchTranslate";
import SetUpTranslation from "../../components/SetUpTranslation";

import {ReactComponent as IconMail} from '../../assets/icon-message.svg';

// props
interface HeaderProps {
    blackList: boolean;
}

const Header = () => {
    // récupération de logout du context
    const {logout} = useAuth();

    // récupération de la fonction de changement de langue

    // navigation
    const navigate = useNavigate();

    // récupération de la page dans laquelle on se trouve
    const location = useLocation();

    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    const firstname = useSelector((action: RootState) => action.user.firstname);
    const lastname = useSelector((action: RootState) => action.user.lastname);
    const nbProfiles = useSelector((action: RootState) => action.profile.nbProfiles);
    const profile_picture = useSelector((action: RootState) => action.user.profile_picture);

    const formattedFirstname = firstname.charAt(0).toUpperCase() + firstname.slice(1);
    const formattedLastname = lastname.charAt(0).toUpperCase() + lastname.slice(1);

    const [title, setTitle] = useState('' as string || undefined);
    const [redirectGoBack, setRedirectGoBack] = useState('' as string || undefined || null);
    const [makee, setMakee] = useState(false);
    const [goback, setGoBack] = useState(false);
    const [dotsMenu, setDotsMenu] = useState(false);
    const [eye, setEye] = useState(false);
    const [nav, setNav] = useState('/' as string);
    const [none, setNone] = useState(false);
    const [params, setParams] = useState(false);
    const [id, setId] = useState('' as string || undefined);


    useEffect(() => {
        setRedirectGoBack(null);
        titleHeader(t).map((item) => {
            if (location.pathname === item.path) {
                setTitle(item.title);
            } else {
                const pathRegex = new RegExp(`^${item.path.replace(/:\w+/g, '(\\w+)')}$`);
                const match = location.pathname.match(pathRegex);
                if (match) {
                    console.log("match", match);
                    if (match[1]) {
                        console.log("match", match[1]);
                        setId(match[1])
                    }
                    if (location.pathname === match[0]) {

                        setTitle(item.title);
                    }
                }
            }
        });

    }, [location.pathname, t])

    useEffect(() => {
        console.log("id", id);
    }, [id])

    // affichage du menu
    const [isMenu, setIsMenu] = useState(false);
    const [isOverProfile, setIsOverProfile] = useState(false);

    const blackListBorder = [
        "admin/challenges/edit/general/hackathon",
        "admin/challenges/edit/sign-up/hackathon",
        "admin/challenges/edit/steps/hackathon",
        "admin/challenges/edit/ressources/hackathon",
        "admin/challenges/edit/criteria/hackathon",
    ];

    const blackListGoBack = [
        "/",
        "/admin/challenges",
        "/my-projects",
        "/admin/school/config/general",
        "/participant/portfolio/:id",
    ]

    const blackListEdit = [
        "/admin/challenges/edit/",
    ]

    const isInPreviewChallenge = [
        "/preview/challenge/",
    ];

    const blackListAvatarMobile = [
        "/my-profile/update",
        "/my-profile/change"
    ]
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const {profile} = useAuth();

    function getProfileText() {
        switch (profile) {
            case 'admin':
                return t("header.admin");
                break;
            case 'participant':
                return 'participant';
                break;
            default:
                return 'inconnu';
        }
    }

    const handleGoBack = () => {
        const pathRegex = new RegExp(`^${location.pathname.replace(':id', '(\\d+)')}$`);
        const match = location.pathname.match(pathRegex);
        const newExcludedRoutes = titleHeader(t).map((route) => {
            const pathRegexExcluded = new RegExp(`^${route.path.replace(':id', '(\\d+)')}$`);
            const matchExcluded = location.pathname.match(pathRegexExcluded);
            return matchExcluded && {
                matchExcludedValue: matchExcluded[0],
                redirectValue: route.redirectGoBack
            };
        });
        if (match && newExcludedRoutes.some((route) => route && route.matchExcludedValue === match[0] && route.redirectValue)) {
            newExcludedRoutes.forEach((route) => {
                if (route && route.matchExcludedValue === match[0]) {
                    if (route.redirectValue) {
                        navigate(route.redirectValue);
                    }
                }
            });
        } else {
            navigate(-1);
        }
    }
    const theme = useTheme();
    const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
    const profileName = useSelector((state: RootState) => state.rootProfile.name);

    useEffect(() => {
        setIsEdit(blackListEdit.some(item => location.pathname.includes(item)));
    }, [location]);

    const blacklistPicture = blackListAvatarMobile.some(item => location.pathname.includes(item));

    return (
        <header
            className={`${blackListEdit.some(item => location.pathname.includes(item)) ? 'header-edit' : ''}${isMenu ? 'header-up' : ''} ${blackListBorder.some(item => location.pathname.includes(item)) ? 'delete-border' : ''}`}>
            <div
                className={`block-header ${blackListEdit.some(item => location.pathname.includes(item)) ? 'block-header-no-nav-bar' : ''} ${isMenu ? 'block-header-up' : ''}`}>
                <div className='content-header'>
                    {
                        isInPreviewChallenge.some(item => location.pathname.includes(item)) ? (
                            <div className="cancel-preview-button">
                                {
                                    isUpMd ? (
                                            <ActionButton
                                                variant={"primary"}
                                                icon={<IconEye/>}
                                                onClick={() => navigate(`/admin/challenge/${id}/general`)}
                                            >
                                                <span>Quitter la prévisualition</span>
                                            </ActionButton>
                                        )
                                        : (
                                            <>
                                                <IconEye className="cancel-preview-button-mobile"
                                                         onClick={() => navigate(`/admin/challenge/${id}/general`)}/>
                                                <h1>{title}</h1>
                                            </>
                                        )
                                }
                            </div>
                        ) : (
                            <h1 className={`${isEdit ? 'titleEdit' : 'headerTitle'}`}>
                                {
                                    !blackListGoBack.some(item => location.pathname === item) && (
                                        <span className={`go-back`}>
                                            <GoBack onClick={handleGoBack}/>
                                        </span>
                                    )
                                }
                                {title}
                            </h1>
                        )
                    }
                    <SwitchTranslate className={"translate-icon-header"}/>
                    <button
                        className={`profile-picture ${location.pathname.includes("/preview/challenge") && !isUpMd ? 'delete-profile-picture' : ''}  ${blackListGoBack.some(item => location.pathname === item) ? '' : 'delete-profile-picture-mobile'} ${blacklistPicture ? 'delete-profile-picture' : ''}`}
                        onClick={() => {
                            !location.pathname.includes("/preview/challenge") && setIsMenu(!isMenu);
                        }}
                    >
                        {
                            location.pathname.includes("/preview/challenge") ? (
                                <Skeleton variant="circular" sx={{height: "100%"}}></Skeleton>
                            ) : (
                                blackListEdit.some(item => !location.pathname.includes(item)) && (
                                    <img src={profile_picture} alt='profile-identity-img1'/>
                                )
                            )
                        }
                    </button>
                </div>
            </div>
            <div className={`menu-profile ${isMenu ? 'menu-profile-visible' : ''}`}>
                <div className='menu-profile-header'>
                    <IconClose className='menu-profile-icon-close' onClick={() => setIsMenu(!isMenu)}/>
                    <DiamondColorHorizontalSvg className='menu-profile-logo'/>
                    <div className='menu-profile-block-identity'>
                        <>
                            <img src={profile_picture} className='menu-profile-profile-picture'
                                 alt='profile-identity-img2'/>
                            <div className='menu-profile-name'>
                                <p>{formattedFirstname}</p>
                                <p>{formattedLastname}</p>
                            </div>
                        </>
                    </div>
                    <div className='menu-profile-my-profile'>
                        <Link to='/my-profile'
                              onClick={() => setIsMenu(!isMenu)}>
                            {
                                t("header.my") === "Mon" ?
                                    (<>{t("header.my")} {t("header.profile")} {getProfileText()}</>) :
                                    (<>{t("header.my")} {getProfileText()} {t("header.profile")}</>)}
                        </Link>
                    </div>
                    <div className='menu-profile-others-accounts'>
                        {nbProfiles > 1 &&
                            <ActionButton
                                variant="secondary"
                                onClick={() => {
                                    setIsMenu(!isMenu)
                                    navigate('/login/choice/profile')
                                }}
                            >
                                {t("header.switchProfile")}
                            </ActionButton>}
                    </div>
                </div>
                <div className="contact-support">
                    <p>
                        <strong>{t("contactSupport")}</strong><br/>
                        <IconMail/> <a href="mailto:support@makee.io">support@makee.io</a>
                    </p>
                </div>
                <div className='menu-profile-footer'>
                    <button onClick={() => {
                        logout();
                    }}>
                        <IconLogout/>
                    </button>
                    <button onClick={() => {
                        logout();
                        navigate("/login/1")
                    }}>
                        <p>{t("logout")}</p>
                    </button>
                </div>
            </div>
            <div className={`overlay ${isMenu ? 'overlay-visible' : ''}`} onClick={() => {
                setIsMenu(!isMenu);
            }}>
            </div>
        </header>
    )
}

export default Header;
