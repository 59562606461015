import React, { createRef, useEffect, useState } from "react";
import {
    IAnswer,
    IChallenge,
    IDeliverableStepProject,
    IErrorProjectData,
    IProject,
    IStepProject,
    ISteps
} from "./types";
import api from "../../../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import SetUpTranslation from "../../../../../components/SetUpTranslation";

import { ReactComponent as IconArrowRight } from "../../../../../assets/arrow-right.svg";
import { ReactComponent as IconWarn } from "../../../../../assets/icon-warn.svg";
import { ReactComponent as IconEdit } from "../../../../../assets/icon-edit.svg";
import { ReactComponent as IconChange } from "../../../../../assets/icon-change.svg";
import { ReactComponent as IconAdd } from "../../../../../assets/icon-add.svg";
import { ReactComponent as IconClose } from "../../../../../assets/icon-close.svg";
import { ReactComponent as IconCheckFlash } from "../../../../../assets/icon-check-flash.svg";

import './index.css'
import {
    Alert,
    Avatar,
    AvatarGroup, Button,
    Chip, FormControlLabel,
    FormControlLabelProps,
    Grid,
    Radio,
    RadioGroup, Snackbar, TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import ActionButton from "../../../../../components/ActionButton";
import { useDispatch } from "react-redux";
import axios from "axios";
import Modal from "../../../../../modules/Modal";
import StepperGradient from "../../../../../components/StepperGradient";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImageUploader from "../../../../../modules/ImageUploader/ImageUploader";
import { DEFAULT_MAIN_PROJECT } from "../../../../../utils/consts";
import { v4 as uuidv4 } from "uuid";
import CollaspseSteps from "../../../../../modules/CollapseSteps";

const ProjectEdition = () => {
    const projectId = useParams().projectId;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

    //translation
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);
    moment.locale(i18n.language);

    const [project, setProject] = useState<IProject>();
    const [challenge, setChallenge] = useState<IChallenge>();
    const [steps, setSteps] = useState<ISteps>();
    const [stepProject, setStepProject] = useState<IStepProject>();
    const [sentStepProjects, setSentStepProjects] = useState<IStepProject[]>([]);

    const [selectSubChallenge, setSelectSubChallenge] = useState<number>();

    const [openChangeSubChallenge, setOpenChangeSubChallenge] = useState(false);
    const [openConfirmModalSend, setOpenConfirmModalSend] = useState<boolean>(false);
    const [openAlertModal, setOpenAlertModal] = useState<boolean>(false);

    const [openTimeoutModal, setOpenTimeoutModal] = useState<boolean>(false);
    const [countdown, setCountdown] = useState(5 * 60); // 5 minutes en secondes

    const [editProject, setEditProject] = useState<boolean>(false);
    const [errorProjectData, setErrorProjectData] = useState<IErrorProjectData[]>([])

    const [img, setImg] = useState<string>("");
    const [imagePreview, setImagePreview] = useState<string>("");
    const [fileToUpload, setFileToUpload] = useState<File | undefined>();
    const [filesToDelete, setFilesToDelete] = useState<string[]>([]);

    const [updatedProject, setUpdatedProject] = useState<IProject | null>();
    const [newAnswers, setNewAnswers] = useState<IAnswer[]>([]);

    const [deliverableFiles, setDeliverableFiles] = useState<IDeliverableStepProject[]>([])
    const [newDeliverablesFiles, setNewDeliverablesFiles] = useState<IDeliverableStepProject[]>([]);

    const [error, setError] = useState<string | null>(null);

    // snackbar
    const [errorFront, setErrorFront] = useState<boolean>(false);
    const [messageErrorFront, setMessageErrorFront] = useState<string>("");
    const [severityErrorFront, setSeverityErrorFront] = useState<"error" | "success" | "info" | "warning">("error");

    const handleDeleteProfilePicture = () => {
        setFileToUpload(undefined);
        // setSaveFileToUpload(undefined);
    }

    const OpenModalChangeSubChallenge = () => {
        setOpenChangeSubChallenge(true);
    }

    const onChangeSubChallenge = async (e: any) => {
        setSelectSubChallenge(Number(e.target.value));
    }

    const MyFormControlLabel = (props: FormControlLabelProps) => {
        const { value } = props;
        let checked = false;
        if (value !== undefined && selectSubChallenge !== undefined && Number(value) === Number(selectSubChallenge)) {
            checked = true;
        }
        if (checked)
            return (
                <FormControlLabel
                    sx={{
                        background: 'var(--Saumon10, #FFF1F1)',
                        borderRadius: '10px',
                        border: '1px solid #F86767',
                        margin: "0",
                        marginBottom: "0.5em",
                        padding: "0.5em",
                        whiteSpace: 'normal'
                    }}
                    checked={checked}
                    {...props}
                />
            );
        return <FormControlLabel
            sx={{
                borderRadius: '10px',
                border: '1px solid #828D9B',
                margin: "0",
                marginBottom: "0.5em",
                padding: "0.5em",
                whiteSpace: 'normal'
            }}

            checked={checked} {...props}
        />;
    }

    const updateSelectSubChallenge = async () => {
        setOpenChangeSubChallenge(false);
        await axios.put(process.env.REACT_APP_API_URL + `/participant/updatesubchallenge/`,
            {
                challengeId: challenge?.id,
                projectId: projectId,
                subChallengeId: selectSubChallenge
            },
            {
                withCredentials: true,
            })
            .then((res) => {
                fetchProject(false);
            })
            .catch((err) => {
                console.log("err", err);
            })
    }

    const fetchProject = async (firstLoad: boolean) => {
        await api.get('/participant/project/edition', {
            params: {
                projectId: projectId,
                firstLoad: firstLoad
            },
            withCredentials: true,
        })
            .then(async (res) => {
                setProject(res.data.project);
                if (res.data.currentStepProject) {
                    setStepProject(res.data.currentStepProject)
                }
                setChallenge(res.data.project.challenge)
                setSteps(res.data.steps);
                setEditProject(false);
                setSelectSubChallenge(res.data.project.subChallenge?.id ? res.data.project.subChallenge?.id : "")
                setSentStepProjects(res.data.sentStepProjects);

                if (res.data.project.file && res.data.project.file.fileUrl) {
                    setImagePreview(res.data.project.file.fileUrl);
                }

                if (res.data.currentStepProject && res.data.currentStepProject.deliverable_step_projects[0]) {

                    const deliverableStepProjects = res.data.currentStepProject.deliverable_step_projects;

                    for (let i = 0; i < deliverableStepProjects.length; i++) {
                        await axios.get(process.env.REACT_APP_API_URL + '/downloadfile', {
                            params: {
                                url: deliverableStepProjects[i].file.fileUrl,
                                filename: deliverableStepProjects[i].file.originalFileName
                            },
                            withCredentials: true,
                            responseType: "blob"
                        })
                            .then((res) => {
                                // Créez un lien pour le téléchargement du fichier
                                const blob = new Blob([res.data], { type: res.headers['content-type'] });

                                // Créez un objet File à partir du blob
                                const file = new File([blob], deliverableStepProjects[i].file.originalFileName, { type: res.headers['content-type'] });

                                setDeliverableFiles((prevData) => {
                                    const data = [...prevData];
                                    data[i] = {
                                        id: deliverableStepProjects[i].id,
                                        file: file
                                    };
                                    return data;
                                });
                            })
                            .catch((err) => {
                                setDeliverableFiles([]);
                            })
                    }
                }
                return res.data.project.challenge;
            })
    }

    const checkIsEditing = async () => {
        await api.get('/participant/project/isEditing', {
            params: {
                projectId: projectId
            },
            withCredentials: true,
        })
            .catch((err) => {
                if (err.response?.data?.code === "isEditingTrue") {
                    navigate(`/participant/challenge/${err.response.data.challengeId}/project/${projectId}`)
                    throw new Error("isEditingTrue");
                }
            }
            )
    }

    let timeout: NodeJS.Timeout;
    const handleBeforeUnload = (event: any) => {
        // Vérifier si l'utilisateur a des modifications non sauvegardées
        if (updatedProject || newAnswers.length !== 0 || newDeliverablesFiles.length !== 0) {
            // s'il y a des modifications non sauvegardées, on affiche la pop-up
            // il faut retourner une string pas vide pour qu'elle s'affiche, mais ça ne défini pas le texte qu'il y a à l'intérieur
            event.returnValue = "on affiche";
        } else {
            window.removeEventListener('beforeunload', handleBeforeUnload);

            // s'il n'y a pas de modifications non sauvegardées, on affiche pas la pop-up
            // le fait de retourner une string vite fait qu'elle ne s'affichera pas
            event.returnValue = "";
        }
    };

    // event permettant d'afficher une pop-up si l'utilisateur essaie de rafraichir la page, fermer l'onglet ou la fenêtre
    window.addEventListener('beforeunload', handleBeforeUnload);

    useEffect(() => {
        if (steps?.inProgress.length !== 0 && stepProject && !stepProject?.sent) {
            timeout = setTimeout(() => {
                setOpenTimeoutModal(true);
            }, 30 * 60 * 1000); // 30 minutes en millisecondes
        }
    }, [challenge]);

    useEffect(() => {
        checkIsEditing()
            .then(() => {
                fetchProject(true);
            })
            .catch((err) => {
                return err.message;
            })

        return () => {
            checkIsEditing()
                .then((data) => {
                    api.put('/participant/project/updateEditing', {
                        projectId: projectId,
                        isEditing: false
                    }, { withCredentials: true })
                })
                .catch((err) => {
                    return err.message;
                })
        };

    }, []);

    useEffect(() => {
        if (openTimeoutModal) {
            const interval = setInterval(async () => {
                if (countdown > 0) {
                    setCountdown(countdown - 1);
                } else {
                    setOpenTimeoutModal(false); // Fermer la modale
                    await api.put('/participant/project/updateEditing', {
                        projectId: projectId,
                        isEditing: false
                    }, { withCredentials: true })
                        .then(() => {
                            navigate(`/participant/challenge/${challenge?.id}/project/${projectId}`); // Redirection
                            clearInterval(interval); // Arrêter l'intervalle
                        })
                }
            }, 1000)

            return () => clearInterval(interval);
        } else {
            setCountdown(5 * 60);
        }
    }, [openTimeoutModal, countdown]);

    let statusName;
    switch (challenge?.status.name) {
        case "in creation":
            statusName = `${t("status.inCreation")}`;
            break;
        case "published":
            statusName = `${t("status.published")}`;
            break;
        case "registrations":
            statusName = `${t("status.registrations")}`;
            break;
        case "submission":
            statusName = `${t("status.submission")}`;
            break;
        case "deliberations":
            statusName = `${t("status.deliberations")}`;
            break;
        case "finished":
            statusName = `${t("status.finished")}`;
            break;
        default:
            break;
    }

    const onChangeProjectData = (data: any, id: any) => {
        //const {id, value} = e.target;
        // Supprimer l'erreur associée au champ "title_project"
        setErrorProjectData(errorProjectData.filter((error) => error.id !== id));

        // Mettre à jour la valeur du champ
        setProject((prevData: any) => ({
            ...prevData,
            [id]: data
        }));

        setUpdatedProject((prevData: any) => ({
            ...prevData,
            [id]: data
        }))
    }

    // useEffect(() => {
    //     console.log(updatedProject);
    // }, [updatedProject]);
    //
    // useEffect(() => {
    //     console.log(newAnswers);
    // }, [newAnswers]);

    const onChangeAnswerQuestionStep = (data: any, id: any) => {
        id = Number(id);
        // Supprimer l'erreur associée à la question
        // setErrorQuestions(errorQuestions.filter((error) => error.idQuestion !== id));

        // Créez une copie du tableau existant
        const updatedAnswers = [...newAnswers];

        // Recherchez si une réponse pour cette question existe déjà
        if (updatedAnswers) {
            const index = updatedAnswers.findIndex((answer) => answer.questionId === id);

            if (index !== -1) {
                // Si l'id correspond, créez une copie de l'objet avec la valeur mise à jour
                updatedAnswers[index] = { ...updatedAnswers[index], answerText: data };
            } else {
                // Si aucune réponse n'existe, ajoutez une nouvelle réponse
                updatedAnswers.push({ questionId: id, answerText: data });
            }

            // Mettez à jour l'état avec le tableau mis à jour
            setNewAnswers(updatedAnswers);
        }
    }

    const limitText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };

    const QuestionDefaultProject = () => {
        return (
            <>
                <h3>{t("page.projectRunning.questions.presentation")}</h3>
                <p className="mb-3">{t("page.projectRunning.questions.text1")}</p>
                <p className="mb-2">{t("page.projectRunning.questions.fieldsRequired")}</p>
                <h4>{t("page.projectRunning.questions.titleProject")}</h4>

                <div>
                    <TextField
                        size="small"
                        fullWidth
                        inputProps={{ maxLength: 45 }}
                        sx={{
                            borderRadius: '10px',
                            '& label.Mui-focused': {
                                color: '#828D9B',
                            },
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: '#828D9B',
                                    borderWidth: '1px',
                                }
                            }

                        }}
                        id="title_project"
                        placeholder={`${t("page.projectRunning.questions.yourAnswer")}`}
                        value={project?.name || ''}
                        onChange={(e) => onChangeProjectData(e.target.value, "name")}
                        helperText={t('page.projectRunning.questions.fieldRequired')}
                        error={error === "fillProject"}
                    />
                </div>
                <h4 className="mt-3">{t("page.projectRunning.questions.shortDescription")}</h4>

                <div>
                    <TextField
                        size="small"
                        fullWidth
                        inputProps={{ maxLength: 200 }}
                        sx={{
                            borderRadius: '10px',
                            '& label.Mui-focused': {
                                color: '#828D9B',
                            },
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: '#828D9B',
                                    borderWidth: '1px',
                                }
                            }

                        }}
                        id="short_desc"
                        placeholder={`${t("page.projectRunning.questions.yourAnswer")}`}
                        value={project?.shortDesc || ''}
                        onChange={(e) => onChangeProjectData(e.target.value, "shortDesc")}
                        helperText={t('page.projectRunning.questions.fieldRequired')}
                        error={error === "fillProject"}
                    />
                </div>
                {/*<p className={"error-message mt-2"}>*/}
                {/*    {*/}
                {/*        errorProjectData.some((error) => error.id === "short_desc") && errorMessageFillField*/}
                {/*    }*/}
                {/*</p>*/}
                <h4 className="mt-3">{t("page.projectRunning.questions.inDepthDescription")}</h4>
                <div
                    className={`ckeditor-container-long-desc ${(error && error === "fillProject") ? "error-ckeditor" : ""}`}>
                    <CKEditor
                        editor={ClassicEditor}
                        data={project?.longDesc || ''}
                        disabled={challenge?.status.name === "finished"}
                        onReady={editor => {
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event: any, editor) => {
                            let data = editor.getData();
                            if (data.length > 5000) {
                                data = data.substring(0, 5000);
                                editor.setData(data);
                            }
                            onChangeProjectData(data, "longDesc");
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                        config={{
                            placeholder: `${t("page.projectRunning.questions.yourAnswer")}`,
                            toolbar: {
                                items: [
                                    'undo', 'redo',
                                    '|', 'heading',
                                    '|', 'fontFamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                    '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                    '|', 'link', 'blockQuote', 'codeBlock',
                                    '|', 'alignment',
                                    'fontColor',
                                    '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                                ],
                                shouldNotGroupWhenFull: true
                            }
                        }}
                    />
                </div>

                {/*<p className={"error-message mt-2"}>*/}
                {/*    {*/}
                {/*        // errorProjectData.some((error) => error.id === "long_desc") && errorMessageFillField*/}
                {/*    }*/}
                {/*</p>*/}
                <h4 id="title_main_picture" className="mt-3">
                    {t("page.projectRunning.questions.mainImage")}
                </h4>
                <div className={error && error === "addImageProject" ? "error" : ""}>
                    <ImageUploader
                        image={img}
                        defaultImage={"image-project"}
                        imagePreview={imagePreview || DEFAULT_MAIN_PROJECT}
                        setImagePreview={setImagePreview}
                        setFileToUpload={setFileToUpload}
                        handleDelete={handleDeleteProfilePicture}
                        ratio="5/2"
                        // rectangle={true}
                        noImageText={`${t("page.projectRunning.questions.noImage")}`}
                        noImageButtonText={`${t("page.projectRunning.questions.addImage")}`}
                        noDeletion={true}
                    // hasClickedOnCancelButton={hasClickedOnCancelButton}
                    // setChange={setChange}
                    // truc={truc2}
                    // setTruc={setTruc2}
                    // imgRef={createRef()}
                    // id={"img-edit-challenge-general"}
                    />
                </div>
            </>
        )
    }

    const inputRefs: { [key: string]: React.RefObject<HTMLInputElement> } = {};

    const addDeliverableFile = () => {
        // Vérifiez si tous les éléments dans deliverablesInProgressFile ont des fichiers non nuls
        const allHaveFiles = deliverableFiles.every((deliverable) => deliverable.file !== null);
        // Si tous les éléments ont des fichiers, ajoutez un nouvel élément avec un fichier nul
        if (allHaveFiles) {
            const newDeliverableFile = {
                id: "new-" + uuidv4(),
                file: null
            }

            // mettre à jour la liste pour affichage
            setDeliverableFiles([...deliverableFiles, newDeliverableFile]);

            // mettre à jour le tableau à envoyer à l'API
            setNewDeliverablesFiles([...newDeliverablesFiles, newDeliverableFile]);
        }
    };

    const onChangeDeliverableFile = (file: File, idDeliverable: string) => {

        // 1. Créez une copie du tableau deliverablesInProgressFile.
        const updateDeliverableFiles = [...deliverableFiles];
        const newDeliverablesCopy = [...newDeliverablesFiles];

        // 2. Recherchez l'index de l'idDeliverable dans le tableau.
        const deliverableFile = updateDeliverableFiles.find((deliverable) => String(deliverable.id) === idDeliverable);
        const newDeliverableFile = newDeliverablesCopy.find((deliverable) => String(deliverable.id) === idDeliverable);

        if (file.size / (1024 * 1024) > 10) {
            setOpenAlertModal(true);
        } else {
            const newFile = {
                id: deliverableFile ? idDeliverable : "new-" + uuidv4(),
                file: file
            }

            if (!deliverableFile) {
                updateDeliverableFiles.push(newFile);
            } else {
                deliverableFile.file = file;
            }

            if (!newDeliverableFile) {
                newDeliverablesCopy.push(newFile);
            } else {
                newDeliverableFile.file = file;
            }

            // mise à jour de la liste à afficher
            setDeliverableFiles(updateDeliverableFiles);

            // mise à jour du tableau à envoyer à l'API
            setNewDeliverablesFiles([...newDeliverablesCopy]);
        }
    }

    const deletedDeliverable = (id: string) => {
        // on supprime le fichier du tableau qui gère l'affichage de la liste
        const deliverableFilesCopy = deliverableFiles.filter((deliverable) => String(deliverable.id) !== String(id));
        // on supprime le fichier du tableau avec les fichiers ajoutés ou mis à jour (s'il est dedans)
        const newDeliverablesCopy = newDeliverablesFiles.filter((deliverable) => String(deliverable.id) !== String(id));

        setDeliverableFiles(deliverableFilesCopy);
        setNewDeliverablesFiles(newDeliverablesCopy);

        const deliverableFile = deliverableFiles.find((deliverable) => String(deliverable.id) === String(id));

        if (deliverableFile && !String(deliverableFile.id).includes('new-')) {
            const filesToDeleteCopy = [...filesToDelete];

            filesToDeleteCopy.push(deliverableFile.id)

            setFilesToDelete(filesToDeleteCopy);
        }

    }

    const saveOrSend = async (send: boolean) => {
        const formData = new FormData();
        formData.append('projectId', String(projectId));
        formData.append('stepId', String(steps?.inProgress[0].id))
        formData.append('send', JSON.stringify(send))
        if (updatedProject) {
            formData.append('updatedProject', JSON.stringify(updatedProject));
        }
        formData.append('answers', JSON.stringify(newAnswers));
        // send project picture
        if (fileToUpload) {
            formData.append('fileToUpload', fileToUpload);
        }
        // send files to delete ids
        formData.append('filesToDelete', JSON.stringify(filesToDelete));

        // send deliverables files
        newDeliverablesFiles.forEach((deliverable, index) => {
            if (deliverable.file) {
                formData.append(`newDeliverablesFiles-${deliverable.id}`, deliverable.file)
            }
        })

        await api.post('/participant/project/saveOrSend',
            formData,
            {
                withCredentials: true
            })
            .then((res) => {
                fetchProject(false);
                setUpdatedProject(null);
                setFileToUpload(undefined);
                setNewAnswers([]);
                setNewDeliverablesFiles([]);
                setError(null);

                setErrorFront(true);
                setSeverityErrorFront("success");
                if (send) {
                    setMessageErrorFront(`${t("page.projectRunning.projectSent")}`);
                } else {
                    setMessageErrorFront(`${t("page.projectRunning.messageErrorFront2")}`);
                }
                setErrorFront(true);
                setSeverityErrorFront("success");
            })
            .catch((err: any) => {
                const errorCode = err.response.data.code;
                setError(errorCode);

                setErrorFront(true);
                setSeverityErrorFront("error");
                setMessageErrorFront(`${t("page.projectRunning.messageErrorOccurred")}`);
            })

    }

    const saveProject = () => {
        saveOrSend(false);
    }

    const ValidateProjectStep = async () => {
        setOpenConfirmModalSend(false)
        saveOrSend(true);
    }

    const infoProject = () => {
        return (
            <>
                <h3 className="presentation">{t("page.projectRunning.questions.presentation")}</h3>
                {/* Si il n'y a pas de titre ni de short_desc ni de long_desc ni d'image principale, le projet ne sera pas visible sur la page du challenge. */}
                {(project?.longDesc && project?.shortDesc && project?.name) ? (
                    <div className="bloc-info-project-edit mt-4">
                        <h3 className="mb-2">
                            <div className="text"
                                dangerouslySetInnerHTML={{
                                    __html: `${project.name !== "" && project?.name}`
                                }} />
                            {(!project.team.eliminated && !isEliminated && steps?.done.length !== 0) &&
                                <span onClick={() => setEditProject(!editProject)}
                                    className="float-end icon-edit"><IconEdit /></span>
                            }
                        </h3>
                        <div className="text"
                            dangerouslySetInnerHTML={{
                                __html: `${project.shortDesc !== "" && project?.shortDesc}`
                            }} />
                        {(imagePreview !== "") && <div>
                            <img className="w-100 image-preview my-2" src={imagePreview} alt="previewImageProject" />
                        </div>}
                        <div className="text"
                            dangerouslySetInnerHTML={{
                                __html: `${project.longDesc !== "" && project?.longDesc}`
                            }} />
                    </div>
                ) : (
                    <div className="presentation mt-1">
                        <p>{t("page.projectRunning.projectNotComplete")}</p>
                    </div>
                )}
            </>
        )
    }

    const infoEditProject = () => {
        return (
            <div className="bloc-info-project-edit-open">
                {QuestionDefaultProject()}
                <Grid container marginTop={4} spacing={2}>
                    <Grid item xs={6}>
                        <ActionButton
                            variant="tertiary"
                            onClick={() => setEditProject(!editProject)}
                        >{t("form.cancel")}</ActionButton>
                    </Grid>
                    <Grid item xs={6}>
                        <ActionButton
                            variant="primary"
                            onClick={() => {
                                setEditProject(!editProject);
                                saveOrSend(false);
                            }}
                        >{t("form.save")}</ActionButton>
                    </Grid>
                </Grid>
            </div>
        )
    }

    let isEliminated = false;
    if ((sentStepProjects.length === 0 || !sentStepProjects[sentStepProjects.length - 1].sent) && steps && ((steps.inProgress.length !== 0 && (new Date(steps.inProgress[0].dateEndDeposite) < new Date())) || steps.done.length !== 0)) {
        isEliminated = true;
    }

    const formatTime = (time: any) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const saveAndQuit = () => {
        saveOrSend(false)
            .then(() => {
                setOpenTimeoutModal(false); // Fermer la modale
                api.put('/participant/project/updateEditing', {
                    projectId: projectId,
                    isEditing: false
                }, { withCredentials: true })
                    .then(() => {
                        clearTimeout(timeout);
                        navigate(`/participant/challenge/${challenge?.id}/project/${projectId}`); // Redirection
                    })
            })
    }

    const quit = () => {
        setOpenTimeoutModal(false); // Fermer la modale
        api.put('/participant/project/updateEditing', {
            projectId: projectId,
            isEditing: false
        }, { withCredentials: true })
            .then(() => {
                clearTimeout(timeout);
                navigate(`/participant/challenge/${challenge?.id}/project/${projectId}`); // Redirection
            })
    }

    return (
        <>
            <section className="project-edition">
                {(project && challenge) &&
                    <>
                        <div className="edition-page-header">
                            <div className='bloc-challenge-details'>
                                <div>
                                    <h4>{challenge.establishments[0].name} {!isDownMd && `${moment(String(challenge.startDate)).format("DD/MM/YYYY")} ${t("page.projectRunning.to")} ${moment(String(challenge.endDate)).format("DD/MM/YYYY")}`}
                                    </h4>
                                    <h1 className="text-uppercase">{challenge.name}</h1>
                                    <p className="text">
                                        <span>{isDownMd && `${moment(String(challenge.startDate)).format("DD MMMM YYYY")} ${t("page.projectRunning.to")} ${moment(String(challenge.endDate)).format("DD MMMM YYYY")} - `}</span>{challenge.shortDescription}
                                    </p>
                                </div>
                                <div className={isDownMd ? "chip text-start" : "chip text-end"}>
                                    <Chip label={statusName} sx={{
                                        backgroundColor: "#fff",
                                        fontWeight: "700",
                                        fontFamily: "Ubuntu"
                                    }} />
                                </div>
                            </div>

                            <div className={isDownMd ? "text-end" : "text-start"}>
                                <ActionButton
                                    variant="little-underline"
                                    onClick={() => navigate(`/challenge/${challenge.id}/general`)}
                                    iconEnd={true}
                                    icon={<IconArrowRight />}
                                >
                                    {t("page.projectRunning.seeChallengePage")}
                                </ActionButton>
                            </div>
                        </div>
                        <div className="edition-page-content">
                            <div className="bloc-project">

                                {project.team.single ? (
                                    // ********** PARTICIPATION SEULE **********
                                    <>
                                        <h2 className="text-center">{t("page.projectRunning.myProject")}</h2>
                                        <ActionButton
                                            className="mt-3 w-50 m-auto"
                                            variant='primary'
                                            onClick={() => navigate(`/participant/challenge/${challenge.id}/project/${projectId}`)}
                                        >
                                            {t("page.projectRunning.exitEditMode")}
                                        </ActionButton>
                                    </>
                                ) : (
                                    // ********** PARTICIPATION EN ÉQUIPE **********
                                    <>
                                        <h2 className="text-center">{t("page.projectRunning.team")} {project.team.numero}</h2>
                                        <h4 className="text-center team-name mb-1">{project.team.name}</h4>
                                        <ActionButton
                                            className="mt-4 w-50 m-auto"
                                            variant='primary'
                                            onClick={() => navigate(`/participant/challenge/${challenge.id}/project/${projectId}`)}
                                        >
                                            {t("page.projectRunning.exitEditMode")}
                                        </ActionButton>
                                        <p className="small text-center">{t("page.projectRunning.editModeInfo1")}</p>
                                        <p className="small text-center mb-4">{t("page.projectRunning.editModeInfo2")}</p>
                                    </>
                                )}
                                {project.subChallenge && (
                                    <div className="subchallenge-details">
                                        <div className="selected-subchallenge">
                                            <h3>{t("page.projectRunning.selectedSubChallenge")}</h3>
                                            <div className="text-end">
                                                <IconEdit className="icon-pen cursor-pointer"
                                                    onClick={() => OpenModalChangeSubChallenge()} />
                                            </div>
                                        </div>
                                        <div className="choice-subchallenge">
                                            <h4 className="bold-600">{project.subChallenge.name}</h4>
                                            <p className={"text"}>{project.subChallenge.description}</p>
                                        </div>
                                    </div>
                                )}
                                {/********** CHALLENGE PROGRESSION **********/}
                                {steps && (
                                    <div className="mt-4 bloc-project-progression">
                                        <h3>{t("page.projectRunning.projectProgess")}</h3>
                                        {!project.team.eliminated && (
                                            <>
                                                {((!project.team.eliminated && !isEliminated) && (steps && steps.inProgress.length === 0 && steps.done.length !== 0 && steps.todo.length === 0) && challenge.status.name !== "deliberations") ? (
                                                    <p className="mt-2 no-border-white-bg">{t("page.projectRunning.waitingChallengeEnd")}</p>
                                                ) : (
                                                    <>
                                                        {((steps.inProgress.length !== 0 && steps.inProgress[0].hasSelection && !steps.inProgress[0].announcementSelections) ||
                                                            (steps.inProgress.length === 0 && steps.done.length !== 0 && steps.done.slice(-1)[0].hasSelection && !steps.done.slice(-1)[0].announcementSelections)) && (
                                                                <p className="mt-2 no-border-white-bg">{t("page.projectRunning.pendingSelection")}</p>
                                                            )}

                                                        {(steps.done.length !== 0 && steps.done.slice(-1)[0].hasSelection && steps.done.slice(-1)[0].announcementSelections) && (
                                                            <p className="mt-2 no-border-white-bg">
                                                                {t("page.projectRunning.selectionsDone")}
                                                                {steps.todo.length > 0
                                                                    ? "sélections terminées, en attente du lancement de l’étape suivante"
                                                                    : "sélections terminées, en attente de la fin du challenge"}
                                                            </p>
                                                        )}

                                                        {((steps.inProgress.length !== 0 && steps.inProgress[0].hasSelection && steps.inProgress[0].announcementSelections) ||
                                                            (steps.inProgress.length === 0 && steps.done.length !== 0 && steps.done.slice(-1)[0].hasSelection && steps.done.slice(-1)[0].announcementSelections)) && (
                                                                <p className="mt-2 no-border-white-bg">
                                                                    {steps.todo.length > 0
                                                                        ? t("page.projectRunning.waitingNextStep")
                                                                        : t("page.projectRunning.waitingChallengeEnd")}
                                                                </p>
                                                            )}

                                                        {((steps.inProgress.length !== 0 && !steps.inProgress[0].hasSelection) ||
                                                            (steps.inProgress.length === 0 && steps.done.length !== 0 && !steps.done.slice(-1)[0].hasSelection)) && (
                                                                <p className="mt-2 no-border-white-bg">
                                                                    {steps.todo.length > 0
                                                                        ? t("page.projectRunning.waitingNextStep")
                                                                        : t("page.projectRunning.waitingChallengeEnd")}
                                                                </p>
                                                            )}
                                                    </>
                                                )}

                                                {steps.done.length !== 0 && (
                                                    <p>{t("page.projectRunning.stepUpper")} {steps.inProgress.length !== 0 ? (steps.done.length + 1) : (steps.done.length + steps.inProgress.length)} {t("page.projectRunning.of")} {challenge.steps.length}</p>
                                                )}
                                                <div className="mt-3">
                                                    <StepperGradient
                                                        totalSteps={challenge.steps.length}
                                                        activeStep={stepProject?.sent ? steps.done.length + 1 : steps.done.length}
                                                        runningStep={steps.inProgress.length}
                                                        blockedAt={null}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {project.team.eliminated && (
                                            <p className="eliminated">{`${t("page.projectRunning.eliminatedAt")} ${steps.eliminatedAt}`}</p>
                                        )}
                                    </div>
                                )}
                                {(steps && (steps.inProgress.length !== 0)) && (
                                    <div className="edit-project mt-5">
                                        {editProject ? infoEditProject() : infoProject()}
                                        {/*{infoProject()}*/}
                                    </div>
                                )}
                            </div>

                            <div className="step-project">
                                <div
                                    className={`step-details ${project.team.eliminated || isEliminated ? " warning" : " default"}`}>
                                    {/********** PROJET ENVOYÉ NON RETENU **********/}
                                    {project.team.eliminated && (
                                        <div>
                                            <div className="text-center mb-2">
                                                <IconWarn />
                                            </div>
                                            <h2 className="text-center">{t("page.projectRunning.projectNotSelected")}</h2>
                                        </div>
                                    )}

                                    {/********** PROJET NON ENVOYÉ ---> NON RETENU **********/}
                                    {isEliminated && (
                                        <div>
                                            <div className="text-center mb-2">
                                                <IconWarn />
                                            </div>
                                            <h2 className="text-center smaller-h2">
                                                <span
                                                    className="fw-normal d-block mb-2">{t("page.projectRunning.deadlinePassed")}</span>
                                                {t("page.projectRunning.projectNotSubmitted")}</h2>
                                        </div>
                                    )}

                                    {/********** PROJET RETENU **********/}
                                    {/********** PREMIÈRE ÉTAPE PAS COMMENCÉE **********/}
                                    {((!project.team.eliminated && !isEliminated) && (steps && steps.inProgress.length === 0 && steps.todo.length !== 0 && steps.done.length === 0)) && (
                                        <div>
                                            <h2 className="text-center">{t("page.projectRunning.stepUpper")} {steps.done.length === 0 ? "1" : steps.done.length + 1}</h2>
                                            <h4 className="text-center w-100 mt-1">{steps.todo[0].name}</h4>
                                            <div className="text-center mt-3">
                                                <h4 className="bordered">{t("page.projectRunning.waitingLaunch")} {t("page.projectRunning.first")} {t("page.projectRunning.step")}...</h4>
                                            </div>
                                        </div>
                                    )}

                                    {/********** ÉTAPE EN COURS, PROJET PAS ENVOYÉ **********/}
                                    {((!project.team.eliminated && !isEliminated) && (steps && steps.inProgress.length !== 0) && !stepProject?.sent) && (
                                        <div>
                                            <h2 className="text-center">{t("page.projectRunning.stepUpper")} {steps.done.length + steps.inProgress.length}</h2>
                                            <h4 className="text-center w-100 mt-1">{steps.inProgress[0].name}</h4>
                                            <div className="text-center mt-1">
                                                <h4 className="bordered">{t("page.projectRunning.dueBy")} {moment(String(steps.inProgress[0].dateEndDeposite)).format("DD/MM/YYYY, HH[h]mm")}</h4>
                                            </div>
                                        </div>
                                    )}

                                    {/********** ÉTAPE EN COURS, PROJET ENVOYÉ, AVANT/PENDANT SÉLECTIONS **********/}
                                    {((!project.team.eliminated && !isEliminated) &&
                                        ((steps && steps.inProgress.length === 0 && challenge.status.name === "deliberations") ||
                                            (steps && steps.inProgress.length !== 0)) &&
                                        ((stepProject && stepProject.sent) || (sentStepProjects.length !== 0 && steps.inProgress.length === 0))) && (
                                            <div>
                                                <div className="text-center mb-2">
                                                    <IconCheckFlash />
                                                </div>
                                                <h2 className="text-center">{t("page.projectRunning.congrats")}</h2>
                                                <h2 className="text-center smaller-h2">{t("page.projectRunning.projectSent")}</h2>
                                                <div className="text-center mt-2">
                                                    {((steps.inProgress.length !== 0 && steps.inProgress[0].hasSelection && !steps.inProgress[0].announcementSelections) ||
                                                        (steps.inProgress.length === 0 && steps.done.length !== 0 && steps.done.slice(-1)[0].hasSelection && !steps.done.slice(-1)[0].announcementSelections)) && (
                                                            <h4 className="mt-2 no-border-white-bg">{t("page.projectRunning.pendingSelection")}</h4>
                                                        )}
                                                    {((steps.inProgress.length !== 0 && steps.inProgress[0].hasSelection && steps.inProgress[0].announcementSelections) ||
                                                        (steps.inProgress.length === 0 && steps.done.length !== 0 && steps.done.slice(-1)[0].hasSelection && steps.done.slice(-1)[0].announcementSelections)) && (
                                                            <h4 className="mt-2 no-border-white-bg">
                                                                {t("page.projectRunning.selectionsDone")}
                                                                {steps.todo.length > 0
                                                                    ? t("page.projectRunning.waitingNextStep")
                                                                    : t("page.projectRunning.waitingChallengeEnd")}
                                                            </h4>
                                                        )}
                                                    {((steps.inProgress.length !== 0 && !steps.inProgress[0].hasSelection) ||
                                                        (steps.inProgress.length === 0 && steps.done.length !== 0 && !steps.done.slice(-1)[0].hasSelection)) && (
                                                            <h4 className="mt-2 no-border-white-bg">
                                                                {steps.todo.length > 0
                                                                    ? t("page.projectRunning.waitingNextStep")
                                                                    : t("page.projectRunning.waitingChallengeEnd")}
                                                            </h4>
                                                        )}
                                                </div>
                                            </div>
                                        )}


                                    {/********** ÉTAPE TERMINÉE, EN ATTENTE PROCHAINE ÉTAPE **********/}
                                    {((!project.team.eliminated && !isEliminated) && (steps && steps.inProgress.length === 0 && steps.done.length !== 0 && steps.todo.length !== 0) && challenge.status.name !== "deliberations") && (
                                        <div>
                                            <div className="text-center mb-2">
                                                <IconCheckFlash />
                                            </div>
                                            <h2 className="text-center">{t("page.projectRunning.congrats")}</h2>
                                            <h2 className="text-center smaller-h2">{t("page.projectRunning.projectSent")}</h2>
                                            <div className="text-center mt-2">
                                                <h4 className="mt-2 no-border-white-bg">{t("page.projectRunning.waitingLaunch")} {t("page.projectRunning.next")} {t("page.projectRunning.step")}...</h4>
                                            </div>
                                        </div>
                                    )}

                                    {/********** ÉTAPE TERMINÉE, EN ATTENTE FIN DU CHALLENGE **********/}
                                    {((!project.team.eliminated && !isEliminated) && (steps && steps.inProgress.length === 0 && steps.done.length !== 0 && steps.todo.length === 0) && challenge.status.name !== "deliberations") && (
                                        <div>
                                            <div className="text-center mb-2">
                                                <IconCheckFlash />
                                            </div>
                                            <h2 className="text-center">{t("page.projectRunning.congrats")}</h2>
                                            <h2 className="text-center smaller-h2">{t("page.projectRunning.projectSent")}</h2>
                                            <div className="text-center mt-2">
                                                <h4 className="mt-2 no-border-white-bg">{t("page.projectRunning.waitingChallengeEnd")}</h4>
                                            </div>
                                        </div>
                                    )}

                                </div>

                                {((!project.team.eliminated && !isEliminated) && (steps && steps.inProgress.length !== 0 && challenge.status.name !== "deliberations") && !stepProject?.sent) && (
                                    <div className="bloc-questions-step py-2 px-4">
                                        <section>
                                            {challenge.resources && challenge.resources.length > 0 &&
                                                <p className="info-resource mb-3">{t("page.projectRunning.resourcesText1")}
                                                    <span
                                                        onClick={() => navigate('/challenge/' + challenge.id + '/ressources')}>{t("page.projectRunning.resourcesText2")}</span> {t("page.projectRunning.resourcesText3")}
                                                </p>}
                                            {steps.done.length === 0 && steps.inProgress.length === 1 && QuestionDefaultProject()}
                                        </section>
                                        {steps.inProgress[0].questions.length !== 0 && (
                                            <section className="mt-4">
                                                <h3>{t("page.projectRunning.stepQuestions")}</h3>
                                                <p>{t("page.projectRunning.questionText")}</p>
                                                {steps.inProgress[0].questions.map((question, index) => {
                                                    let answer = '';
                                                    if (stepProject) {
                                                        const spQuestion = stepProject.step.questions.find((spQuestion) => question.id === spQuestion.id);
                                                        if (spQuestion && spQuestion.answers.length !== 0) {
                                                            answer = spQuestion.answers[0].answerText;
                                                        }
                                                    }
                                                    return (
                                                        <div key={index}>
                                                            <h4 className="mt-3">{question.questionText} *</h4>
                                                            <div
                                                                className={`ckeditor-container-long-desc ${(error && error === "emptyAnswer") ? "error-ckeditor" : ""}`}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={answer}
                                                                    disabled={challenge?.status.name === "finished"}
                                                                    onReady={editor => {
                                                                        console.log('Editor is ready to use!', editor);
                                                                        //console.log(Array.from( editor.ui.componentFactory.names()));
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        onChangeAnswerQuestionStep(data, question.id);
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        console.log('Blur.', editor);
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        console.log('Focus.', editor);
                                                                    }}
                                                                    config={{
                                                                        placeholder: `${t("page.projectRunning.questions.yourAnswer")}`,
                                                                        toolbar: {
                                                                            items: [
                                                                                'undo', 'redo',
                                                                                '|', 'heading',
                                                                                '|', 'fontFamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                                                                '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                                                                '|', 'link', 'blockQuote', 'codeBlock',
                                                                                '|', 'alignment',
                                                                                'fontColor',
                                                                                '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                                                                            ],
                                                                            shouldNotGroupWhenFull: true
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                            <p className={"error-message mt-2"}>
                                                                {
                                                                    // errorQuestions.some((error) => error.idQuestion === question.id) && errorMessageFillField
                                                                }
                                                            </p>
                                                        </div>
                                                    )
                                                }
                                                )}
                                            </section>
                                        )}
                                        {steps.inProgress[0].deliverables.length !== 0 && (
                                            <section className="mt-3">
                                                <h3>{t("page.projectRunning.requiredDeliverables")}</h3>
                                                {steps.inProgress[0].deliverables.map((deliverable, index) => (
                                                    <div className="bloc-info-deliverable" key={index}>
                                                        <h4>{deliverable?.name}</h4>
                                                        <p>{deliverable?.description}</p>
                                                    </div>
                                                ))}
                                            </section>
                                        )}
                                        <section className="mt-3">
                                            <h3>{t("page.projectRunning.yourDeliverables")} *
                                            </h3>
                                            {deliverableFiles && deliverableFiles.map((deliverable, index) => (
                                                <div key={index} className="mb-2">
                                                    <Button
                                                        key={index}
                                                        variant="contained"
                                                        className='upload-file'
                                                        component="label"
                                                        sx={{
                                                            padding: '0',
                                                            maxWidth: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                    >
                                                        <p style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            margin: 0,
                                                        }}
                                                            className='button-text w-80 '>
                                                            {deliverable?.file ? limitText(deliverable?.file?.name, 15) : limitText(`${t("page.projectRunning.importDocument")}`, 20)}
                                                        </p>
                                                        <input
                                                            ref={inputRefs[deliverable.id] = createRef()}
                                                            id={`${deliverable?.id}`}
                                                            key={index}
                                                            name={`${deliverable?.id}`}
                                                            type="file"
                                                            hidden
                                                            onChange={(event) => {
                                                                const files = (event.target as HTMLInputElement).files
                                                                if (files && files.length > 0) {
                                                                    // setErrorDeliverables(false);
                                                                    onChangeDeliverableFile(files[0], String(event.target.name));
                                                                }
                                                            }
                                                            }
                                                        />
                                                        <div>
                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <Button onClick={() => {
                                                                        inputRefs[deliverable.id].current?.click()
                                                                    }}>
                                                                        <IconChange />
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Button
                                                                        onClick={() => deletedDeliverable(deliverable.id)}>
                                                                        <IconClose />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Button>
                                                </div>
                                            ))}
                                        </section>
                                        <section id="info-attachment" className="w-75 m-auto mt-4 mb-2 text-center">
                                            <ActionButton
                                                variant="secondary"
                                                onClick={() => addDeliverableFile()}
                                                icon={<IconAdd />}
                                            >
                                                {t("page.projectRunning.addAttachment")}
                                            </ActionButton>
                                            <p className="mt-2">{t("page.projectRunning.imageFiles")} -PDF- Excel <span
                                                className="limit">&nbsp;(10Mo maximum)</span></p>
                                        </section>

                                        {error &&
                                            <p className={"error-message mt-3 text-center"}>
                                                {
                                                    (error === "fillProject")
                                                        ? t("page.projectRunning.errorFillProject")
                                                        : (error === "addImageProject")
                                                            ? t("page.projectRunning.errorAddImageProject")
                                                            : (error === "emptyAnswer")
                                                                ? t("page.projectRunning.errorEmptyAnswer")
                                                                : ""
                                                }
                                            </p>
                                        }
                                        <div className="save-send-buttons">
                                            <ActionButton
                                                variant="tertiary"
                                                onClick={saveProject}
                                            >
                                                {t("page.projectRunning.save")}
                                            </ActionButton>
                                            <ActionButton
                                                variant="primary"
                                                onClick={() => setOpenConfirmModalSend(true)}
                                            >
                                                {t("page.projectRunning.validateStep")}
                                            </ActionButton>
                                        </div>
                                    </div>
                                )}
                                {sentStepProjects && sentStepProjects.length > 0 && (
                                    <section className="section-collapse-steps-project mt-3">
                                        <h2 className="text-center mb-3">{t("page.projectRunning.stepUpper")}{sentStepProjects.length > 1 && "s"} {t("page.projectRunning.sent")}{sentStepProjects.length > 1 && "s"}</h2>
                                        <CollaspseSteps stepsProject={sentStepProjects} />
                                    </section>
                                )}
                            </div>
                        </div>
                    </>
                }
            </section>


            <Modal
                icon={<IconChange className='icon-change' />}
                title={t("page.projectRunning.selectedSubChallenge")}
                open={openChangeSubChallenge}
                onClose={() => setOpenChangeSubChallenge(false)}
                primaryButtonText={t("form.validate")}
                primaryButtonAction={() => updateSelectSubChallenge()}
                secondaryButtonText={t("form.cancel")}
                secondaryButtonAction={() => {
                    setSelectSubChallenge(project?.subChallenge?.id);
                    setOpenChangeSubChallenge(false);
                }}
            >
                <RadioGroup
                    name="use-radio-group"
                    // defaultValue={project?.subChallenge && Number(project.subChallenge.id)}
                    value={selectSubChallenge ? selectSubChallenge : project?.subChallenge?.id}
                    sx={{ wordBreak: 'break-word' }}
                    onChange={(e) => onChangeSubChallenge(e)}
                >
                    {challenge?.subChallenges && challenge.subChallenges.map((subChallenge, index) => (
                        <MyFormControlLabel
                            key={index}
                            value={subChallenge.id}
                            label={
                                <div className="text-start">
                                    <h5 className="h5modal">{subChallenge.name}</h5>
                                    <p className="desc-sub-challenge">{subChallenge.description}</p>
                                </div>
                            }
                            control={
                                <Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                            color: subChallenge && selectSubChallenge !== undefined && selectSubChallenge === Number(subChallenge.id) ? '#F86767' : '#828D9B'
                                        },
                                    }}
                                />
                            }
                        />
                    ))}
                </RadioGroup>
            </Modal>
            <Modal
                open={openConfirmModalSend}
                onClose={() => setOpenConfirmModalSend(false)}
                title={t("page.projectRunning.modalConfirmModalSend.title")}
                icon={<IconWarn className="iconWarn" />}
                primaryButtonText={t("page.projectRunning.modalConfirmModalSend.primaryButton")}
                primaryButtonAction={() => ValidateProjectStep()}
                tertiaryButtonText={t("page.projectRunning.modalConfirmModalSend.tertiaryButton")}
                tertiaryButtonAction={() => setOpenConfirmModalSend(false)}
            >
                <p><span
                    className="fw-bold">{t("page.projectRunning.modalConfirmModalSend.attention")}</span>, {t("page.projectRunning.modalConfirmModalSend.text1")}
                </p>
            </Modal>
            <Modal
                open={openTimeoutModal}
                onClose={() => setOpenTimeoutModal(false)}
                title={t("page.projectRunning.modalStillHere.title")}
                icon={<IconWarn className="iconWarn" />}
                primaryButtonText={t("page.projectRunning.modalStillHere.saveAndContinue")}
                primaryButtonAction={() => {
                    saveOrSend(false)
                    setOpenTimeoutModal(false)
                }}
                secondaryButtonText={t("page.projectRunning.modalStillHere.saveAndQuit")}
                secondaryButtonAction={saveAndQuit}
                tertiaryButtonText={t("page.projectRunning.modalStillHere.exit")}
                tertiaryButtonAction={quit}
                hideIconClose={true}
            >
                <p>
                    {t("page.projectRunning.modalStillHere.projectNotSaved")}
                    <br />
                    <br />
                    {t("page.projectRunning.modalStillHere.withoutAction")}
                    <span className='fw-bold'> {t("page.projectRunning.modalStillHere.changesWillBeLost")}</span>.
                    <br />
                    {t("page.projectRunning.modalStillHere.timeLeft")} {formatTime(countdown)}
                </p>
            </Modal>
            <Modal
                open={openAlertModal}
                onClose={() => setOpenAlertModal(false)}
                title={t("page.projectRunning.modalAlertFileSize.title")}
                icon={<IconWarn className="iconWarn" />}
                primaryButtonText={t("page.projectRunning.modalAlertFileSize.primaryButton")}
                primaryButtonAction={() => setOpenAlertModal(false)}
            >
                <p>
                    {t("page.projectRunning.modalAlertFileSize.text")}
                </p>
            </Modal>
            <Snackbar
                open={errorFront}
                autoHideDuration={3000}
                onClose={() => setErrorFront(false)}
            >
                <Alert onClose={() => {
                    setErrorFront(false)
                }} severity={severityErrorFront}>{messageErrorFront}</Alert>
            </Snackbar>
        </>
    )
}

export default ProjectEdition;
