// dnd-kit
import {
    DndContext,
    useSensor
} from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    arrayMove
} from '@dnd-kit/sortable';
import { v4 as uuidv4 } from 'uuid';
// sensor
import GlobalPointerSensor from '../../utils/GlobalPointerSensor';

// types
import IPrice from './types';
import {IChallenge} from '../../pages/MINIMAL/Admin/AdminChallenges/types'

// react
import React, { useEffect } from 'react';

// components
import ActionButton from '../ActionButton';

// icons
import { ReactComponent as IconAdd } from '../../assets/icon-add.svg';

// style
import './index.css'

import { useAdminEditChallengeContext } from '../../pages/MINIMAL/Admin/AdminChallenges/AdminEditChallenge/index';
import Prize from '../Prize';

interface PrizeListProps {
    setChallenge: (challenge: IChallenge) => void
    challenge: IChallenge
}

const PrizeList = ({challenge, setChallenge}: PrizeListProps) => {
    const sensors = [useSensor(GlobalPointerSensor)];
    const { imagePrices, setImagePrices} = useAdminEditChallengeContext();
    
    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = challenge.prizes.findIndex((prize) => prize.id === active.id);
            const newIndex = challenge.prizes.findIndex((prize) => prize.id === over.id);

            const newPrices = challenge.prizes.map((prize) => {
                if (prize.id === active.id) {
                    return {
                        ...prize,
                        position: newIndex + 1
                    };
                } else if (prize.id === over.id) {
                    return {
                        ...prize,
                        position: oldIndex + 1
                    };
                }
                return prize;
            });

            // Mettre à jour le challenge en changeant la position
            setChallenge({
                ...challenge,
                prizes: arrayMove(newPrices, oldIndex, newIndex)
            });
        }
    };

    const handleDeletePrize = (prize: IPrice) => {
        // Supprimer dare et remettre les positions à jour
        const newPrices = challenge.prizes
            .filter((p) => String(p.id) !== String(prize.id))
            .map((p, index) => ({
                ...p,
                position: index + 1
            }));
        // Mettre à jour challenge
        setChallenge({
            ...challenge,
            prizes: newPrices
        });
    };

    const handleUpdatePrize = (prize: IPrice) => {
        // Mettre à jour dare
        const newPrices:any = challenge.prizes.map((p) =>
            String(p.id) === String(prize.id) ? prize : p
        );
        // Mettre à jour challenge
        setChallenge({
            ...challenge,
            prizes: newPrices
        });
    };

    const handleAddPrize = () => {
        console.log('add prize');
        console.log("prize", challenge.prizes);
        // Ajouter Prize
        const newPrice: any = {
            id: "new-"+uuidv4(),
            name: "",
            description: "",
            position: challenge.prizes.length + 1,
            prizePicture: "/images/photo-profil.jpg"
        };

        // Ajouter newDare à challenge
        setChallenge({
            ...challenge,
            prizes: [...challenge.prizes, newPrice]
        });
    };

    useEffect(() => {
        console.log("prizes", challenge.prizes);
    }, [challenge.prizes]);

    return (
        <div className="defis">
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={challenge.prizes.sort((a, b) => a.position - b.position).map((price) => price.id)}
                    strategy={verticalListSortingStrategy}
                >
                    {challenge.prizes
                        .map((prize) => (
                        <Prize
                            disabled={challenge.status.name === 'finished'}
                            key={String(prize.id)}
                            prize={prize}
                            handleDeletePrize={handleDeletePrize}
                            handleUpdatePrize={handleUpdatePrize}
                        />
                    ))}
                </SortableContext>
            </DndContext>
            <ActionButton
                variant={challenge.status.name === 'finished'?'disabled':'secondary'}
                onClick={challenge.status.name === 'finished'?()=>{}:handleAddPrize}
                className='button-add-dare'
                icon={<IconAdd className='icon-add'/>}
            >
                Ajouter un prix
            </ActionButton>
        </div>
        // <div className="prizes">
        //     <DndContext
        //         sensors={sensors}
        //         onDragEnd={handleDragEnd}
        //     >
        //         <SortableContext
        //             items={prizes.map((prize: any) => prize.id)}
        //             strategy={verticalListSortingStrategy}
        //         >
        //             {prizes.map((prize: IPrize) => (
        //                 <Prize
        //                     key={prize.id}
        //                     prize={prize}
        //                     mobile={mobile}
        //                     handleDeletePrize={handleDeletePrize}
        //                     handleUpdatePrize={handleUpdatePrize}
        //                 />
        //             ))}
        //         </SortableContext>
        //     </DndContext>
        //     <ActionButton
        //         variant='secondary'
        //         onClick={handleAddPrize}
        //         className='button-add-prize'
        //         icon={<IconAdd className="icon-add" />}
        //     >
        //         Ajouter un prix
        //     </ActionButton>
        // </div>
    )
}

export default PrizeList;