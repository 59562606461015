import "./index.css";
import {IProjects, IAllProjects} from "./types";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Card, CardActions, CardContent, CardMedia, Chip, Grid, Typography} from "@mui/material";
import ActionButton from "../../../../../components/ActionButton";
import {ReactComponent as ArrowRight} from "../../../../../assets/arrow-right.svg";
import {DEFAULT_CHALLENGE_PICTURE} from "../../../../../utils/consts";
import api from "../../../../../utils/api";

const ChallengeResultats = () => {
    const {id} = useParams();
    const [allProjects, setAllProjects] = useState<IAllProjects>();
    const [projectsSelected, setprojectsSelected] = useState();
    const navigate = useNavigate();
    const getAllProjects = async () => {
        await api.get(`/challenge/finished/listProjects`,
            {
                params: {
                    challengeId: id
                },
                withCredentials: true
            }
        ).then((res) => {
            console.log(res.data);
            setAllProjects(res.data);
        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        getAllProjects();
    }, []);
    return (
        <section className={"container-challenge-resultats"}>
            <h2>Les projets déposés pour ce challenge</h2>
            <div className={"container-projects-selected"}>
                <h3>Les projets gagnants</h3>
                <div className={"container-projects-selected-content"}>
                    <Grid container justifyContent={"center"} spacing={1}>
                        {
                            allProjects && allProjects?.allProjects.projectsSelected.length > 0 && allProjects?.allProjects.projectsSelected.map((project: IProjects, index: number) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={4}
                                          key={index}>
                                        <Card
                                            className={"admin-infos-challenge-project-list"}>
                                            <CardMedia
                                                component="img"
                                                alt="image card projet sélectionné"
                                                height="50%"
                                                image={`${project.file ? project.file.fileUrl : DEFAULT_CHALLENGE_PICTURE}`}
                                            />
                                            <CardContent>
                                                {
                                                    project.subChallenge && (
                                                        <Chip
                                                            label={`Défi ${project.subChallenge.position} - ${project.subChallenge.name}`}
                                                            sx={{
                                                                backgroundColor: "white",
                                                                padding: "5px",
                                                                fontFamily: "var(--font-secondary)",
                                                                lineHeight: "24px",
                                                                color: "var(--primary90)",
                                                                fontWeight: "500",
                                                                fontSize: "11px",
                                                                position: "absolute",
                                                                top: "1rem",
                                                                right: "1rem"
                                                            }}
                                                        />
                                                    )
                                                }
                                                <Typography variant="h3"
                                                            className={"typo-card-h3"} style={{textAlign: "left"}}>
                                                    {project.team.name}
                                                </Typography>
                                                <Typography variant="h3"
                                                            className={"typo-card-h4"} style={{textAlign: "left"}}>
                                                    {project.name}
                                                </Typography>
                                                <Typography marginTop={1}
                                                            variant="body1"
                                                            className={"typo-card-p"}>
                                                    {project.teamMembers.length} {project.teamMembers.length > 1 ? "participants" : "participant"}
                                                </Typography>
                                                <ActionButton
                                                    variant={"see-project"}
                                                    icon={<ArrowRight/>}
                                                    iconEnd={true}
                                                    className={"button-see-project"}
                                                    onClick={() => {
                                                        navigate(`/admin/challenge/${id}/project/${project.id}`)
                                                    }}>
                                                    Voir le projet
                                                </ActionButton>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    {
                        allProjects && allProjects?.allProjects.projectsSelected.length === 0 && (
                            <p className={"text-center mt-2"}>Aucun projet sélectionné</p>
                        )
                    }
                </div>
            </div>
            {/* <div className={"container-projects-finals"}>
                <h3>Les projets finalistes</h3>
                <div className={"container-projects-selected-content"}>
                    <Grid container spacing={3}
                          justifyContent={"center"}>
                        {
                            allProjects && allProjects?.allProjects.projectsFinals.length > 0 && allProjects?.allProjects.projectsFinals.map((project: IProjects, index: number) => {
                                return (
                                    <Grid item xs={12} sm={6} md={6} lg={4}
                                          key={index}>
                                        <Card
                                            className={"admin-infos-challenge-project-list admin-infos-challenge-project-list"}>
                                            <CardMedia
                                                component="img"
                                                alt="image card projet finaliste"
                                                height="50%"
                                                image={`${project.file.fileUrl}`}
                                            />
                                            <CardContent>
                                                {
                                                    project.subChallenge && (
                                                        <Chip
                                                            label={`Défi ${project.subChallenge.position} - ${project.subChallenge.name}`}
                                                            sx={{
                                                                backgroundColor: "white",
                                                                padding: "5px",
                                                                fontFamily: "var(--font-secondary)",
                                                                lineHeight: "24px",
                                                                color: "var(--primary90)",
                                                                fontWeight: "500",
                                                                fontSize: "11px",
                                                                position: "absolute",
                                                                top: "1rem",
                                                                right: "1rem"
                                                            }}
                                                        />
                                                    )
                                                }
                                                <Typography variant="h3"
                                                            className={"typo-card-h3"} style={{textAlign: "left"}}>
                                                    {project.team.name}
                                                </Typography>
                                                <Typography variant="h3"
                                                            className={"typo-card-h4"} style={{textAlign: "left"}}>
                                                    {project.name}
                                                </Typography>
                                                <Typography marginTop={1}
                                                            variant="body1"
                                                            className={"typo-card-p"}>
                                                    {project.teamMembers.length} {project.teamMembers.length > 1 ? "participants" : "participant"}
                                                </Typography>
                                                <ActionButton
                                                    variant={"see-project"}
                                                    icon={<ArrowRight/>}
                                                    iconEnd={true}
                                                    className={"button-see-project"}
                                                    onClick={() => {
                                                        navigate(`/admin/challenge/${id}/project/${project.id}`)
                                                    }}>
                                                    Voir le projet
                                                </ActionButton>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    {
                        allProjects && allProjects?.allProjects.projectsFinals.length === 0 && (
                            <p className={"text-center mt-3"}>Aucun projet finaliste</p>
                        )
                    }
                </div>
            </div> */}
            <div className={"container-projects-eliminated"}>
                <h3>Les autres projets</h3>
                <div className={"container-projects-selected-content"}>
                    <Grid container spacing={3}
                          justifyContent={"center"}>
                        {
                            allProjects && allProjects?.allProjects.projectsEliminated.length > 0 && allProjects?.allProjects.projectsEliminated.map((project: IProjects, index: number) => {
                                return (
                                    <Grid item xs={12} sm={6} md={6} lg={4}
                                          key={index}>
                                        <Card
                                            className={"admin-infos-challenge-project-list admin-infos-challenge-project-list"}>
                                            {
                                                project?.file?.fileUrl ? (
                                                    <CardMedia
                                                        component="img"
                                                        alt="image card projet éliminé"
                                                        height="50%"
                                                        image={`${project?.file?.fileUrl}`}
                                                    />
                                                ) : null
                                            }
                                            <CardContent>
                                                {
                                                    project.subChallenge && (
                                                        <Chip
                                                            label={`Défi ${project.subChallenge.position} - ${project.subChallenge.name}`}
                                                            sx={{
                                                                backgroundColor: "white",
                                                                padding: "5px",
                                                                fontFamily: "var(--font-secondary)",
                                                                lineHeight: "24px",
                                                                color: "var(--primary90)",
                                                                fontWeight: "500",
                                                                fontSize: "11px",
                                                                position: "absolute",
                                                                top: "1rem",
                                                                right: "1rem"
                                                            }}
                                                        />
                                                    )
                                                }
                                                <Typography variant="h3"
                                                            className={"typo-card-h3"} style={{textAlign: "left"}}>
                                                    {project.team.name}
                                                </Typography>
                                                <Typography variant="h3"
                                                            className={"typo-card-h4"} style={{textAlign: "left"}}>
                                                    {project.name}
                                                </Typography>
                                                <Typography marginTop={1}
                                                            variant="body1"
                                                            className={"typo-card-p"}>
                                                    {project.teamMembers.length} {project.teamMembers.length > 1 ? "participants" : "participant"}
                                                </Typography>
                                                <ActionButton
                                                    variant={"see-project"}
                                                    icon={<ArrowRight/>}
                                                    iconEnd={true}
                                                    className={"button-see-project"}
                                                    onClick={() => {
                                                        navigate(`/admin/challenge/${id}/project/${project.id}`)
                                                    }}>
                                                    Voir le projet
                                                </ActionButton>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    {
                        allProjects && allProjects?.allProjects.projectsEliminated.length === 0 && (
                            <p className={"text-center mt-3"}>Aucun projet éliminé</p>
                        )
                    }
                </div>
            </div>
        </section>
    )
}
export default ChallengeResultats;
