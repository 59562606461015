// react-routeur
import {Link, useLocation} from "react-router-dom";

// mui
import {Box, FormControl, TextField} from "@mui/material";

// components
import ActionButton from "../../../../../../components/ActionButton";

// context
import {useAuth} from "../../../../../../context/AuthContext";

// assets
import DiamondColor from '../../../../../../assets/diamond-color';
import MakeeDiamondLogin from '../../../../../../assets/makee-diamond-login';

// style
import './index.css';

// redux
import {useDispatch, useSelector} from "react-redux";
import {RootState} from '../../../../../../app/type';

import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../../../../../components/SetUpTranslation";
import axios from "axios";
import api from "../../../../../../utils/api";
import {useState} from "react";

const MergeExistingAccount = () => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    // context
    const {
        mergeAccountAdmin,
        mergeAccountParticipant,
        searchProfile,
        finishedCheckingAuth,
        setFinishedCheckingAuth,
        isAuthenticated,
        setIsAuthenticated,
        loginUser
    } = useAuth();
    const location = useLocation();

    const dispatch = useDispatch();

    // redux state
    const error = useSelector((action: RootState) => action.error.error);
    const errorMessage = useSelector((action: RootState) => action.error.message);
    return (
        <>
            <div className="merge-existing-account-svg">
                <div className='merge-existing-account'>
                    {/* image svg en haut du formulaire (mobile) */}
                    <MakeeDiamondLogin className='makee-diamond-login'/>
                    <h1 className='h1-auth'>{t("page.mergeAccount.connectMyAccount")}</h1>
                    <FormControl onSubmit={async (e) => {
                        await loginUser(e);
                        location.pathname.includes('admin') ? mergeAccountAdmin(e) :
                            location.pathname.includes('participant') ? mergeAccountParticipant(e) :
                                console.log('erreur')
                    }}
                    >
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': {marginTop: '40px'},
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                {...(error && {error: true})}
                                id="email"
                                label={t("page.login.formConnection.inputText1")}
                                variant="outlined"
                                placeholder="example@mail.com"
                                type="email"
                                className='form-text-auth login-email-psw-input'
                                defaultValue={searchProfile && searchProfile.user.email}
                                disabled
                            />

                            <TextField
                                {...(error && {error: true})}
                                id="password"
                                label={t("page.login.formConnection.inputText2")}
                                variant="outlined"
                                placeholder="************"
                                type="password"
                                className='form-text-auth'
                                helperText={error && errorMessage}
                            />

                            <ActionButton
                                auth
                                className="button-text-auth"
                                type="submit"
                                variant='primary'
                            >
                                {t("login")}
                            </ActionButton>
                        </Box>
                    </FormControl>

                    {/* lien vers la page de récupération du mot de passe */}
                    <Link to="/reset-password" className='merge-existing-account-link small-lead-text'>
                        {t("page.login.forgotPswd")}
                    </Link>
                </div>

                <DiamondColor className='diamond'/>
            </div>
        </>
    )
}

export default MergeExistingAccount;
