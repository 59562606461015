// react
import React, {createContext, useContext, useEffect, useRef, useState} from "react";

// react-dom
import {useNavigate, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from '../../app/type';

//Others
import {DEFAULT_PROFILE_PICTURE} from "../../utils/consts";
import api from "../../utils/api";
import axios from "axios";

import {useTranslation} from "react-i18next";
import SetUpTranslation from "../../components/SetUpTranslation";

// creation du context
export const AuthContext = createContext(null as any);

interface IProfile {
    id: number,

    role: string,
}

// hook pour utiliser le context
export const AuthProvider = ({children}: React.PropsWithChildren) => {
    const [t, i18n] = useTranslation("translation");
    SetUpTranslation(i18n);

    // ----------------------------------------------------------------------

    // --------------------------- CONNEXION --------------------------------

    // ----------------------------------------------------------------------

    // isAuthenticated : true si l'utilisateur est connecté et false sinon
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    // finishedCheckingAuth : false tant que la connexion a pas été vérifiée, true quand le processus de vérif est fini
    const [finishedCheckingAuth, setFinishedCheckingAuth] = useState<boolean>(false);

    //isInitialRender : true tant que c'est le PREMIER RENDU qui est effectué, false après...
    //Il permet d'éviter que les useEffect (vérifiant si le processus de connexion est fini) se lancent dès le premier rendu
    const isInitialRender = useRef(true);

    // profile : les informations de l'utilisateur connecté
    // const [allProfiles, setAllProfiles] = useState(null as any);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    // Fonction de déconnexion
    // Elle set isAuthenticated à false et redirige vers "/login/1"
    function logout() {
        api.post("/auth/logout",
            {},
            {withCredentials: true})
            .then((res) => {
                // setAllProfiles(null);
                // navigate("/login/1");

            })
            .catch((err) => {
                console.log("erreur après login : " + err.message);
                console.log(err.response.data.message);
                // navigate("/login/1");
            });
        setIsAuthenticated(false);
        // navigate("/login/1")
        dispatch({
            type: "CLEAR_ERROR",
        });
        dispatch({
            type: "CLEAR_PROFILES",
        });
        dispatch({
            type: "CLEAR_PROFILE",
        });
        dispatch({
            type: "CLEAR_USER",
        });
        dispatch({
            type: "CLEAR_CHALLENGES",
        });
    }

    // Fonction de vérification du token et de login
    // Elle set isAuthenticated à true si le token est valide
    // Elle redirige vers "/login/1" si le token est invalide
    const login = () => {
        //setFinishedCheckingAuth(false); //On dit que la vérification est en cours
        setFinishedCheckingAuth(false);
        api.post("/auth/verif_token",
            {},
            {withCredentials: true})
            .then((res) => {
                console.log(res.data);
                if (res.data) {
                    setIsAuthenticated(true);
                }
            })
            .catch((err) => {
                console.log("erreur pendant la vérification du token : " + err.message);
                // console.log(err.response.data.message);
                setFinishedCheckingAuth(true); //On dit que la vérification est finie malgré tout (pas besoin de useRef ici)
                setIsAuthenticated(false);
                console.log("isAuthenticated Log Error: ", isAuthenticated);
            });
    }
    useEffect(() => {
        // console.log("isAuthenticated ??: ", isAuthenticated);
    }, []);
    //Premier useEffect se lançant au rendu (du contexte ?) -> Se connecte/Vérifie la connexion

    // useEffect(() => {
    //     login();
    // }, []);

    //Second useEffect se lançant dès que le status de connexion change
    //Vérifie d'abord si on est au rendu initial
    //Sinon, on indique que le processus de connexion/vérification de connexion est FINI
    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            setFinishedCheckingAuth(true); //On dit que la vérification est finie
            // console.log("Use effect contexte", isAuthenticated)
        }
    }, [isAuthenticated])

    // fonction qui permet de se connecter
    // elle envoie les données de connexion au back (email et password)
    // si la connexion est réussie, elle appelle la fonction login() qui permet de mettre à jour le context
    // elle redirige ensuite vers la page de choix du profil
    // si la connexion échoue, elle affiche une erreur dans la console
    const loginUser = (e: any) => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        console.log(email, password)
        axios.post(process.env.REACT_APP_API_URL + '/auth/login',
            {
                language: localStorage.getItem("translation"),
                email: email,
                password: password
            },
            {
                withCredentials: true
            })
            .then((response) => {
                response.status === 200 && login();
                dispatch({
                    type: "CLEAR_ERROR",
                });
                if (location.pathname !== "/signup/participant/merge/summary") {
                    if (response.data.countProfiles === 1) {
                        navigate("/login/choice/profile")
                        getProfile(true);
                    } else {
                        navigate("/login/choice/profile")
                    }
                }
            })
            .catch((error) => {
                console.log("error : ", error)
                dispatch({
                    type: "SET_ERROR",
                    payload: {
                        message: error.response.data.message,
                        error: true,
                    }
                });
            });
    }

    const getProfile = async (isLogin?: boolean) => {
        await api.get('/auth/getprofile',
            {
                withCredentials: true
            })
            .then((res) => {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        firstname: res.data.user.firstname,
                        lastname: res.data.user.lastname,
                        email: res.data.user.email,
                        profile_picture: res.data.user.profile_picture ? res.data.user.profile_picture : DEFAULT_PROFILE_PICTURE,
                    }
                })
                dispatch({
                    type: "SET_PROFILES",
                    payload: {
                        allProfiles: res.data,
                        nbAdmin: (res.data.Profiles.admin?.length),
                        nbCollaborator: (res.data.Profiles.collaborator?.length),
                        nbParticipant: (res.data.Profiles.participant?.length),
                        nbProfiles: (res.data.Profiles.admin?.length || 0) + (res.data.Profiles.collaborator?.length || 0) + (res.data.Profiles.participant?.length || 0),
                        profiles: [
                            ...(res.data.Profiles.participant?.length) > 0 ? ['participant'] : [],
                            ...(res.data.Profiles.admin?.length) > 0 ? ['admin'] : [],
                            ...(res.data.Profiles.collaborator?.length) > 0 ? ['intervenant'] : [],
                        ],
                    }
                });

                const profilesAdmin = res.data.Profiles.admin;
                const profilesCollaborator = res.data.Profiles.collaborator;
                const profilesParticipant = res.data.Profiles.participant;

                //Si j'ai plus d'un profile ( count = le total de profiles dans chaque tableau )
                const countProfiles = (profilesAdmin?.length || 0) + (profilesCollaborator?.length || 0) + (profilesParticipant?.length || 0);

                if (countProfiles === 1 && isLogin) {
                    if (profilesAdmin?.length === 1) {
                        dispatch({
                            type: "SET_PROFILE",
                            payload: {
                                profile: {
                                    id: profilesAdmin[0].id,
                                    name: "admin",
                                },
                            },
                        });
                        console.log("select admin");

                        selectProfile("admin", profilesAdmin[0].establishment);

                    } else if (profilesCollaborator?.length === 1) {
                        dispatch({
                            type: "SET_PROFILE",
                            payload: {
                                profile: {
                                    id: profilesCollaborator[0].id,
                                    name: "collaborator",
                                },
                            },
                        });
                        console.log("select collaborator");

                        selectProfile("collaborator", profilesCollaborator[0].establishment);
                    } else if (profilesParticipant?.length === 1) {
                        dispatch({
                            type: "SET_PROFILE",
                            payload: {
                                profile: {
                                    id: profilesParticipant[0].id,
                                    name: "participant",
                                },
                            },
                        });
                        console.log("select participant");
                        selectProfile("participant");
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }

    const [profile, setProfile] = useState(useSelector((state: RootState) => state.rootProfile.name) as string | null);

    const selectProfile = (role: string, establishment?: string | null, isMerge?: boolean | null, isCreating?: boolean) => {
        api.post("/auth/selectprofile",
            {
                role_name: role,
                establishment_name: establishment
            },
            {
                withCredentials: true
            })
            .then((res) => {
                const rootProfile = res.data.profile;
                setProfile(rootProfile.role.name);
                dispatch({
                    type: "SET_PROFILE",
                    payload: {
                        profile: {
                            id: rootProfile.id,
                            name: rootProfile.role.name,
                        },
                    },
                });

                if (establishment && establishment !== undefined) {
                    dispatch({
                        type: "SET_USER",
                        payload: {
                            establishment: establishment,
                        }
                    });
                } else {
                    dispatch({
                        type: "CLEAR_USER_ESTABLISHMENT",
                    });
                }

                if (isMerge) {
                    navigate("/signup/admin/merge/summary", {replace: true});
                } else if (isCreating) {
                    if (role === 'participant') {
                        navigate("/signup/participant/create/done", {replace: true})
                    } else if (role === 'admin') {
                        navigate("/signup/admin/create/done", {replace: true})
                    }
                } else {
                    //Get session sorage redirect
                    const redirect = localStorage.getItem("redirect");
                    if (redirect && ((rootProfile.role.name === "admin" && redirect !== "/") || rootProfile.role.name !== "admin")) {
                        if (!redirect.includes("/admin") && rootProfile.role.name === "participant") {
                            navigate(redirect, {replace: true});
                        } else if (!redirect.includes("/participant") && rootProfile.role.name === "admin") {
                            navigate(redirect, {replace: true});
                        } else {
                            navigate("/", {replace: true});
                        }
                    } else {
                        if (rootProfile.role.name === "admin") {
                            navigate("/admin/challenges", {replace: true});
                        } else {
                            navigate("/", {replace: true});
                        }
                    }

                    setTimeout(() => {
                        localStorage.removeItem("redirect");
                    }, 5000);
                }

            })
            .catch((err) => {
                console.log("erreur pendant la sélection du profil : " + err.message);
            });
    }

    // Vérification du token à chaque changement d'URL
    // useEffect(() => {
    //     // Liste des URLs qui ne nécessitent pas de vérification du token
    //     const locations: string[] = ["signup", "login", "password"];
    //
    //     // Vérification du token uniquement si l'URL actuelle n'est pas dans la liste des URLs exclues
    //
    //     // if (!locations.some((loc) => location.pathname.includes(loc)) && location.pathname !== "/") {
    //     //     login();
    //     // }
    //     login();
    // }, [location.pathname]);

    // ----------------------------------------------------------------------

    // -------------------------- INSCRIPTION -------------------------------

    // ----------------------------------------------------------------------

    // code : true si le code est valide et false sinon
    const [userSignup, setUserSignup] = useState(null as any);
    const [token, setToken] = useState(null as any);
    const [code, setCode] = useState(null as any);

    // fonction d'insciption
    // on vérifie tout d'abord un code pour confirmer l'adresse mail de l'utilistateur

    function verifyCodeAdmin(otp: string, token: string) {
        setCode(otp);
        console.log(token);
        api.post("/admin/verify_code",
            {
                code: otp,
                token: token,
                language: localStorage.getItem('translation')
            },
            {withCredentials: true})
            .then((res) => {
                dispatch({
                    type: "CLEAR_ERROR",
                });
                setToken(token);
                api.get("/admin/signup_info", {
                    params: {
                        token: token
                    },
                    withCredentials: true
                })
                    .then((res) => {
                        dispatch({
                            type: "SET_USER",
                            payload: {
                                firstname: res.data.message.user.firstname,
                                lastname: res.data.message.user.lastname,
                                email_pro: res.data.message.email,
                                establishment: res.data.message.establishments[0].name,
                            }
                        });
                        dispatch({
                            type: "CLEAR_ERROR",
                        });
                        console.log(
                            "firstname: ", res.data.message.user.firstname,
                            "lastname: ", res.data.message.user.lastname,
                            "email: ", res.data.message.email,
                            "establishment: ", res.data.message.establishments[0].name
                        )
                        console.log(res)
                    })
                    .catch((err) => {
                        console.log("c'est la sauce")
                        console.log(err);
                    });
                navigate("/signup/admin/invite/profile/" + token);
            })
            .catch((err) => {
                dispatch({
                    type: "SET_ERROR",
                    payload: {
                        message: err.response.data.message,
                        error: true,
                    }
                });
                console.log('tout va mal')
            });
    }

    function verifyCodeParticipant(otp: string, token: string) {
        setCode(otp);
        axios.post(process.env.REACT_APP_API_URL + "/participant_libre/verify_code",
            {
                code: otp,
                token: token,
                language: localStorage.getItem('translation')
            },
            {withCredentials: true})
            .then((res) => {
                setToken(token);
                dispatch({
                    type: "CLEAR_ERROR",
                });
                navigate("/signup/participant/create/infos");
            })
            .catch((err) => {
                dispatch({
                    type: "SET_ERROR",
                    payload: {
                        message: err.response.data.message,
                        error: true,
                    }
                });
                console.log("erreur pendant la récupération du profil : " + err.message);
            });
    }

    // ----------------------------------------------------------------------

    // ---------------------- UPDATE USER INFOS -----------------------------

    // ----------------------------------------------------------------------

    // fonction qui permet de mettre à jour les informations de l'utilisateur

    function updateInfos(e: any) {
        e.preventDefault();
        const firstname = e.target.firstname.value;
        const lastname = e.target.lastname.value;
        api.post('/admin/signup_info_update',
            {
                firstname: firstname,
                lastname: lastname,
                token: token
            }, {
                withCredentials: true
            })
            .then((response) => {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        firstname: firstname,
                        lastname: lastname,
                    }
                })
                navigate("/signup/admin/invite/account");
            })
            .catch((error) => {
                console.log(error);
            })
    }

    // ----------------------------------------------------------------------

    // ---------------------- MERGE COMPTE EXISTANT -------------------------

    // ----------------------------------------------------------------------

    const [searchProfile, setSearchProfile] = useState(null as any);

    function searchUserAdmin(e: any) {
        e.preventDefault();
        const emailreset = e.target.email.value;
        console.log(emailreset)
        console.log(token)
        api.post('/admin/search_user',
            {
                emailreset: emailreset,
                language: localStorage.getItem('translation'),
                token: token
            }, {
                withCredentials: true
            })
            .then((response) => {
                console.log(response.data);
                setSearchProfile(response.data);
                dispatch({
                    type: "CLEAR_ERROR",
                });
                navigate("/signup/admin/merge/search/search");
            })
            .catch((error) => {
                dispatch({
                    type: "SET_ERROR",
                    payload: {
                        message: error.response.data.message,
                        error: true,
                    }
                });
                console.log(error);
            })
    }

    function searchUserParticipant(e: any) {
        e.preventDefault();
        const emailreset = e.target.email.value;
        console.log(emailreset)
        console.log(token)
        api.post('/participant_libre/search_user',
            {
                emailreset: emailreset,
                language: localStorage.getItem('translation'),
                token: token
            }, {
                withCredentials: true
            })
            .then((response) => {
                console.log(response.data);
                setSearchProfile(response.data);
                dispatch({
                    type: "CLEAR_ERROR",
                });
                navigate("/signup/participant/merge/search/search");
            })
            .catch((error) => {
                dispatch({
                    type: "SET_ERROR",
                    payload: {
                        message: error.response.data.message,
                        error: true,
                    }
                });
                console.log(error);
            })
    }

    const [mergeProfile, setmergeProfile] = useState(null as any);

    function mergeAccountAdmin(e: any) {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        api.post('/admin/merge_account',
            {
                emailreset: email,
                password: password,
                code: code,
                token: token,
                language: localStorage.getItem('translation'),
            }, {
                withCredentials: true
            })
            .then((response) => {
                dispatch({
                    type: "CLEAR_ERROR",
                });
                setmergeProfile(response.data)
                setFinishedCheckingAuth(false);
                setIsAuthenticated(true);

                getProfile(false);
                selectProfile('admin', response.data.establishment_name, true);
            })
            .catch((error) => {
                dispatch({
                    type: "SET_ERROR",
                    payload: {
                        message: t("page.mergeAccount.invalidCredentials"),
                        error: true,
                    }
                });
                console.log(error);
            })
    }

    function mergeAccountParticipant(e: any) {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        console.log(email)
        console.log(password)
        console.log(token)
        api.post('/participant_libre/merge_account',
            {
                emailreset: email,
                password: password,
                code: code,
                token: token,
                language: localStorage.getItem('translation'),
            }, {
                withCredentials: true
            })
            .then((response) => {
                console.log(response.data);
                dispatch({
                    type: "CLEAR_ERROR",
                });
                setmergeProfile(response.data)
                console.log(mergeProfile);
                e.preventDefault();
                navigate("/signup/participant/merge/summary");
            })
            .catch((error) => {
                dispatch({
                    type: "SET_ERROR",
                    payload: {
                        message: 'Identifiant ou mot de passe incorrect',
                        error: true,
                    }
                });
                console.log(error);
            })
    }

    // ----------------------------------------------------------------------
    // ---------------------- CREATE A NEW ACCOUNT --------------------------
    // ----------------------------------------------------------------------

    function createAccountAdmin(e: any) {
        e.preventDefault();
        const emailreset = e.target.email_recup.value;
        const password = e.target.password.value;
        const password2 = e.target.password_confirm.value;
        const tel = e.target.tel.value;
        axios.post(process.env.REACT_APP_API_URL + '/admin/signup_first_admin',
            {
                token: token,
                emailreset: emailreset,
                password: password,
                password2: password2,
                code: code,
                tel: tel,
                language: localStorage.getItem('translation')
            }, {
                withCredentials: true
            })
            .then((response) => {
                dispatch({
                    type: "CLEAR_ERROR",
                });

                setFinishedCheckingAuth(false);
                setIsAuthenticated(true);

                getProfile(false);
                selectProfile('admin', response.data.establishment_name, false, true);

            })
            .catch((error) => {
                console.log(error);
                setRegistrationError(error.response.data);
            })
    }

    const [registrationError, setRegistrationError] = useState<any | null>({});

    function createAccountParticipant(e: any) {
        e.preventDefault();
        const emailreset = e.target.email_recup.value;
        const password = e.target.password.value;
        const password2 = e.target.password_confirm.value;
        const tel = e.target.tel.value;
        axios.post(process.env.REACT_APP_API_URL + '/participant_libre/confirm_signup',
            {
                language: localStorage.getItem("translation"),
                token: token,
                emailreset: emailreset,
                password: password,
                password2: password2,
                code: code,
                tel: tel,
            }, {
                withCredentials: true
            })
            .then((response) => {
                dispatch({
                    type: "CLEAR_ERROR",
                });

                // setFinishedCheckingAuth(false);
                // setIsAuthenticated(true);

                getProfile(false);
                selectProfile('participant', null, false, true);
            })
            .catch((error) => {
                console.log(error.response.data)
                setRegistrationError(error.response.data);
            })
    }

    const [email, setEmail] = useState('');

    function createParticipantLibre(e: any) {
        e.preventDefault();
        const firstname = e.target.firstname.value;
        const lastname = e.target.lastname.value;
        const email = e.target.email.value;
        console.log('createparticipant')
        axios.post(process.env.REACT_APP_API_URL + '/participant_libre/signup_first_participant_libre',
            {
                language: localStorage.getItem("translation"),
                firstname: firstname,
                lastname: lastname,
                email: email,
            }, {
                withCredentials: true
            })
            .then((response) => {
                setEmail(email);
                localStorage.setItem('emailSignupParticipant', email);
                dispatch({
                    type: "CLEAR_ERROR",
                });
                navigate("/signup/participant/email");
            })
            .catch((error) => {
                // dispatch({
                //     type: "SET_ERROR",
                //     payload: {
                //         message: 'Veuillez compléter tout les champs.',
                //         error: true,
                //     }
                // });
                setRegistrationError(error.response.data);
                console.log(error);
            })
    }

    /**
     * RESET PASSWORD
     */
    const [errorResetPassword, setErrorResetPassword] = useState<string>("");
    const sendResetPassword = async (e: any) => {
        e.preventDefault();

        const emailreset = e.target.email.value;

        await api.post('/auth/send_reset_password',
            {
                emailreset: emailreset,
                language: localStorage.getItem("translation")
            }, {
                withCredentials: true
            })
            .then((response) => {
                localStorage.setItem('emailreset', emailreset);
                navigate("/reset-password/done");
            })
            .catch((error) => {
                console.log(error);
                setErrorResetPassword(error.response.data.message);
            })
    }

    /*  MY PROFILE  */

    const resetPassword = async (e: any, token: string) => {
        e.preventDefault();

        const password = e.target.password.value;
        const passwordconfirm = e.target.passwordconfirm.value;

        await api.post('/auth/resetpasswordforgot',
            {
                language: localStorage.getItem("translation"),
                password: password,
                password2: passwordconfirm,
                token: token
            },
            {
                withCredentials: true
            }
        ).then((res) => {
            navigate('/forgot/password/done');
        }).catch((err) => {
            console.log(err);
        })
    }

    // ----------------------------------------------------------------------
    // ---------------------- RENVOIE DE MAIL -------------------------------
    // ----------------------------------------------------------------------

    function resendEmail() {
        console.log('resend email')
        api.post('/participant_libre/resend_code_participant_libre',
            {
                language: localStorage.getItem("translation"),
                email: email,
            }, {
                withCredentials: true
            })
            .then((response) => {
                console.log('email renvoyé')
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            setFinishedCheckingAuth,
            finishedCheckingAuth,
            profile,
            // allProfiles,
            userSignup,
            searchProfile,
            mergeProfile,
            registrationError,
            setRegistrationError,
            createParticipantLibre,
            verifyCodeAdmin,
            verifyCodeParticipant,
            login,
            logout,
            loginUser,
            searchUserAdmin,
            searchUserParticipant,
            mergeAccountAdmin,
            mergeAccountParticipant,
            updateInfos,
            selectProfile,
            createAccountAdmin,
            createAccountParticipant,
            resendEmail,
            sendResetPassword,
            resetPassword,
            getProfile
        }}>
            {children}
        </AuthContext.Provider>
    );
}
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}
export default useAuth;
