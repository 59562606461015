import ModuleChallengePlanning from "../../../../../modules/Planning";
import "./index.css";
import api from "../../../../../utils/api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
const ChallengePlanning = () => {
    const challengeId = useParams().id;
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [isManual, setIsManual] = useState<boolean | null>(null);

    const getOneChallenge = async () => {
        await api.get('/challenge/preview', {
            params: {
                challengeId: challengeId
            },
            withCredentials: true,
        })
            .then((res) => {
                setImageUrl(res.data.challenge.planning);
                setIsManual(res.data.challenge.isManual);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    useEffect(() => {
        getOneChallenge();
    }, []);
    return (
        <section className="admin-challenge-planning">
            <div className="planning-header">
                <h2>Planning</h2>
            </div>
            {
                isManual ? (
                    <div className="block-planning-manual">
                        <img src={imageUrl ? imageUrl : ""} alt="Planning" className='img-planning-public' />
                    </div>
                ) : (
                    <ModuleChallengePlanning />
                )
            }
        </section>
    );
}

export default ChallengePlanning;